export const initialState = {
  mySites: [],
  allSites: [],
  basketSitesIds: [],
  auctionSites: [],
  auctionSitesIds: [],
  dealDoneSites: [],
  wonSites: [],
  lostSites: [],
  editSites: undefined,
  sitesDetails: undefined,
  sitesDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function sitesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SITES_FIND_ALLSITES': {
      if (action.data) {
        const allSites = action.data.allSites;

        return {
          ...state,
          allSites: allSites
        };
      }
      return initialState;
    }
    case 'SITESS_SET_EDITSITES': {
      if (action.data) {
        const editSites = action.data;

        return {
          ...state,
          editSites: editSites
        };
      }
      return initialState;
    }
    case 'SITESS_SET_SITESDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          sitesDetailsId: id
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
