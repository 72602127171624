import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { signUpUser, signUpManager } from 'src/redux/actions/user';
import { getLocale } from 'src/redux/selectors';
import changeLocale from 'src/redux/actions/locale';

class SignUp extends Component {
  render = () => {
    const {
      Layout,
      signUpUser,
      signUpManager,
      currentLocale,
      changeLocale,
      push
    } = this.props;

    return (
      <Layout
        signUpUser={signUpUser}
        signUpManager={signUpManager}
        currentLocale={currentLocale}
        changeLocale={changeLocale}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {}
});

const mapDispatchToProps = {
  signUpUser,
  signUpManager,
  changeLocale,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
