import React from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  InputNumber,
} from "antd";
import {
  BellOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import Notify from "src/components/meta/Notification";
import Highlighter from "react-highlight-words";
const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;

const { Option } = Select;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-selector {
    width: 100%;

    padding-top: 0px !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-top: 0em !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    margin-top: 10px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
    padding-top: 10px !important;
  }
`;
function callback(key) {
  console.log(key);
}

class ToolsFormClass extends React.Component {
  state = {
    descriptions: this.props.descriptions,
  };
  selectItem = value => {
    if (value.substr(0, 4) === "Add ") {
      let parsedValue = value.substr(4, value.length);
      this.props.form.setFieldsValue({ description: parsedValue });
      let descriptions = this.state.descriptions;
      descriptions.pop();
      descriptions.push({ value: parsedValue });
      this.setState({ descriptions: descriptions });
    }
  };
  searchItem = (value, b) => {
    let descriptions = this.state.descriptions;
    if (descriptions[descriptions.length - 1].value.substr(0, 4) === "Add ") {
      descriptions.pop();
    }
    if (
      !descriptions.find(
        descriptions => descriptions.value.toLowerCase() === value.toLowerCase()
      ) &&
      value !== ""
    ) {
      descriptions.push({ value: "Add " + value });
    }
    this.setState({ descriptions: descriptions });
  };
  render() {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={11}>
            <FormItem label={<span>Stock Nr.</span>}>
              {this.props.form.getFieldDecorator("serial", {
                rules: [{ required: true, message: "Serial is required" }],
                initialValue: this.props.editBits
                  ? this.props.editBits.serial
                  : "",
              })(<Input placeholder={"Please enter serial"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={1} />
          {/* <Col span={11}>
            <FormItem label={<span>Serial Nr.</span>}>
              {this.props.form.getFieldDecorator("stockNumber", {
                rules: [{ required: true, message: "Serial is required" }],
                initialValue: this.props.editBits
                  ? this.props.editBits.stockNumber
                  : "",
              })(<Input placeholder={"Please enter serial"} />)}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem label={<span>Tool Type</span>}>
              {this.props.form.getFieldDecorator("size", {
                rules: [
                  {
                    required: true,
                    message: "Tool Type is required",
                  },
                ],
                initialValue: this.props.editBits
                  ? this.props.editBits.size
                  : "",
              })(
                <Select>
                  <Option value="Bit">Bit</Option>
                  <Option value="Rod">Rod</Option>
                  <Option value="Hammer">Hammer</Option>
                  <Option value="Adapter">Adapter</Option>
                  <Option value="Refurbish">Refurbish</Option>
                </Select>
              )}
            </FormItem>
          </Col>{" "}
          <Col span={1} />
          <Col span={11}>
            <FormItem label={<span>Description</span>}>
              {this.props.form.getFieldDecorator("description", {
                rules: [{ required: true, message: "Description is required" }],
                initialValue: this.props.editBits
                  ? this.props.editBits.description
                  : "",
              })(<Input placeholder={"Please enter description"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={1} />
          <Col span={11}>
            <FormItem label={<span>Initial Status</span>}>
              {this.props.form.getFieldDecorator("status", {
                rules: [{ required: true, message: "Status is required" }],
                initialValue: this.props.editBits
                  ? this.props.editBits.status
                  : "",
              })(
                <Select>
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={11}>
            <FormItem label={<span>Price</span>}>
              {this.props.form.getFieldDecorator("price", {
                rules: [{ required: false, message: "Price is required" }],
                initialValue: this.props.editBits
                  ? this.props.editBits.price
                  : "",
              })(
                <InputNumber
                  // disabled
                  // defaultValue={price}
                  style={{ width: "100%" }}
                  formatter={value =>
                    `R ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={value => value.replace(/\R\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
          </Col>{" "}
          <Col span={24}>
            <FormItem label={<span>Site Access</span>}>
              {this.props.form.getFieldDecorator("access", {
                rules: [
                  {
                    required: true,
                    message: "Access required",
                  },
                ],
                initialValue: this.props.editBits
                  ? this.props.editBits.access
                  : [],
              })(
                <Select mode="multiple">
                  {this.props.sites.map(site => (
                    <Option key={site._id} value={site._id}>
                      {site.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}
const ToolsForm = Form.create()(ToolsFormClass);

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editBits: undefined,
      size: "large",
      flag: false,
      currentTab: "All",
    };
  }

  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllBits()
      .then(() => {
        this.props
          .findAllSites()
          .then(() => {
            this.setState({ loading: false, currentTab: "All" });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addBitsVisible) {
      this.setState({ addBitsVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  bitsForm;
  onAddBits = e => {
    this.setState({ addBitsVisible: true, editBits: undefined });
  };

  handleAddBits = e => {
    e.preventDefault();
    this.bitsForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBits) {
          values._id = this.state.editBits._id;
        }
        this.props
          .addBits(values)
          .then(() => {
            this.props
              .findAllBits()
              .then(() => {
                if (this.state.editBits) {
                  Notify("success", "Successfully saved tool");
                } else {
                  Notify("success", "Successfully added tool");
                }
                this.bitsForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addBitsVisible: false,
                  editBits: undefined,
                  currentTab: "All",
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleEditBits = e => {
    this.setState({ addBitsVisible: true, editBits: e });
  };

  handleDeleteBits = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this tool?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteBits(id)
          .then(() => {
            that.props
              .findAllBits()
              .then(() => {
                that.setState({ loading: false, currentTab: "All" });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify("success", "Successfully deleted tool");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() {},
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };

  render() {
    let descriptions = this.props.bits.map(tool => {
      return { value: tool.description };
    });
    const { size } = this.state;
    const { bits } = this.props;
    let tools;
    if (this.state.currentTab === "All") {
      tools = bits;
    } else {
      tools = bits.filter(tool => tool.size === this.state.currentTab);
    }
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Spin spinning={this.state.loading}>
          {this.state.addBitsVisible ? (
            <Card
              bordered={false}
              extra={
                <Title
                  style={{
                    paddingRight: "75em",
                    fontSize: "20px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.editBits ? "Edit Tool" : "Add Tool"}
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      marginTop: "0.5em",
                      marginBottom: "0em",
                    }}
                  >
                    {this.state.editBits
                      ? "Please click save to edit tool"
                      : "Fill in all fields and click save to add a new tool"}
                  </p>
                </Title>
              }
              style={{
                margin: "auto",
                marginTop: "3em",
                width: "50em",
                backgroundColor: "white",
                borderRadius: "0.8em",
                boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div
                style={{
                  padding: "3em",
                  paddingTop: "1em",
                  paddingBottom: "4em",
                  margin: "auto",
                }}
              >
                <ToolsForm
                  descriptions={descriptions}
                  editBits={this.state.editBits}
                  wrappedComponentRef={form => (this.bitsForm = form)}
                  sites={this.props.sites}
                />{" "}
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleAddBits}
                  size="large"
                  style={{
                    float: "right",
                    fontSize: "14px",
                    width: "7em",
                    height: "3em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  Save
                </Button>
                <Button
                  shape="round"
                  onClick={e => this.setState({ addBitsVisible: false })}
                  style={{
                    fontSize: "14px",
                    width: "7em",
                    height: "3em",
                    float: "right",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "#EEEFF0",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Card>
          ) : (
            <Row>
              {" "}
              <Col span={24}>
                <StyledTabs
                  style={{ marginTop: "1em" }}
                  defaultActiveKey="1"
                  onChange={this.changeTypeTab}
                  tabBarExtraContent={{
                    left: (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={this.onAddBits}
                        icon={
                          <PlusCircleOutlined style={{ fontSize: "110%" }} />
                        }
                        size="large"
                        style={{
                          marginRight: "2.5em",
                          height: "3em",
                          width: "9em",
                        }}
                      >
                        Add Tool
                      </Button>
                    ),

                    right: (
                      <h1 style={{ color: "#656565", fontSize: 22 }}>
                        Tools Issued (Total) :{" "}
                        <span style={{ color: "#003FA5", fontSize: 25 }}>
                          {this.props.bits.length}
                        </span>
                      </h1>
                    ),
                  }}
                >
                  <TabPane tab="All" key="All">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title="Last User"
                          dataIndex="lastUser"
                          key="lastUser"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title="Meters"
                          dataIndex="meters"
                          key="meters"
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => {
                            switch (status) {
                              case "New":
                                return this.renderStatus("New", "#4BED5B");
                              case "Used":
                                return this.renderStatus("Used", "#EE4C4C");
                              case "Out":
                                return this.renderStatus("Out", "#FF8F28");
                              case "Sharp":
                                return this.renderStatus("Sharp", "#EDAC4B");
                              case "Lost":
                                return this.renderStatus("Lost", "#D4D4D4");
                              case "Damaged":
                                return this.renderStatus("Damaged", "#EE4C4C");
                              default:
                                return this.renderStatus(
                                  "No Status",
                                  "lightgrey"
                                );
                            }
                          }}
                        />
                      ) : null} */}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Price"
                          dataIndex="price"
                          key="price"
                          render={(text, record) => {
                            return <span>R {text}</span>;
                          }}
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                  <TabPane tab="Bits" key="Bit">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title='Last User'
                          dataIndex='lastUser'
                          key='lastUser'
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title='Meters'
                          dataIndex='meters'
                          key='meters'
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => {
                            switch (status) {
                              case "New":
                                return this.renderStatus("New", "#4BED5B");
                              case "Used":
                                return this.renderStatus("Used", "#EE4C4C");
                              case "Out":
                                return this.renderStatus("Out", "#FF8F28");
                              case "Sharp":
                                return this.renderStatus("Sharp", "#EDAC4B");
                              case "Lost":
                                return this.renderStatus("Lost", "#D4D4D4");
                              case "Damaged":
                                return this.renderStatus("Damaged", "#EE4C4C");
                              default:
                                return this.renderStatus(
                                  "No Status",
                                  "lightgrey"
                                );
                            }
                          }}
                        />
                      ) : null} */}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                  <TabPane tab="Rods" key="Rod">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title='Last User'
                          dataIndex='lastUser'
                          key='lastUser'
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title='Meters'
                          dataIndex='meters'
                          key='meters'
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => (
                            <div>
                              {status === "New" ? (
                                <p>
                                  <StyledBadge color="#4BED5B" style={{}} />
                                  New
                                </p>
                              ) : status === "Used" ? (
                                <p>
                                  <StyledBadge color="#EE4C4C" style={{}} />
                                  Used
                                </p>
                              ) : status === "Out" ? (
                                <p>
                                  <StyledBadge color="#FF8F28" style={{}} />
                                  Out
                                </p>
                              ) : (
                                <p>
                                  <StyledBadge color="lightgrey" style={{}} />
                                  No Status
                                </p>
                              )}
                            </div>
                          )}
                        />
                      ) : null} */}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                  <TabPane tab="Hammers" key="Hammer">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title='Last User'
                          dataIndex='lastUser'
                          key='lastUser'
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title='Meters'
                          dataIndex='meters'
                          key='meters'
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => (
                            <div>
                              {status === "New" ? (
                                <p>
                                  <StyledBadge color="#4BED5B" style={{}} />
                                  New
                                </p>
                              ) : status === "Used" ? (
                                <p>
                                  <StyledBadge color="#EE4C4C" style={{}} />
                                  Used
                                </p>
                              ) : status === "Out" ? (
                                <p>
                                  <StyledBadge color="#FF8F28" style={{}} />
                                  Out
                                </p>
                              ) : (
                                <p>
                                  <StyledBadge color="lightgrey" style={{}} />
                                  No Status
                                </p>
                              )}
                            </div>
                          )}
                        />
                      ) : null} */}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                  <TabPane tab="Adapters" key="Adapter">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title='Last User'
                          dataIndex='lastUser'
                          key='lastUser'
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title='Meters'
                          dataIndex='meters'
                          key='meters'
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => (
                            <div>
                              {status === "New" ? (
                                <p>
                                  <StyledBadge color="#4BED5B" style={{}} />
                                  New
                                </p>
                              ) : status === "Used" ? (
                                <p>
                                  <StyledBadge color="#EE4C4C" style={{}} />
                                  Used
                                </p>
                              ) : status === "Out" ? (
                                <p>
                                  <StyledBadge color="#FF8F28" style={{}} />
                                  Out
                                </p>
                              ) : (
                                <p>
                                  <StyledBadge color="lightgrey" style={{}} />
                                  No Status
                                </p>
                              )}
                            </div>
                          )}
                        />
                      ) : null} */}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                  <TabPane tab="Refurbishes" key="Refurbish">
                    <StyledTable
                      bordered={false}
                      dataSource={tools}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    >
                      <Column
                        //{...this.getColumnSearchProps("serial")}
                        title="Stock Nr."
                        dataIndex="serial"
                        key="serial"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Tool Type"
                          dataIndex="size"
                          key="size"
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("size")}
                          title="Description"
                          dataIndex="description"
                          key="description"
                        />
                      ) : null}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("currentRig")}
                          title="Current Rig"
                          dataIndex="currentRig"
                          key="currentRig"
                        />
                      ) : null} */}
                      {/*{window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("lastUser")}
                          title='Last User'
                          dataIndex='lastUser'
                          key='lastUser'
                        />
                      ) : null}
                      {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("meters")}
                          title='Meters'
                          dataIndex='meters'
                          key='meters'
                        />
                      ) : null}*/}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          //{...this.getColumnSearchProps("status")}
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={status => (
                            <div>
                              {status === "New" ? (
                                <p>
                                  <StyledBadge color="#4BED5B" style={{}} />
                                  New
                                </p>
                              ) : status === "Used" ? (
                                <p>
                                  <StyledBadge color="#EE4C4C" style={{}} />
                                  Used
                                </p>
                              ) : status === "Out" ? (
                                <p>
                                  <StyledBadge color="#FF8F28" style={{}} />
                                  Out
                                </p>
                              ) : (
                                <p>
                                  <StyledBadge color="lightgrey" style={{}} />
                                  No Status
                                </p>
                              )}
                            </div>
                          )}
                        />
                      ) : null} */}
                      {/* {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps("access")}
                          title="Site Access"
                          dataIndex="access"
                          key="access"
                          render={(text, record) => {
                            let sites = text.map(site => {
                              return (
                                <span>
                                  {this.props.sites.find(
                                    sSite => sSite._id === site
                                  )
                                    ? this.props.sites.find(
                                        sSite => sSite._id === site
                                      ).name
                                    : "Unknown site"}
                                </span>
                              );
                            });
                            return sites.map((site, index) => {
                              return sites.length === index + 1 ? (
                                <span>{site}</span>
                              ) : (
                                <span>{site}, </span>
                              );
                            });
                          }}
                        />
                      ) : null} */}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, bits) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.handleEditBits(bits)}
                            >
                              Edit
                            </Button>
                            {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              data={bits._id}
                              onClick={this.handleDeleteBits}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>{" "}
                  </TabPane>
                </StyledTabs>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedTools = Form.create()(Tools);
export default WrappedTools;
