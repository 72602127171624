import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter, Link } from 'react-router-dom';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getAllRigs,
  getTimeStampRigs,
  getRigs140,
  getRigs171
} from 'src/redux/selectors';
import {
  addRigs,
  deleteRigs,
  findAllRigs,
  find140mm,
  find171mm,
  findAllRigsUpdatedToday
} from 'src/redux/actions/rigs';
import { addRequest } from 'src/redux/actions/request';

class Rigs extends Component {
  render = () => {
    const {
      Layout,
      push,
      history,
      rigs,
      rigs140,
      rigs171,
      findAllRigs,
      addRigs,
      deleteRigs,
      sendNotification,
      find140mm,
      find171mm
    } = this.props;
    return (
      <Layout
        push={push}
        history={history}
        addRigs={addRigs}
        rigs={rigs}
        findAllRigs={findAllRigs}
        deleteRigs={deleteRigs}
        sendNotification={sendNotification}
        find140mm={find140mm}
        find171mm={find171mm}
        rigs140={rigs140}
        rigs171={rigs171}
      />
    );
  };
}

const mapStateToProps = state => ({
  rigs: state.rigs.allRigs || [],
  rigs140: state.rigs.rigs140 || [],
  rigs171: state.rigs.rigs171 || []
});

const mapDispatchToProps = {
  push,
  findAllRigs,
  addRigs,
  deleteRigs,
  sendNotification,
  find140mm,
  find171mm,
  findAllRigsUpdatedToday
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Rigs)
);
