import React from "react";
import {
  DashboardOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Col, Space, Button, Popover } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import { getSidebarCollapsed } from "src/redux/selectors";
import { changeColor, collapseSidebar } from "src/redux/actions/status";
import { TeamOutlined, EuroCircleOutlined } from "@ant-design/icons";
import { GiScrew } from "@react-icons/all-files/gi/GiScrew";

const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#009BDF"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#009BDF"
    />
  </svg>
);
const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #009bdf;
  }
`;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: "gcm", // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log("operation failed w/ error:", status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("basic");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      collapsedWidth="130"
      collapsible
      trigger={null}
      collapsed={props.collapsed}
      width={210}
      style={{
        // border: '1px solid #009BDF3b',
        //zIndex: 9,
        //position: "fixed",
        background: "#003A98",
        //height: "calc(100vh - 40px)",
        //right: 0,
        //top: 50,
        //minHeight: "100vh",
      }}
    >
      <Menu
        style={{ background: "none" }}
        theme="light"
        //mode="inline"
        defaultSelectedKeys={["users"]}
      >
        <Menu.Item
          key="dashboard"
          icon={
            <DashboardOutlined
              style={{
                fontSize: props.collapsed ? "30px" : "17px",
                color: "#fff",
                //zIndex: 1,
                //position: 'absolute'
                padding: props.collapsed ? "0.4em" : "",
                cursor: "pointer",
                borderRadius: props.collapsed ? "0.3em" : "",
                //backgroundColor: props.collapsed ? '#009BDF' : '',
                height: props.collapsed ? "2em" : "",
                width: props.collapsed ? "2em" : "",
              }}
            />
          }
          style={{
            marginTop: "8em",
            padding: props.collapsed ? "0 calc(50% - 60px / 2)" : "",
            marginBottom: "3em",
            overflow: "visible",
            backgroundColor: "rgb(0, 58, 152)",
          }}
        >
          <StyledLink to="/user/pexunits">
            <span style={{ fontSize: "17px", fontWeight: 500, color: "#fff" }}>
              Dashboard
            </span>
          </StyledLink>
        </Menu.Item>

        {/*
        <Menu.Item
          key="usermanagement"
          style={{
            padding: props.collapsed ? '0 calc(50% - 60px / 2)' : '',
            marginBottom: '3em',
            overflow: 'visible',
            backgroundColor: 'rgb(0, 58, 152)'
          }}
        >
          <StyledLink to="/user/usermanagement">
            <TeamOutlined
              style={{
                fontSize: props.collapsed ? '30px' : '17px',
                paddingTop: '0px',
                color: '#fff',
                padding: props.collapsed ? '0.4em' : '',
                cursor: 'pointer',
                borderRadius: props.collapsed ? '0.3em' : '',
                //backgroundColor: props.collapsed ? '#009BDF' : '',
                height: props.collapsed ? '2em' : '',
                width: props.collapsed ? '2em' : ''
              }}
            />
            <span style={{ fontSize: '17px', fontWeight: 500, color: '#fff' }}>
              User Management
            </span>
          </StyledLink>
        </Menu.Item>
            */}
        <Menu.Item
          key="bitmanagement"
          style={{
            overflow: "visible",
            padding: props.collapsed ? "0 calc(50% - 60px / 2)" : "",
            backgroundColor: "rgb(0, 58, 152)",
          }}
        >
          <StyledLink to="/user/BitManagement">
            <Space>
              <GiScrew
                style={{
                  fontSize: props.collapsed ? "30px" : "17px",
                  paddingTop: "0px",
                  color: "#fff",
                  padding: props.collapsed ? "0.4em" : "",
                  cursor: "pointer",
                  borderRadius: props.collapsed ? "0.3em" : "",
                  //backgroundColor: props.collapsed ? '' : '',
                  height: props.collapsed ? "2em" : "",
                  width: props.collapsed ? "2em" : "",
                }}
              />
              <span
                style={{
                  fontSize: "17px",
                  color: "#fff",
                  fontWeight: 500,
                  marginLeft: props.collapsed ? "3em" : "",
                }}
              >
                Bit Management
              </span>
            </Space>
          </StyledLink>
        </Menu.Item>

        <Menu.Item
          key="settings"
          style={{
            marginTop: "38em",
            padding: props.collapsed ? "0 calc(50% - 60px / 2)" : "",
            overflow: "visible",
            backgroundColor: "rgb(0, 58, 152)",
          }}
        >
          <StyledLink to="/user/pexunits">
            <SettingOutlined
              style={{
                fontSize: props.collapsed ? "30px" : "17px",
                paddingTop: "0px",
                color: "#fff",
                padding: props.collapsed ? "0.4em" : "",
                cursor: "pointer",
                borderRadius: props.collapsed ? "0.3em" : "",
                //backgroundColor: props.collapsed ? '#009BDF' : '',
                height: props.collapsed ? "2em" : "",
                width: props.collapsed ? "2em" : "",
              }}
            />
            <span style={{ fontSize: "17px", color: "#fff", fontWeight: 500 }}>
              Settings
            </span>
          </StyledLink>
        </Menu.Item>

        <Popover
          trigger="click"
          style={{}}
          placement="topLeft"
          content={
            <div style={{ width: "17em", padding: "0.5em" }}>
              <Row justify="space-between">
                <Col span={8}>
                  <Button
                    type="default"
                    style={{
                      borderRadius: "5em",
                      background: "#f15b2b",
                      color: "#fff",
                      height: "3em",
                      width: "3em",
                      marginTop: "0.5em",
                      fontSize: "1.3em",
                    }}
                    onClick={null}
                  >
                    SA
                  </Button>
                </Col>
                <Col span={16}>
                  <p
                    style={{
                      color: "#878787",
                      marginBottom: "0.5em",
                    }}
                  >
                    Super Admin
                  </p>
                  {/* <StyledLink to={'/user/myprofile'}>
                    <Button type="primary" onClick={'/user/myprofile'}>
                      My Profile
                    </Button>
                  </StyledLink> */}
                </Col>
              </Row>
              <Row justify="start">
                <Col
                  span={24}
                  style={{
                    marginTop: "1.4em",
                    marginLeft: "0.5em",
                  }}
                >
                  <a style={{ color: "#878787", fontSize: "16px" }} href="/">
                    <PoweroffOutlined onClick={props.signOut} />{" "}
                    &nbsp;&nbsp;Sign out
                  </a>
                </Col>
              </Row>
            </div>
          }
        >
          <Menu.Item
            key="information"
            style={{
              marginTop: "2em",
              padding: props.collapsed ? "0 calc(50% - 60px / 2)" : "",
              overflow: "visible",
              backgroundColor: "rgb(0, 58, 152)",
            }}
          >
            <StyledLink to="/user/pexunits">
              <InfoCircleOutlined
                style={{
                  fontSize: props.collapsed ? "30px" : "17px",
                  paddingTop: "0px",
                  color: "#fff",
                  padding: props.collapsed ? "0.4em" : "",
                  cursor: "pointer",
                  borderRadius: props.collapsed ? "0.3em" : "",
                  //backgroundColor: props.collapsed ? '#009BDF' : '',
                  height: props.collapsed ? "2em" : "",
                  width: props.collapsed ? "2em" : "",
                }}
              />
              <span
                style={{ fontSize: "17px", color: "#fff", fontWeight: 500 }}
              >
                Information
              </span>
            </StyledLink>
          </Menu.Item>
        </Popover>
        {/*<Menu.Item onClick={props.collapseSidebar} key="shift">
          <StyledLink to="/user/shiftmanager">
            <DashboardOutlined
              style={{
                fontSize: '17px',
                paddingTop: '0px'
              }}
            />
            <span style={{ fontSize: '17px', fontWeight: 500 }}>
              Shift Manager
            </span>
          </StyledLink>
        </Menu.Item>*/}
        {/*<Menu.Item onClick={props.collapseSidebar} key="pexunits">
          <StyledLink to="/user/pexunits">
            <StockOutlined style={{ fontSize: "20px", paddingRight: "12px" }} />
            <span style={{ fontSize: "15px" }}>Dashboard</span>
          </StyledLink>
        </Menu.Item>*/}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
  };
};

const mapDispatchToProps = {
  collapseSidebar,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
