import { getToken } from "src/redux/selectors";

export function addRigs(values, activeDisplayId) {
  if (activeDisplayId) {
    values.activeDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/addrigs",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add rigs" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateRigs(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/updaterigs",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to update rigs " });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveRigs(values, activeDisplayId) {
  if (activeDisplayId) {
    values.activeDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/setrigs",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to edit rigs" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteRigs(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/deleterigs",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete rigs" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllRigs() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/findallrigs",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_FIND_ALLRIGS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllRigsUpdatedToday() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/rigs/todayupdatedrigs",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_UPDATED_TODAY",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find140mm() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/rigs140", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_FIND_RIGS140",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find38() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/find38", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_FIND_RIG38",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find171mm() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/rigs/rigs171", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_FIND_RIGS171",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditRigs(rigs) {
  return dispatch =>
    dispatch({
      type: "RIG_SET_EDITRIGS",
      data: rigs,
    });
}
