/* global window */
import React from "react";
import {
  CarFilled,
  HistoryOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import { getSidebarCollapsed, getUser } from "src/redux/selectors";
import { collapseSidebar } from "src/redux/actions/status";

const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #009bdf;
  }
`;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: "gcm", // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log("operation failed w/ error:", status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("basic");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      width="72"
      collapsedWidth="80"
      trigger={null}
      collapsible
      collapsed={false}
      id="managerSidebarId"
      // width={170}
      style={{
        border: "1px solid #009BDF3b",
        zIndex: 9,
        position: "fixed",
        background: "white",
        height: "calc(100vh - 60px)",
        left: 0,
        top: 60,
        borderRight: " 0.5px solid rgba(122, 122, 122, 0.39) ",
      }}
    >
      <Menu
        style={{ background: "none" }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["auctionstock"]}
      >
        {/*     <Menu.Item
          style={{ marginTop: '15px' }}
          key="dashboard" 
        >
         <StyledLink to="/manager/dashboard">
            <Icon type="appstore" theme="filled" style={{ fontSize: '25px', paddingRight: '15px' }} />
            <span>Dashboard</span>
          </StyledLink>
        </Menu.Item>*/}
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          style={{ marginTop: "15px" }}
          key="auctionstock"
          id="managerSidebarId"
        >
          <StyledLink to="/manager/auctionstock">
            <CarFilled style={{ fontSize: "20px", paddingRight: "12px" }} />
            <span style={{ fontSize: "15px" }}>Stock on Auction</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          key="mybasket"
        >
          <StyledLink to="/manager/mybasket">
            <ShoppingCartOutlined
              style={{ fontSize: "20px", paddingRight: "12px" }}
            />
            <span style={{ fontSize: "15px" }}>My Basket</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          key="history"
        >
          <StyledLink to="/manager/history">
            <HistoryOutlined
              style={{ fontSize: "20px", paddingRight: "12px" }}
            />
            <span style={{ fontSize: "15px" }}>History</span>
          </StyledLink>
        </Menu.Item>

        {/*    <Menu.Item style={{ position: 'absolute', bottom: 110 }} key="account">
          <StyledLink to="/manager/account">
            <Icon type="setting" theme="filled" />
            <span>Account Settings</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          style={{ position: 'absolute', bottom: 70 }}
          onClick={signOut}
          key="signout"
        >
          {/* <StyledLink to="/"> 
          <Icon type="poweroff" />
          <span>Sign Out</span>
          {/* </StyledLink>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
    user: getUser(state) || {},
  };
};

const mapDispatchToProps = {
  collapseSidebar,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
