import React from 'react';
import { Row } from 'antd';

import Notify from 'src/components/meta/Notification';

class Dashboard extends React.Component {
  componentWillMount = e => {
    if (this.props.user.approved) {
      this.props.push('/manager/auctionstock');
    } else {
      this.props.push('/manager/account');
    }
  };
  static defaultProps = {
    error: null,
    member: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });
    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return <Row type="flex" justify="space-around" align="middle" />;
  }
}

export default Dashboard;
