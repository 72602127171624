/* global document */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Spin } from 'antd';

import configureStore from './redux/store';
import 'src/styles/global.scss';
import * as serviceWorker from 'src/serviceWorker';
import Routes from './routes/routes';
// Components

// // HIJACK CONSOLE AND ERROR LOG FOR PRODUCTION
// if (process.env.NODE_ENV !== 'development')
//   window.console = {
//     log: () => { },
//     error: () => { },
//     warn: () => { }
//   }; //HIJACK CONSOLE LOG

const { persistor, store, history } = configureStore();
// persistor.purge(); // Debug to clear persist

const rootElement = document.getElementById('root');
console.log(process.env.REACT_APP_GLOBAL_SERVER);
console.log(process.env.REACT_APP_GLOBAL_VERSION_CNTRL);
const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Routes history={history} />
    </PersistGate>
  </Provider>
);

// Function to request audio permissions
// Function to request audio permissions
async function requestAudioPermissions() {
  // Check if cordova is available
  const { cordova } = window;
  if (cordova && cordova.plugins.permissions) {
    // Define the permission you want to request
    const permission = cordova.plugins.permissions.RECORD_AUDIO;

    try {
      // Request the permission
      const result = await cordova.plugins.permissions.requestPermission(permission);

      // Check if the permission is granted
      if (result && result.state === 'granted') {
        // Audio permissions are granted, continue with audio recording
        console.log("Audio permissions granted.");
      } else {
        // Audio permissions are denied or not granted, handle accordingly
        console.log("Audio permissions not granted.");
      }
    } catch (error) {
      // Error occurred while requesting permission
      console.log("Error occurred while requesting audio permissions.");
    }
  } else {
    // cordova.plugins.permissions not available (not running on Cordova)
    console.log("cordova.plugins.permissions not available.");
  }
}
async function downloadAudioRecording(audioResult) {
  const { cordova } = window;
  if (cordova && cordova.plugins && cordova.plugins.permissions) {
    // Use Cordova File Transfer plugin to download the audio file
    const fileTransfer = new cordova.plugins.FileTransfer();
    const fileUrl = audioResult; // The URL of the audio file to download
    const fileName = `Disciplinary Recording - ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName} ${this.props.editStock.access}.mp3`;

    fileTransfer.download(
      fileUrl,
      cordova.file.externalRootDirectory + fileName, // Save the file to the device's external storage
      () => {
        // File download success callback
        console.log('File downloaded successfully.');
      },
      (error) => {
        // File download error callback
        console.error('File download error:', error);
      },
      null,
      {
        // Optional headers if required for the download request
      }
    );
  } else {
    // cordova.plugins.permissions not available (not running on Cordova)
    console.log("cordova.plugins.permissions not available.");
  }
}
async function requestFileDownloadPermissions() {
  const { cordova } = window;
  if (cordova && cordova.plugins.permissions) {
    const permission = cordova.plugins.permissions.WRITE_EXTERNAL_STORAGE;
    try {
      const result = await cordova.plugins.permissions.requestPermission(permission);
      if (result && result.state === 'granted') {
        // File download permissions are granted, proceed with file downloads
        console.log("File download permissions granted.");
      } else {
        // File download permissions are denied or not granted, handle accordingly
        console.log("File download permissions not granted.");
      }
    } catch (error) {
      // Error occurred while requesting permission
      console.log("Error occurred while requesting file download permissions.");
    }
  } else {
    // cordova.plugins.permissions not available (not running on Cordova)
    console.log("cordova.plugins.permissions not available.");
  }
}
if (window.cordova) {
  document.addEventListener(
    'deviceready',
    async () => {
      // Request audio permissions before rendering the app
      await requestAudioPermissions();
      // Request file download permissions before starting the app
      await requestFileDownloadPermissions();
      // Request audio permissions before rendering the app
      await requestAudioPermissions();

      // Render your React components after Cordova is ready and permissions are granted
      render(<Root />, rootElement);
      serviceWorker.register();
    },
    false
  );
} else {
  // For non-Cordova environments (e.g., web browsers), just render the app
  render(<Root />, rootElement);
  serviceWorker.register();
}
