import user from './user';
import cddata from './cddata';
import stock from './stock';
import requisition from './requisition';
import pexunit from './pexunit';
import request from './request';
import pexrequest from './pexrequest';
import admin from './admin';
import status from './status';
import notifications from './notifications';
import bits from './bits';
import sites from './sites';

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        if (action.payload.user) {
          if (action.payload.user.user) {
            /*eslint-disable */
            // window.pubnub = new PubNub({
            //   publishKey: 'pub-c-3e443c2e-9f0a-4f95-9e14-897384b93826',
            //   subscribeKey: 'sub-c-aa1715c6-fabb-11eb-b38c-d287984b4121'
            // });
            /*eslint-enable */
            // window.pubnub.setUUID(action.payload.user.user._id);
            let channels = [action.payload.user.user._id, 'global'];
            if (action.payload.user.user.type === 'admin') {
              channels.push('admin');
              channels.push('managers');
            }
            if (action.payload.user.user.type === 'manager') {
              channels.push('managers');
            }
            if (action.payload.user.user.type === 'buyer') {
              channels.push('buyers');
            }
            // window.pubnub.subscribe({
            //   channels: channels
            // });
            // if (window.cordova) {
            //   window.FirebasePlugin.getToken(
            //     token => {
            //       window.pubnub.push.addChannels(
            //         {
            //           channels: channels,
            //           device: token,
            //           pushGateway: 'gcm' // apns, gcm, mpns
            //         },
            //         function(status) {
            //           if (status.error) {
            //             console.log('operation failed w/ error:', status);
            //           }
            //         }
            //       );
            //     },
            //     error => {
            //       console.error(error);
            //     }
            //   );
            //   window.FirebasePlugin.onMessageReceived(
            //     notif => {
            //       notif.message = JSON.parse(notif.message);
            //       // this.props.setPubnub(notif)
            //     },
            //     error => {
            //       console.error(error);
            //     }
            //   );
            // } else {
            // }
          }
        }
      }
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  user,
  cddata,
  stock,
  requisition,
  pexunit,
  request,
  pexrequest,
  admin,
  status,
  notifications,
  bits,
  sites
};
