import { getToken } from "src/redux/selectors";

export function addRequest(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/addpexrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add request" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveRequest(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/savepexrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to edit request" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteRequest(id, pexunitId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/deletepexrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id, pexunitId: pexunitId }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete request" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findallpexrequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "PEXREQUEST_FIND_ALLPEXREQUESTS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findMyRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findmypexrequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "PEXREQUEST_FIND_MYPEXREQUESTS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findJobNumbers(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/getjobnumbers?value=" +
          value,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to get job numbers" });
        }
        return resolve(await res.json());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findTechnicians(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/gettechnicians?value=" +
          value,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to get technicians" });
        }
        return resolve(await res.json());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findRequestDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findrequestdetails?id=" +
          id,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "VEHICLES_FIND_VEHICLEDETAILS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findauctionrequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "VEHICLES_FIND_AUCTIONVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDoneRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/finddealdonerequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "VEHICLES_FIND_DEALDONEVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionWonRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findauctionwonrequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "VEHICLES_FIND_WONVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionLostRequest() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/findauctionlostrequest",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "VEHICLES_FIND_LOSTVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditRequest(request) {
  return dispatch =>
    dispatch({
      type: "VEHICLES_SET_EDITVEHICLE",
      data: request,
    });
}

export function setRequestDetailsId(id) {
  return dispatch =>
    dispatch({
      type: "VEHICLES_SET_VEHICLEDETAILSID",
      data: id,
    });
}

export function findVariants(model) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/cddata/findvariants?model=" +
          model,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_VARIANTS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears(variant) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/cddata/findyears?variant=" +
          variant,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_YEARS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/request/acceptoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/request/rejectoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/acceptadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/request/rejectadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}
