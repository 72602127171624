import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter, Link } from 'react-router-dom';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getAllBits,
  getTimeStampBits,
  getBits140,
  getBits171,
  getTodayBits
} from 'src/redux/selectors';
import {
  addBits,
  deleteBits,
  findAllBits,
  find140mm,
  find171mm,
  findAllBitsUpdatedToday
} from 'src/redux/actions/bits';
import { addRequest } from 'src/redux/actions/request';

class UserManagement extends Component {
  render = () => {
    const {
      Layout,
      push,
      history,
      bits,
      bits140,
      bits171,
      findAllBits,
      addBits,
      deleteBits,
      sendNotification,
      find140mm,
      find171mm,
      findAllBitsUpdatedToday
    } = this.props;
    return (
      <Layout
        push={push}
        history={history}
        addBits={addBits}
        bits={bits}
        findAllBits={findAllBits}
        findAllBitsUpdatedToday={findAllBitsUpdatedToday}
        deleteBits={deleteBits}
        sendNotification={sendNotification}
        find140mm={find140mm}
        find171mm={find171mm}
        bits140={bits140}
        bits171={bits171}
      />
    );
  };
}

const mapStateToProps = state => ({
  bits: state.bits.allBits || [],
  bits140: state.bits.bits140 || [],
  bits171: state.bits.bits171 || []
});

const mapDispatchToProps = {
  push,
  findAllBits,
  addBits,
  deleteBits,
  sendNotification,
  find140mm,
  find171mm,
  findAllBitsUpdatedToday
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserManagement)
);
