import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { sendNotification } from 'src/redux/actions/notifications';
import { findAllPexunit } from 'src/redux/actions/pexunit';
import { addRequest, findJobNumbers } from 'src/redux/actions/pexrequest';
import { withRouter, Link } from 'react-router-dom';

class Pexunit extends Component {
  render = () => {
    const {
      Layout,
      pexunit,
      user,
      findAllPexunit,
      addRequest,
      sendNotification,
      push,
      findJobNumbers,
      history
    } = this.props;
    return (
      <Layout
        pexunit={pexunit}
        user={user}
        findAllPexunit={findAllPexunit}
        addRequest={addRequest}
        sendNotification={sendNotification}
        push={push}
        findJobNumbers={findJobNumbers}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  pexunit: state.pexunit.allPexunit || [],
  user: state.user.user || {}
});

const mapDispatchToProps = {
  findAllPexunit,
  addRequest,
  sendNotification,
  push,
  findJobNumbers
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Pexunit)
);
