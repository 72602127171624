export const initialState = {
  allBits: [],
  editBits: undefined,
  timeStamp: undefined
};

export default function bitsReducer(state = initialState, action) {
  switch (action.type) {
    case 'BITS_FIND_ALLBITS': {
      if (action.data) {
        const allBits = action.data.allBits;

        return {
          ...state,
          allBits: allBits
        };
      }
      return initialState;
    }
    case 'BIT_SET_EDITBITS': {
      if (action.data) {
        const editBits = action.data;

        return {
          ...state,
          editBits: editBits
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
