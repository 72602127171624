import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { sendNotification } from "src/redux/actions/notifications";
import { addStock, deleteStock, findAllStock } from "src/redux/actions/stock";
import {
  addBits,
  deleteBits,
  findAllBits,
  findAllBitsUpdatedToday,
  updateBits,
} from "src/redux/actions/bits";
import {
  findAllUsers,
  findAllManagers,
  findAllBuyers,
} from "src/redux/actions/admin";
import { findAllRigs } from "src/redux/actions/rigs";
import {
  addRequest,
  addBatchRequest,
  findJobNumbers,
  findTechnicians,
} from "src/redux/actions/request";
import { findAllSites } from "src/redux/actions/sites";

import { withRouter, Link } from "react-router-dom";

class Stock extends Component {
  render = () => {
    const {
      Layout,
      stock,
      user,
      findAllStock,
      addStock,
      users,
      findAllUsers,
      deleteStock,
      addRequest,
      addBatchRequest,
      findJobNumbers,
      findTechnicians,
      sendNotification,
      push,
      history,
      findAllBits,
      findAllRigs,
      rigs,
      addBits,
      deleteBits,
      bits,
      updateBits,
      findAllBitsUpdatedToday,
      findAllSites,
      sites,
    } = this.props;
    return (
      <Layout
        stock={stock}
        user={user}
        addStock={addStock}
        findAllStock={findAllStock}
        addRequest={addRequest}
        findTechnicians={findTechnicians}
        findAllUsers={findAllUsers}
        findAllManagers={findAllManagers}
        findAllBuyers={findAllBuyers}
        addBatchRequest={addBatchRequest}
        findJobNumbers={findJobNumbers}
        deleteStock={deleteStock}
        sendNotification={sendNotification}
        push={push}
        history={history}
        rigs={rigs}
        findAllRigs={findAllRigs}
        bits={bits}
        findAllBits={findAllBits}
        deleteBits={deleteBits}
        addBits={addBits}
        updateBits={updateBits}
        findAllBitsUpdatedToday={findAllBitsUpdatedToday}
        findAllSites={findAllSites}
        sites={sites}
        users={users}
      />
    );
  };
}

const mapStateToProps = state => ({
  stock: state.stock.allStock || [],
  rigs: state.admin.allRigs || [],
  user: state.user.user || {},
  bits: state.bits.allBits || [],
  sites: state.sites.allSites,
  users: state.admin.allUsers || [],
});

const mapDispatchToProps = {
  addStock,
  deleteStock,
  findAllStock,
  findAllRigs,
  findAllUsers,
  findAllManagers,
  findAllBuyers,
  addRequest,
  sendNotification,
  push,
  addBatchRequest,
  findJobNumbers,
  findTechnicians,
  findAllBits,
  addBits,
  updateBits,
  findAllBitsUpdatedToday,
  findAllSites,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Stock)
);
