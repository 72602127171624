import React from "react";
import styled from "styled-components";
import { SettingFilled } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Menu, Select, Row, Col, Spin, Input, List, Switch, Tabs } from "antd";
import { RiProfileFill } from "@react-icons/all-files/ri/RiProfileFill";
import { Layout } from "antd";
import Resizer from "react-image-file-resizer";
import { ButtonSquare } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const FormItem = Form.Item;
const Option = Select.Option;
const Account = props => {
  return (
    <Form onSubmit={props.handleAccountInfoSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>Username</span>
            }
          >
            {props.props.form.getFieldDecorator("username", {
              rules: [{ required: true, message: "Name required" }],
              initialValue: props.props.user.username,
            })(<Input disabled />)}
          </FormItem>
        </Col>

        <Col span={11}>
          <FormItem
            label={<span style={{ color: "#6D6E70", fontSize: 16 }}>Name</span>}
          >
            {props.props.form.getFieldDecorator("name", {
              rules: [{ required: true, message: "Name required" }],
              initialValue: props.props.user.name,
            })(<Input disabled />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>Email</span>
            }
          >
            {props.props.form.getFieldDecorator("surname", {
              rules: [{ required: true, message: "Surname required" }],
              initialValue: props.props.user.email,
            })(<Input disabled />)}
          </FormItem>
        </Col>

        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>Number</span>
            }
          >
            {props.props.form.getFieldDecorator("number", {
              rules: [{ required: true, message: "Number required" }],
              initialValue: props.props.user.number,
            })(<Input disabled />)}
          </FormItem>
        </Col>
      </Row>
      {/* <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row> */}
    </Form>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("newPass", {
              rules: [
                { required: true, message: "New password required" },
                { validator: props.validateToNextPassword },
              ],
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
              />
            )}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Confirm Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("confirmPass", {
              rules: [
                { required: true, message: "Confirm new password" },
                { validator: props.compareToFirstPassword },
              ],
            })(<Input.Password type="password" />)}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem>
            <ButtonSquare
              style={{ height: 45, borderRadius: "4em", float: "right" }}
              className="submit-button"
              type="primary"
              htmlType="submit"
            >
              Save Changes
            </ButtonSquare>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when my car gets a bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when Asambo 360 reviews my stock
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me of Asambo 360 updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: this.props.user.image,
      activeView: "account",
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPass"], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              "success",
              "Success",
              "Successfully saved account information"
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", "Notify3", e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify("success", "Success", "Successfully changed password");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined,
    });
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 20 }}>
            <RiProfileFill
              style={{
                fontSize: 25,
                color: "#009BDF",
                marginRight: 10,
                marginBottom: "-0.1em",
              }}
            />
            <span style={{ fontWeight: 750, fontSize: 18 }}>
              Account Settings
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Info" key="1">
                  <Account
                    props={this.props}
                    image={this.state.image}
                    beforeUpload={this.beforeUpload}
                    handleImageUpload={this.handleImageUpload}
                    handleProfileImage={this.handleProfileImage}
                    handleAccountInfoSave={this.handleAccountInfoSave}
                    removeImage={this.removeImage}
                  />
                </TabPane>
                <TabPane tab="Password" key="2">
                  <Password
                    props={this.props}
                    handlePasswordChange={this.handlePasswordChange}
                    compareToFirstPassword={this.compareToFirstPassword}
                    validateToNextPassword={this.validateToNextPassword}
                    handleConfirmBlur={this.handleConfirmBlur}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
