import React from "react";
import styled from "styled-components";
import {
  DollarOutlined,
  ScanOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  Statistic,
} from "antd";
import moment from "moment";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const numeral = require("numeral");
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
`;
class StockFormClass extends React.Component {
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          serial: result.text,
        });
      },
      error => {
        alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: "Place a barcode inside the scan area",
      }
    );
  };

  render() {
    return (
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <FormItem label={<span>Item Code</span>}>
          {this.props.form.getFieldDecorator("itemCode", {
            rules: [{ required: true, message: "Item code required" }],
            initialValue: this.props.editStock
              ? this.props.editStock.itemCode
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Description</span>}>
          {this.props.form.getFieldDecorator("description", {
            rules: [{ required: true, message: "Description required" }],
            initialValue: this.props.editStock
              ? this.props.editStock.description
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Unit</span>}>
          {this.props.form.getFieldDecorator("unit", {
            rules: [
              {
                required: true,
                message: "Unit required",
              },
            ],
            initialValue: this.props.editStock ? this.props.editStock.unit : "",
          })(
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a stock unit"
              optionFilterProp="children"
            >
              <Option key={"Assembled"} value={"Assembled"}>
                {"Assembled"}
              </Option>
              <Option key={"Component"} value={"Component"}>
                {"Component"}
              </Option>
            </Select>
          )}
        </FormItem>
        <FormItem label={<span>Serial (If Applicable)</span>}>
          {this.props.form.getFieldDecorator("serial", {
            rules: [{ required: false }],
            initialValue: this.props.editStock
              ? this.props.editStock.serial
              : "",
          })(
            <Input
              suffix={
                window.cordova ? (
                  <ScanOutlined onClick={this.barcodeScanner} />
                ) : null
              }
            />
          )}
        </FormItem>
        <FormItem label={<span>Inventory Value</span>}>
          {this.props.form.getFieldDecorator("invValue", {
            rules: [
              {
                required: true,
                message: "Value required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.invValue
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Retail Value</span>}>
          {this.props.form.getFieldDecorator("retValue", {
            rules: [
              {
                required: true,
                message: "Value required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.retValue
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Low Stock Quantity</span>}>
          {this.props.form.getFieldDecorator("lowStock", {
            rules: [
              {
                required: true,
                message: "Quantity required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.lowStock
              : "",
          })(<Input type={"number"} />)}
        </FormItem>
        <FormItem label={<span>Quantity</span>}>
          {this.props.form.getFieldDecorator("qty", {
            rules: [
              {
                required: true,
                message: "Quantity required",
              },
            ],
            initialValue: this.props.editStock ? this.props.editStock.qty : "",
          })(<Input type={"number"} />)}
        </FormItem>
        <Row type="flex" justify="space-around" align="middle">
          <Button type="primary" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type="primary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>
      </Form>
    );
  }
}
const StockForm = Form.create()(StockFormClass);
class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
      stockTotal: 0.0,
      retailTotal: 0.0,
      potentialTotal: 0.0,
      margin: 0,
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock()
      .then(() => {
        let stock = this.props.stock;
        let stockTotal = 0.0;
        let retailTotal = 0.0;
        let potentialTotal = 0.0;
        let margin = 0;
        stock.forEach(stock => {
          stockTotal = stockTotal + parseFloat(stock.invValue) * stock.qty;
          retailTotal = retailTotal + parseFloat(stock.retValue) * stock.qty;
        });
        potentialTotal = retailTotal - stockTotal;
        margin = (potentialTotal / retailTotal) * 100;
        this.setState({
          loading: false,
          stockTotal: stockTotal,
          retailTotal: retailTotal,
          potentialTotal: potentialTotal,
          margin: margin,
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  render() {
    const { stock } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: "center" }}
        >
          <Col xs={24} md={12} style={{ marginLeft: "-0.8em" }}>
            <DollarOutlined
              style={{ fontSize: 25, color: "#009BDF", marginRight: 4 }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>Evaluation</span>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            xs={24}
            md={12}
          />
        </Row>
        <Spin spinning={this.state.loading}>
          <Row
            style={{
              background: "white",
              marginBottom: 20,
              marginTop: 20,
              padding: 15,
            }}
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col style={{ display: "flex" }} xs={12} md={6}>
              <Statistic
                style={{ margin: "auto" }}
                title="Total Stock Value"
                value={this.state.stockTotal}
                precision={2}
                prefix="R"
              />
            </Col>
            <Col style={{ display: "flex" }} xs={12} md={6}>
              <Statistic
                style={{ margin: "auto" }}
                title="Total Retail Value"
                value={this.state.retailTotal}
                precision={2}
                prefix="R"
              />
            </Col>
            <Col style={{ display: "flex" }} xs={12} md={6}>
              <Statistic
                style={{ margin: "auto" }}
                title="Potential Profit"
                value={this.state.potentialTotal}
                precision={2}
                prefix="R"
              />
            </Col>
            <Col style={{ display: "flex" }} xs={12} md={6}>
              <Statistic
                style={{ margin: "auto" }}
                title="Profit Margin"
                value={this.state.margin}
                precision={0}
                suffix="%"
              />
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <StyledTable
                style={{ background: "white" }}
                rowKey="_id"
                size="middle"
                expandedRowRender={
                  window.innerWidth <= 420
                    ? record => (
                      <Card
                        size="small"
                        title={record.itemCode}
                        style={{ width: 300, marginLeft: -35 }}
                      >
                        <p>Quantity: {record.qty}</p>
                        <p>
                          Cost: R {parseFloat(record.invValue).toFixed(2)}
                        </p>
                        <p>
                          Inventory Value: R{" "}
                          {(parseFloat(record.invValue) * record.qty).toFixed(
                            2
                          )}
                        </p>
                        <p>
                          Retail Value: R{" "}
                          {(parseFloat(record.retValue) * record.qty).toFixed(
                            2
                          )}
                        </p>
                        <p>
                          Potential Profit: R{" "}
                          {(
                            parseFloat(record.retValue) * record.qty -
                            parseFloat(record.invValue) * record.qty
                          ).toFixed(2)}
                        </p>
                        <p>
                          Margin:{" "}
                          {(
                            ((parseFloat(record.retValue) * record.qty -
                              parseFloat(record.invValue) * record.qty) /
                              (parseFloat(record.retValue) * record.qty)) *
                            100
                          ).toFixed(0)}
                          %
                        </p>
                      </Card>
                    )
                    : null
                }
                dataSource={this.props.stock}
                onChange={this.handleSort}
                onRow={record => {
                  if (parseInt(record.qty) === 0) {
                    return { style: { background: "#ffd3d3" } };
                  }
                }}
              >
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("itemCode")}
                    title="Item Code"
                    dataIndex="itemCode"
                    key="itemCode"
                  />
                ) : null}
                <Column
                  {...this.getColumnSearchProps("description")}
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("qty")}
                    title="Quantity"
                    dataIndex="qty"
                    key="qty"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    title="Cost"
                    render={(text, record) => {
                      return (
                        <span>R {parseFloat(record.invValue).toFixed(2)}</span>
                      );
                    }}
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    title="Inventory Value"
                    render={(text, record) => {
                      return (
                        <span>
                          R{" "}
                          {(parseFloat(record.invValue) * record.qty).toFixed(
                            2
                          )}
                        </span>
                      );
                    }}
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    title="Retail Value"
                    render={(text, record) => {
                      return (
                        <span>
                          R{" "}
                          {(parseFloat(record.retValue) * record.qty).toFixed(
                            2
                          )}
                        </span>
                      );
                    }}
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    title="Potential Profit"
                    render={(text, record) => {
                      return (
                        <span>
                          R{" "}
                          {(
                            parseFloat(record.retValue) * record.qty -
                            parseFloat(record.invValue) * record.qty
                          ).toFixed(2)}
                        </span>
                      );
                    }}
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    title="Margin"
                    render={(text, record) => {
                      return (
                        <span>
                          {(
                            ((parseFloat(record.retValue) * record.qty -
                              parseFloat(record.invValue) * record.qty) /
                              (parseFloat(record.retValue) * record.qty)) *
                            100
                          ).toFixed(0)}
                          %
                        </span>
                      );
                    }}
                  />
                ) : null}
              </StyledTable>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default Stock;
