import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findAllUsers,
  findAllManagers,
  findAllBuyers,
  addUser,
  addManager,
  addBuyer,
  deleteUser
} from 'src/redux/actions/admin';
import { getLocale, getUser } from 'src/redux/selectors';
import { sendNotification } from 'src/redux/actions/notifications';
import { withRouter, Link } from 'react-router-dom';
import { findAllSites } from 'src/redux/actions/sites';

class Users extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      users,
      managers,
      buyers,
      user,
      findAllUsers,
      findAllManagers,
      findAllBuyers,
      addUser,
      addManager,
      addBuyer,
      deleteUser,
      sendNotification,
      push,
      history,
      findAllSites,
      sites
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        users={users}
        managers={managers}
        buyers={buyers}
        user={user}
        findAllUsers={findAllUsers}
        findAllManagers={findAllManagers}
        findAllBuyers={findAllBuyers}
        addUser={addUser}
        addManager={addManager}
        addBuyer={addBuyer}
        deleteUser={deleteUser}
        sendNotification={sendNotification}
        push={push}
        history={history}
        findAllSites={findAllSites}
        sites={sites}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  users: state.admin.allUsers || [],
  managers: state.admin.allManagers || [],
  buyers: state.admin.allBuyers || [],
  user: getUser(state) || {},
  sites: state.sites.allSites
});

const mapDispatchToProps = {
  getLocale,
  findAllUsers,
  findAllManagers,
  findAllBuyers,
  addUser,
  addManager,
  addBuyer,
  deleteUser,
  sendNotification,
  push,
  findAllSites
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Users)
);
