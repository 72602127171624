import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import UserSidebar from './UserSidebar';
import ManagerSidebar from './ManagerSidebar';
import BuyerSidebar from './BuyerSidebar';
import AdminSidebar from './AdminSidebar';
import ResHeader from 'src/components/layout/ResHeader';
import ResFooter from 'src/components/layout/ResFooter';
import { getSidebarCollapsed, getUser } from 'src/redux/selectors';
import BackgroundImage from 'src/static/background.svg';
const { Content } = Layout;
if (window.innerWidth <= 1100) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const Template = props => {
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <ResHeader />
        <Layout>
          {props.user.type === 'user' ? null : props.user.type === 'manager' ? ( //<UserSidebar />
            <ManagerSidebar />
          ) : props.user.type === 'buyer' ? (
            null
            // <BuyerSidebar />
          ) : (
            <AdminSidebar />
          )}
          <Layout
            style={{
              backgroundColor: '#F8F8FF',
              backgroundPosition: 'center',
              ...(props.background && {
                backgroundImage: `url(${BackgroundImage})`
              }),
              marginLeft: props.collapsed ? 0 : 0,
              marginTop: window.isPhone ? 0 : 40,

              transition: 'all 0.2s'
            }}
          >
            <Content
              style={{
                transition: 'all 0.2s',
                margin: 20,
                //minHeight: "calc(100% + 580px)",
                minHeight: window.isPhone ? 'calc(100vh - 3.5em)' : ''
              }}
            >
              {props.children}
            </Content>
            <ResFooter />
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
    user: getUser(state) || {}
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
