export const initialState = {
  sidebarCollapsed: true,
  rig1: false,
  rig2: false,
  rig3: false,
  rig4: false,
  rig5: false,
  rig6: false,
  rig7: false,
  rig8: false,
  rig9: false,
  rig10: false,
  serial: 'None'
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case 'SIDEBAR_COLLAPSE': {
      let partialState = {
        sidebarCollapsed: !state.sidebarCollapsed
      };

      return {
        ...state,
        ...partialState
      };
    }
    case 'UPDATE_SERIAL': {
      let serial = {
        serial: state.selectedSerial.serial
      };

      return {
        ...state,
        ...serial
      };
    }
    case 'UPDATE_RIG1': {
      return {
        ...state,
        rig1: true,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG2': {
      return {
        ...state,
        rig1: false,
        rig2: true,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG3': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: true
      };
    }
    case 'UPDATE_RIG4': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: true,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG5': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: true,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG6': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: true,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG7': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: true,
        rig8: false,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG8': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: true,
        rig9: false,
        rig10: false
      };
    }
    case 'UPDATE_RIG9': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: true,
        rig10: false
      };
    }
    case 'UPDATE_RIG10': {
      return {
        ...state,
        rig1: false,
        rig2: false,
        rig3: false,
        rig4: false,
        rig5: false,
        rig6: false,
        rig7: false,
        rig8: false,
        rig9: false,
        rig10: true
      };
    }
    default:
      return state;
  }
}
