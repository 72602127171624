import React, { useState } from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { withRouter, Link } from "react-router-dom";
import "@ant-design/compatible/assets/index.css";
import NewIcon from "src/static/new-icon.svg";
import SharpIcon from "src/static/sharp-icon.svg";
import DamagedIcon from "src/static/damaged-icon.svg";
import LostIcon from "src/static/lost-icon.svg";
import RigImg from "src/static/rig-img.png";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
} from "antd";
import moment from "moment";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { ButtonRadius } from "src/components/elements/Button";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import SignatureCanvas from "react-signature-canvas";
import {
  updateRig1,
  updateRig2,
  updateRig3,
  updateRig4,
  updateRig5,
  updateRig6,
  updateRig7,
  updateRig8,
  updateRig9,
  updateRig10,
} from "src/redux/actions/status";
import { connect } from "react-redux";
import { thisExpression } from "@babel/types";
function onChange(date, dateString) {
  console.log(date, dateString);
}
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const numeral = require("numeral");
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledCard = styled(Card)`
  .ant-card-body {
    height: 5.4em;
    padding: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 3px !important;
    display: flex;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 55px !important;
  border-radius: 0.5em !important;
  padding-top: 0px !important;
  .ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.45) !important;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  height: 55px !important;
  border-radius: 0.5em !important;
  padding-top: 11px !important;

  .ant-input-number:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-input-number-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  border-radius: 0.5em !important;
  ::placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.8em !important;
    width: 0.8em !important;
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
`;
const StyledModalRig = styled(Modal)`
  .ant-modal-content {
    text-align: center !important;
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;

    pointer-events: auto;
    min-height: 98vh;
  }
  .ant-modal-footer {
    display: none;
  }
`;
const EditableContext = React.createContext();

class StockFormClassBit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Bit
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Stock Nr."
                  value={stock.bit ? stock.bit.serial : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                value={stock.bit ? stock.bit.status : "None"}
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  value={stock.bit ? stock.bit.description : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [
                    {
                      required: true,
                      message: "Meters required",
                      type: "number",
                    },
                  ],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedStatus", {
                  rules: [{ required: true, message: "Status is required" }],
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Bit") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}

          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassHammer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Hammer
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Stock Nr."
                  value={stock.hammer ? stock.hammer.serial : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                value={stock.hammer ? stock.hammer.status : "None"}
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  value={stock.hammer ? stock.hammer.description : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: true, message: "Meters required" }],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedStatus", {
                  rules: [{ required: true, message: "Status is required" }],
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Hammer") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassRod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Rod
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Stock Nr."
                  value={stock.rod ? stock.rod.serial : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                value={stock.rod ? stock.rod.status : "None"}
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  value={stock.rod ? stock.rod.description : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: true, message: "Meters required" }],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedStatus", {
                  rules: [{ required: true, message: "Status is required" }],
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  onSearch={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Rod") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassAdapter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Adapter
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Stock Nr."
                  value={stock.adapter ? stock.adapter.serial : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                value={stock.adapter ? stock.adapter.status : "None"}
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  value={stock.adapter ? stock.adapter.description : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: true, message: "Meters required" }],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedStatus", {
                  rules: [{ required: true, message: "Status is required" }],
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Adapter") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassRefurbish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Refurbish
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Stock Nr."
                  value={stock.refurbish ? stock.refurbish.serial : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                value={stock.refurbish ? stock.refurbish.status : "None"}
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  value={stock.refurbish ? stock.refurbish.description : "None"}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: true, message: "Meters required" }],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedStatus", {
                  rules: [{ required: true, message: "Status is required" }],
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Refurbish") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassRigMeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftDamages: false,
      meters: "",
    };
  }
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  updateRig = () => {
    let rig = JSON.parse(JSON.stringify(this.props.stock));
    rig.meters = rig.meters
      ? rig.meters + this.state.meters
      : this.state.meters;
    this.props
      .addStock(rig)

      .then(suc => {
        this.props.onOk();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <span>
        <Form layout="horizontal">
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            <Col
              xs={12}
              md={12}
              style={{ marginLeft: "-0.8em", marginTop: "0em" }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "#FF9D66",
                  marginLeft: 10,
                  fontFamily: "Roboto",
                }}
              >
                Rig Meters
              </span>{" "}
              <br />
            </Col>
          </Row>
          <span>
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display: this.state.addBitsVisible ? "flex" : "none",
              }}
              type="flex"
              justify="space-around"
              align="middle"
            />
            <Row style={{ marginTop: "1em" }}>
              <Col span={24}>
                <StyledInput
                  placeholder="Rig Meters"
                  onChange={e => {
                    this.setState({ meters: parseInt(e.currentTarget.value) });
                  }}
                  //value={this.state.meters}
                  style={{ width: "100%", height: 55 }}
                  type="Number"
                />
              </Col>
              {/* <Col span={2} /> */}
              {/* <Col span={11}>
                <FormItem>
                  {this.props.form.getFieldDecorator("Pit Drilled", {
                    rules: [
                      { required: true, message: "Pit Drilled required" },
                    ],
                  })(
                    <StyledInput
                      placeholder="Pit Drilled"
                      //value={
                      //  this.props.editStock ? this.props.editStock.itemCode : ""
                      //}
                      style={{ width: "100%", height: 55 }}
                    />
                  )}
                </FormItem>
              </Col> */}
            </Row>
          </span>
        </Form>
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "2em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "2em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.updateRig}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </span>
    );
  }
}
class StockFormClassShiftMeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftMeters: false,
    };
  }
  showModalShiftMeters = () => {
    this.setState({
      visibleShiftMeters: true,
    });
  };

  handleOkShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  handleCancelShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Shift Meters
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("itemCode", {
                  rules: [{ required: true, message: "Serial required" }],
                })(
                  <StyledSelect
                    placeholder="Stock Nr."
                    style={{ width: "100%" }}
                    onChange={bitSerial => {
                      const selectedBit = this.props.bits.find(
                        bit => bit.serial === bitSerial
                      );
                      this.setState({ selectedBit });
                    }}
                  >
                    {this.props.bits.map(bitserial => {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    })}{" "}
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                // disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={24}>
              <FormItem>
                <StyledInput
                  // disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledInput
                  placeholder="Stock Nr."
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {" "}
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col> */}
          <Col span={11}>
            <FormItem>
              <StyledSelect
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" /> None
                </Option>
              </StyledSelect>
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {/*{this.props.form.getFieldDecorator("description", {
                rules: [{ required: true, message: "Description required" }],
                initialValue: this.props.editPexunit
                  ? this.props.editPexunit.description
                  : "",
              })(*/}
              <StyledInput
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
              />
              {/*)}*/}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassDamages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftDamages: false,
    };
  }
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "0em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Damages
            </span>{" "}
            <br />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.6em" }}>
          <Col span={7}>
            <span>Bit</span>
            <FormItem>
              {this.props.form.getFieldDecorator("bitSerial", {})(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Bit") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              <Input
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
                disabled
                value={
                  this.state.selectedBit
                    ? this.state.selectedBit.description
                    : "N/A"
                }
              />
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              {this.props.form.getFieldDecorator("bitReason", {})(
                <StyledSelect
                  placeholder="Reason"
                  style={{ width: "100%", height: 55 }}
                >
                  <Option value={"Stuck"}>Stuck</Option>
                  <Option value="Break">Break</Option>
                  <Option value="Formation">Formation</Option>
                  <Option value="Lost in hole">Lost in hole</Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.6em" }}>
          <Col span={7}>
            <span>Hammer</span>
            <FormItem>
              {this.props.form.getFieldDecorator("hammerSerial", {})(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedHammer = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedHammer });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Hammer") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              <Input
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
                disabled
                value={
                  this.state.selectedHammer
                    ? this.state.selectedHammer.description
                    : "N/A"
                }
              />
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              {this.props.form.getFieldDecorator("hammerReason", {})(
                <StyledSelect
                  placeholder="Reason"
                  style={{ width: "100%", height: 55 }}
                >
                  <Option value={"Stuck"}>Stuck</Option>
                  <Option value="Break">Break</Option>
                  <Option value="Formation">Formation</Option>
                  <Option value="Lost in hole">Lost in hole</Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.6em" }}>
          <Col span={7}>
            <span>Rod</span>
            <FormItem>
              {this.props.form.getFieldDecorator("rodSerial", {})(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedRod = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedRod });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Rod") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              <Input
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
                disabled
                value={
                  this.state.selectedRod
                    ? this.state.selectedRod.description
                    : "N/A"
                }
              />
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              {this.props.form.getFieldDecorator("rodReason", {})(
                <StyledSelect
                  placeholder="Reason"
                  style={{ width: "100%", height: 55 }}
                >
                  <Option value={"Stuck"}>Stuck</Option>
                  <Option value="Break">Break</Option>
                  <Option value="Formation">Formation</Option>
                  <Option value="Lost in hole">Lost in hole</Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.6em" }}>
          <Col span={7}>
            <span>Adapter</span>
            <FormItem>
              {this.props.form.getFieldDecorator("adapterSerial", {})(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedAdapter = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedAdapter });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Adapter") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              <Input
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
                disabled
                value={
                  this.state.selectedAdapter
                    ? this.state.selectedAdapter.description
                    : "N/A"
                }
              />
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              {this.props.form.getFieldDecorator("adapterReason", {})(
                <StyledSelect
                  placeholder="Reason"
                  style={{ width: "100%", height: 55 }}
                >
                  <Option value={"Stuck"}>Stuck</Option>
                  <Option value="Break">Break</Option>
                  <Option value="Formation">Formation</Option>
                  <Option value="Lost in hole">Lost in hole</Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.6em" }}>
          <Col span={7}>
            <span>Refurbish</span>
            <FormItem>
              {this.props.form.getFieldDecorator("refurbishSerial", {})(
                <StyledSelect
                  showSearch
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedRefurbish = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedRefurbish });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    if (bitserial.size === "Refurbish") {
                      return (
                        <Option value={bitserial.serial}>
                          {bitserial.serial}
                        </Option>
                      );
                    }
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              <Input
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
                disabled
                value={
                  this.state.selectedRefurbish
                    ? this.state.selectedRefurbish.description
                    : "N/A"
                }
              />
            </FormItem>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <FormItem style={{ marginTop: "1.6em" }}>
              {this.props.form.getFieldDecorator("refurbishReason", {})(
                <StyledSelect
                  placeholder="Reason"
                  style={{ width: "100%", height: 55 }}
                >
                  <Option value={"Stuck"}>Stuck</Option>
                  <Option value="Break">Break</Option>
                  <Option value="Formation">Formation</Option>
                  <Option value="Lost in hole">Lost in hole</Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ marginTop: "1em" }}
        >
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //// disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
const StockFormBit = Form.create()(StockFormClassBit);
const StockFormHammer = Form.create()(StockFormClassHammer);
const StockFormRod = Form.create()(StockFormClassRod);
const StockFormAdapter = Form.create()(StockFormClassAdapter);
const StockFormRefurbish = Form.create()(StockFormClassRefurbish);
const StockFormRigMeters = Form.create()(StockFormClassRigMeters);
const StockFormShiftMeters = Form.create()(StockFormClassShiftMeters);
const StockFormDamages = Form.create()(StockFormClassDamages);

class RigFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editStock: this.props.editStock,
      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
      visibleHammer: false,
      visibleShiftDamages: false,
      visibleRod: false,
      visibleAdapter: false,
      visibleRefurbish: false,
      visibleRigMeters: false,
      visibleShiftMeters: false,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  handleCancelShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };
  showModalRigMeters = () => {
    this.setState({
      visibleRigMeters: true,
    });
  };

  handleOkRigMeters = e => {
    console.log(e);
    this.setState({
      visibleRigMeters: false,
    });
  };

  handleCancelRigMeters = e => {
    console.log(e);
    this.setState({
      visibleRigMeters: false,
    });
  };
  showModalRefurbish = () => {
    this.setState({
      visibleRefurbish: true,
    });
  };

  handleOkRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };

  handleCancelRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };
  showModalAdapter = () => {
    this.setState({
      visibleAdapter: true,
    });
  };

  handleOkAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };

  handleCancelAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };
  showModalRod = () => {
    this.setState({
      visibleRod: true,
    });
  };

  handleOkRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };

  handleCancelRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };
  showModalHammer = () => {
    this.setState({
      visibleHammer: true,
    });
  };

  handleOkHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  handleCancelHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  updateSignal;

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.removeEventListener("backbutton", this.handleBack);
    //}
  };
  onRigUpdate = e => {
    this.props.updateRig1();
  };
  onAddStock = e => {
    this.setState({
      addStockVisible: true,
      editStock: null,
      visible: true,
    });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e, visible: true });
  };
  handleAddStock = e => {
    const { selectedBit } = this.state;
    //this.props.updateBits({
    //  // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
    //  // TODO removedId: jy moet die selected bit se id hier sit,
    //  removedMeters: this.state.removedMeters || selectedBit.meter,
    //  removedStatus: selectedBit.status,
    //  replaceId: selectedBit.serial,
    //  replaceSize: this.state.replaceSize || selectedBit.size,
    //  replaceStatus: this.state.newBitStatus || selectedBit.status,
    //  replaceLastUser: this.props.user.name,
    //  replaceSerial: selectedBit.serial,
    //  replaceCurrentRig: this.props.currentRig,
    //});
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .findAllBits(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.props.user.access)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();

                // this.props.history.goBack();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: null,
                  visible: false,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
    this.props.push("/user/dashboard");
  };

  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };

  handleUpdateBits = () => {
    const { selectedBit } = this.state;

    this.props
      .updateBits({
        // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
        // TODO removedId: jy moet die selected bit se id hier sit,
        removedMeters: this.state.removedMeters || selectedBit.meter,
        removedStatus: selectedBit.status,
        replaceId: selectedBit.serial,
        replaceSize: this.state.replaceSize || selectedBit.size,
        replaceStatus: this.state.newBitStatus || selectedBit.status,
        replaceLastUser: this.props.user.name,
        replaceSerial: selectedBit.serial,
        replaceCurrentRig: this.props.currentRig,
      })

      .then(suc => {
        this.setState({ loading: false });
        // TODO wys success message en nav uit view uit
        this.props.push("/user/pexunits");
        //console.log(suc);
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };

  handleUpdateRigBit = async e => {
    let rig = JSON.parse(JSON.stringify(this.props.editStock));
    let currentBit = rig.bit ? JSON.parse(JSON.stringify(rig.bit)) : undefined;
    let bit = JSON.parse(JSON.stringify(this.stockForm.state.selectedBit));

    let removedMeters = this.stockForm.props.form.getFieldValue(
      "removedMeters"
    );
    rig.removedMeters = removedMeters;
    let removedStatus = this.stockForm.props.form.getFieldValue(
      "removedStatus"
    );
    if (currentBit) {
      currentBit.status = removedStatus;
      if (currentBit.cost) {
        currentBit.cost.push({
          date: new Date(),
          meters: parseInt(removedMeters),
        });
      } else {
        currentBit.cost = [
          {
            date: new Date(),
            meters: parseInt(removedMeters),
          },
        ];
      }
      currentBit.currentRig = "None";
      await this.props.addBits(currentBit);
      currentBit.meters = parseInt(removedMeters);
      currentBit.date = new Date();
      if (rig.cost) {
        rig.cost.push(currentBit);
      } else {
        rig.cost = [currentBit];
      }
    }
    bit.currentRig = rig.currentRig;
    rig.bit = bit;
    this.props
      .addStock(rig)

      .then(async suc => {
        await this.props.findAllStock(this.props.user.access);
        await this.props.findAllBits(this.props.user.access);
        this.setState({ editStock: rig, loading: false, visibleBit: false });
        this.stockForm.props.form.resetFields();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };
  handleUpdateRigHammer = async e => {
    let rig = JSON.parse(JSON.stringify(this.props.editStock));
    let currentHammer = rig.hammer
      ? JSON.parse(JSON.stringify(rig.hammer))
      : undefined;
    let hammer = JSON.parse(JSON.stringify(this.stockForm.state.selectedBit));

    let removedMeters = this.stockForm.props.form.getFieldValue(
      "removedMeters"
    );
    rig.removedMeters = removedMeters;
    let removedStatus = this.stockForm.props.form.getFieldValue(
      "removedStatus"
    );
    if (currentHammer) {
      currentHammer.status = removedStatus;
      if (currentHammer.cost) {
        currentHammer.cost.push({
          date: new Date(),
          meters: parseInt(removedMeters),
        });
      } else {
        currentHammer.cost = [
          {
            date: new Date(),
            meters: parseInt(removedMeters),
          },
        ];
      }
      currentHammer.currentRig = "None";
      await this.props.addBits(currentHammer);
      currentHammer.meters = parseInt(removedMeters);
      currentHammer.date = new Date();
      if (rig.cost) {
        rig.cost.push(currentHammer);
      } else {
        rig.cost = [currentHammer];
      }
    }
    hammer.currentRig = rig.currentRig;

    rig.hammer = hammer;
    this.props
      .addStock(rig)

      .then(async suc => {
        await this.props.findAllStock(this.props.user.access);
        await this.props.findAllBits(this.props.user.access);
        this.setState({ editStock: rig, loading: false, visibleHammer: false });
        this.stockForm.props.form.resetFields();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };
  handleUpdateRigRod = async e => {
    let rig = JSON.parse(JSON.stringify(this.props.editStock));
    let currentRod = rig.rod ? JSON.parse(JSON.stringify(rig.rod)) : undefined;
    let rod = JSON.parse(JSON.stringify(this.stockForm.state.selectedBit));

    let removedMeters = this.stockForm.props.form.getFieldValue(
      "removedMeters"
    );
    rig.removedMeters = removedMeters;
    let removedStatus = this.stockForm.props.form.getFieldValue(
      "removedStatus"
    );
    if (currentRod) {
      currentRod.status = removedStatus;
      if (currentRod.cost) {
        currentRod.cost.push({
          date: new Date(),
          meters: parseInt(removedMeters),
        });
      } else {
        currentRod.cost = [
          {
            date: new Date(),
            meters: parseInt(removedMeters),
          },
        ];
      }
      currentRod.currentRig = "None";
      await this.props.addBits(currentRod);
      currentRod.meters = parseInt(removedMeters);
      currentRod.date = new Date();
      if (rig.cost) {
        rig.cost.push(currentRod);
      } else {
        rig.cost = [currentRod];
      }
    }
    rod.currentRig = rig.currentRig;

    rig.rod = rod;
    this.props
      .addStock(rig)

      .then(async suc => {
        await this.props.findAllStock(this.props.user.access);
        await this.props.findAllBits(this.props.user.access);
        this.setState({ editStock: rig, loading: false, visibleRod: false });
        this.stockForm.props.form.resetFields();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };
  handleUpdateRigAdapter = async e => {
    let rig = JSON.parse(JSON.stringify(this.props.editStock));
    let currentAdapter = rig.adapter
      ? JSON.parse(JSON.stringify(rig.adapter))
      : undefined;
    let adapter = JSON.parse(JSON.stringify(this.stockForm.state.selectedBit));

    let removedMeters = this.stockForm.props.form.getFieldValue(
      "removedMeters"
    );
    rig.removedMeters = removedMeters;
    let removedStatus = this.stockForm.props.form.getFieldValue(
      "removedStatus"
    );
    if (currentAdapter) {
      currentAdapter.status = removedStatus;
      if (currentAdapter.cost) {
        currentAdapter.cost.push({
          date: new Date(),
          meters: parseInt(removedMeters),
        });
      } else {
        currentAdapter.cost = [
          {
            date: new Date(),
            meters: parseInt(removedMeters),
          },
        ];
      }
      currentAdapter.currentRig = "None";
      await this.props.addBits(currentAdapter);
      currentAdapter.meters = parseInt(removedMeters);
      currentAdapter.date = new Date();
      if (rig.cost) {
        rig.cost.push(currentAdapter);
      } else {
        rig.cost = [currentAdapter];
      }
    }
    adapter.currentRig = rig.currentRig;

    rig.adapter = adapter;
    this.props
      .addStock(rig)

      .then(async suc => {
        await this.props.findAllStock(this.props.user.access);
        await this.props.findAllBits(this.props.user.access);
        this.setState({
          editStock: rig,
          loading: false,
          visibleAdapter: false,
        });
        this.stockForm.props.form.resetFields();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };
  handleUpdateRigRefurbish = async e => {
    let rig = JSON.parse(JSON.stringify(this.props.editStock));
    let currentRefurbish = rig.refurbish
      ? JSON.parse(JSON.stringify(rig.refurbish))
      : undefined;
    let refurbish = JSON.parse(
      JSON.stringify(this.stockForm.state.selectedBit)
    );

    let removedMeters = this.stockForm.props.form.getFieldValue(
      "removedMeters"
    );
    rig.removedMeters = removedMeters;
    let removedStatus = this.stockForm.props.form.getFieldValue(
      "removedStatus"
    );
    if (currentRefurbish) {
      currentRefurbish.status = removedStatus;
      if (currentRefurbish.cost) {
        currentRefurbish.cost.push({
          date: new Date(),
          meters: parseInt(removedMeters),
        });
      } else {
        currentRefurbish.cost = [
          {
            date: new Date(),
            meters: parseInt(removedMeters),
          },
        ];
      }
      currentRefurbish.currentRig = "None";
      await this.props.addBits(currentRefurbish);
      currentRefurbish.meters = parseInt(removedMeters);
      currentRefurbish.date = new Date();
      if (rig.cost) {
        rig.cost.push(currentRefurbish);
      } else {
        rig.cost = [currentRefurbish];
      }
    }
    refurbish.currentRig = rig.currentRig;

    rig.refurbish = refurbish;
    this.props
      .addStock(rig)

      .then(async suc => {
        await this.props.findAllStock(this.props.user.access);
        await this.props.findAllBits(this.props.user.access);
        this.setState({
          editStock: rig,
          loading: false,
          visibleRefurbish: false,
        });
        this.stockForm.props.form.resetFields();
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };

  handleAddDamage = async e => {
    let values = this.stockForm.props.form.getFieldsValue();
    if (values.bitSerial) {
      let tool = this.props.bits.find(tool => tool.serial === values.bitSerial);
      if (tool.damage) {
        tool.damage.push({
          date: new Date(),
          reason: values.bitReason,
        });
      } else {
        tool.damage = [
          {
            date: new Date(),
            reason: values.bitReason,
          },
        ];
      }
      await this.props.addBits(tool);
    }
    if (values.hammerSerial) {
      let tool = this.props.bits.find(
        tool => tool.serial === values.hammerSerial
      );
      if (tool.damage) {
        tool.damage.push({
          date: new Date(),
          reason: values.hammerReason,
        });
      } else {
        tool.damage = [
          {
            date: new Date(),
            reason: values.hammerReason,
          },
        ];
      }
      await this.props.addBits(tool);
    }
    if (values.rodSerial) {
      let tool = this.props.bits.find(tool => tool.serial === values.rodSerial);
      if (tool.damage) {
        tool.damage.push({
          date: new Date(),
          reason: values.rodReason,
        });
      } else {
        tool.damage = [
          {
            date: new Date(),
            reason: values.rodReason,
          },
        ];
      }
      await this.props.addBits(tool);
    }
    if (values.adapterSerial) {
      let tool = this.props.bits.find(
        tool => tool.serial === values.adapterSerial
      );
      if (tool.damage) {
        tool.damage.push({
          date: new Date(),
          reason: values.adapterReason,
        });
      } else {
        tool.damage = [
          {
            date: new Date(),
            reason: values.adapterReason,
          },
        ];
      }
      await this.props.addBits(tool);
    }
    if (values.refurbishSerial) {
      let tool = this.props.bits.find(
        tool => tool.serial === values.refurbishSerial
      );
      if (tool.damage) {
        tool.damage.push({
          date: new Date(),
          reason: values.refurbishReason,
        });
      } else {
        tool.damage = [
          {
            date: new Date(),
            reason: values.refurbishReason,
          },
        ];
      }
      await this.props.addBits(tool);
    }
    this.setState({ visibleDamages: false });
    this.stockForm.props.form.resetFields();
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              // margin: "-20px",
              // marginBottom: "0.5em",
              textAlign: "center",
            }}
          >
            <Col xs={24} md={24} style={{}}>
              {" "}
              <Link to="/user/pexunits">
                {/* <ArrowLeftOutlined
                  style={{
                    fontSize: '20px',
                    top: '0.6em',
                    left: '0.5em',
                    position: 'absolute',
                    color: 'white'
                  }}
                /> */}
              </Link>
              <ButtonRadius
                style={{
                  padding: 0,
                  height: "2em",
                  margin: "auto",
                  marginTop: "2em",
                  position: "absolute",
                  fontSize: 14,
                  left: "-8.5vw",
                  top: "-1.5em",
                  border: "1px solid rgb(0, 58, 152)",
                  fontFamily: "Open Sans",
                  textShadow: "none",
                  backgroundColor: "rgb(0, 58, 152)",
                  color: "white",
                  width: "2em",
                }}
                type="primary"
                onClick={this.props.onCancel}
                size="large"
                block
              >
                <ArrowLeftOutlined
                  style={{
                    color: "white",
                  }}
                />
              </ButtonRadius>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "#FF9D66",
                  marginLeft: 10,
                  fontFamily: "Roboto",
                }}
              >
                Rig {this.props.editStock.currentRig}
              </span>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "0.5em",
              textAlign: "center",
            }}
          >
            <Col span={24}>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "black",
                  fontFamily: "Poppins",
                }}
              >
                Please Select Tool
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalBit}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Bit
                  </p>
                </StyledCard>
              </Col>
              <Col span={2} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalHammer}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Hammer
                  </p>
                </StyledCard>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalRod}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Rod
                  </p>
                </StyledCard>
              </Col>
              <Col span={2} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalAdapter}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Adapter
                  </p>
                </StyledCard>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={24}
                style={{ margin: "auto", marginTop: "0.5em" }}
                onClick={this.showModalRefurbish}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Refurbishment
                  </p>
                </StyledCard>
              </Col>{" "}
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalDamages}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Damages
                  </p>
                </StyledCard>
              </Col>
              <Col span={2} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalRigMeters}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "#009BDF",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "white",
                      marginTop: 5,
                    }}
                  >
                    Rig Meters
                  </p>
                </StyledCard>
              </Col>
              {/* <Row style={{ margin: "auto", arginTop: "1em" }}>
                {" "}
                <ButtonRadius
                  style={{
                    margin: "auto",
                    marginTop: "2em",
                    height: 45,
                    width: "45%",
                    fontSize: 14,
                    border: "1px solid #009BDF",
                    fontFamily: "Open Sans",
                    textShadow: "none",
                    backgroundColor: "transparent",
                    color: "#009BDF",
                    width: "8em",
                  }}
                  type="primary"
                  onClick={this.props.onCancel}
                  size="large"
                  block
                >
                  <ArrowLeftOutlined
                    style={{
                      color: "#009BDF",
                    }}
                  />
                  Back
                </ButtonRadius>
              </Row> */}
            </Row>
          </Spin>
        </div>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Bit"
          visible={this.state.visibleBit}
          onOk={this.handleOkBit}
          onCancel={this.handleCancelBit}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormBit
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleUpdateRigBit()}
                onCancel={e =>
                  this.setState({
                    visibleBit: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Hammer"
          visible={this.state.visibleHammer}
          onOk={this.handleOkHammer}
          onCancel={this.handleCancelHammer}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormHammer
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleUpdateRigHammer()}
                onCancel={e =>
                  this.setState({
                    visibleHammer: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Rod"
          visible={this.state.visibleRod}
          onOk={this.handleOkRod}
          onCancel={this.handleCancelRod}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormRod
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleUpdateRigRod()}
                onCancel={e =>
                  this.setState({
                    visibleRod: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          title="Adapter"
          visible={this.state.visibleAdapter}
          onOk={this.handleOkAdapter}
          onCancel={this.handleCancelAdapter}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormAdapter
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleUpdateRigAdapter()}
                onCancel={e =>
                  this.setState({
                    visibleAdapter: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Refurbish"
          visible={this.state.visibleRefurbish}
          onOk={this.handleOkRefurbish}
          onCancel={this.handleCancelRefurbish}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormRefurbish
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleUpdateRigRefurbish()}
                onCancel={e =>
                  this.setState({
                    visibleRefurbish: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          title="RigMeters"
          visible={this.state.visibleRigMeters}
          onOk={this.handleOkRigMeters}
          onCancel={this.handleCancelRigMeters}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormRigMeters
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                addStock={this.props.addStock}
                onOk={e =>
                  this.setState({
                    visibleRigMeters: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleRigMeters: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          visible={this.state.visibleDamages}
          onOk={this.handleOkDamages}
          onCancel={this.handleCancelDamages}
        >
          <Row
            style={{
              maxWidth: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
              <StockFormDamages
                stock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e => this.handleAddDamage()}
                onCancel={e =>
                  this.setState({
                    visibleDamages: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
      </Spin>
    );
  }
}
const RigForm = Form.create()(RigFormClass);
class StockFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
    };
  }
  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return this.props.editStock ? (
      <Form layout="horizontal">
        <FormItem label={<span>Rig Number</span>}>
          {this.props.form.getFieldDecorator("currentRig", {
            rules: [
              {
                required: true,
                message: "Rig Number required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.currentRig
              : "",
          })(
            <StyledInput
              maxLength={3}
              // disabled
              //type={"number"}
              style={{ width: "100%", height: 55 }}
            />
          )}
        </FormItem>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                // disabled
                value={
                  this.props.editStock ? this.props.editStock.itemCode : ""
                }
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledInput
                // disabled
                value={
                  this.props.editStock ? this.props.editStock.toolType : ""
                }
                style={{ width: "100%", height: 55 }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={24}>
              <StyledInput
                // disabled
                value={
                  this.props.editStock
                    ? this.props.editStock.itemDescription
                    : ""
                }
                style={{ width: "100%", height: 55 }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "",
                })(
                  <StyledSelect style={{ width: "100%", height: 55 }}>
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" /> Out
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: true, message: "Serial required" }],
                })(
                  <StyledInputNumber
                    min={1}
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={24}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          {/* <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Serial"
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} /> */}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  // disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {" "}
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  // disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" /> Out
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        {/*<Row style={{ marginTop: "2.5em" }}>
          <ButtonRadius
            // disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "100%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: selectedBit ? "#009BDF" : "#cccccc",
              color: "#fff",
            }}
            type='primary'
            onClick={this.handleUpdateBits}
            size='large'
            block
          >
            Update
          </ButtonRadius>
        </Row>{" "}*/}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            // disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: selectedBit ? "#009BDF" : "#cccccc",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            {this.props.okText}
          </ButtonRadius>
        </Row>
      </Form>
    ) : (
      <Form layout="horizontal">
        <FormItem label={<span>Rig Number</span>}>
          {this.props.form.getFieldDecorator("currentRig", {
            rules: [
              {
                required: true,
                message: "Rig Number required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.currentRig
              : "",
          })(
            <StyledInput maxLength={3} style={{ width: "100%", height: 55 }} />
          )}
        </FormItem>
        {/* <Col
          xs={24}
          md={12}
          style={{
            marginLeft: '-0.8em',
            marginTop: '1.5em',
            marginBottom: '0.5em'
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: 'black',
              marginLeft: 10,
              fontFamily: 'Poppins'
            }}
          >
            Add Tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: 'right',
                fontWeight: 500,
                fontSize: 15,
                color: 'rgb(255, 157, 102)',
                marginLeft: 10,
                fontFamily: 'Poppins'
              }}
            >
              <EyeOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: '1em' }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator('itemCode', {
                rules: [{ required: true, message: 'Serial required' }],
                initialValue: this.props.editStock
                  ? this.props.editStock.itemCode
                  : ''
              })(
                <StyledSelect
                  placeholder="Serial"
                  style={{ width: '100%' }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{' '}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator('toolType', {
                rules: [{ required: true, message: 'Serial required' }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : 'No Serial'
              })(
                <StyledSelect
                   disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: '100%', height: 55 }}
                  onSelect={e => this.setState({ replaceSize: e })}
                  onBlur={e => this.setState({ selectedBit })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: '-0.5em' }}>
          {' '}
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator('itemStatus', {
                rules: [{ required: true, message: 'Serial required' }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : 'No Status'
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: '100%', height: 55 }}
                  // disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={'New'}>
                    {' '}
                    <StyledBadge color="#4BED5B"  /> New
                  </Option>
                  <Option value="Used">
                    {' '}
                    <StyledBadge color="#EE4C4C"  /> Used
                  </Option>
                  <Option value="Out">
                    {' '}
                    <StyledBadge color="#FF8F28"  /> Out
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{' '}
        <Row style={{ marginTop: '-0.5em' }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator('itemDescription', {
                rules: [{ required: true, message: 'Serial required' }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : 'No Description'
              })(
                <StyledSelect
                   disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: '100%', height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row> */}
        {/*<Row style={{ marginTop: "2.5em" }}>
          <ButtonRadius
            // disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "100%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: selectedBit ? "#009BDF" : "#cccccc",
              color: "#fff",
            }}
            type='primary'
            onClick={this.handleUpdateBits}
            size='large'
            block
          >
            Update
          </ButtonRadius>
        </Row>{" "}*/}
        <Row type="flex" justify="space-around" align="middle">
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            // disabled={false}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            {this.props.okText}
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}

const StockForm = Form.create()(StockFormClass);
class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });

    this.props
      .findAllBits(this.props.user.access)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            this.props
              .findAllSites()
              .then(() => {
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ loading: false, itemCodes: itemCodes });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllBits(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.addEventListener("backbutton", this.handleBack);
    //}
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.removeEventListener("backbutton", this.handleBack);
    //}
  };
  onRigUpdate = e => {
    this.props.updateRig1();
  };
  onAddStock = e => {
    this.setState({
      addStockVisible: true,
      editStock: null,
    });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e, visible: true });
  };
  handleAddStock = e => {
    const { selectedBit } = this.state;
    //this.props.updateBits({
    //  // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
    //  // TODO removedId: jy moet die selected bit se id hier sit,
    //  removedMeters: this.state.removedMeters || selectedBit.meter,
    //  removedStatus: selectedBit.status,
    //  replaceId: selectedBit.serial,
    //  replaceSize: this.state.replaceSize || selectedBit.size,
    //  replaceStatus: this.state.newBitStatus || selectedBit.status,
    //  replaceLastUser: this.props.user.name,
    //  replaceSerial: selectedBit.serial,
    //  replaceCurrentRig: this.props.currentRig,
    //});
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .findAllBits(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.props.user.access)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: null,
                  visible: false,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };

  handleUpdateBits = () => {
    const { selectedBit } = this.state;

    this.props
      .updateBits({
        // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
        // TODO removedId: jy moet die selected bit se id hier sit,
        removedMeters: this.state.removedMeters || selectedBit.meter,
        removedStatus: selectedBit.status,
        replaceId: selectedBit.serial,
        replaceSize: this.state.replaceSize || selectedBit.size,
        replaceStatus: this.state.newBitStatus || selectedBit.status,
        replaceLastUser: this.props.user.name,
        replaceSerial: selectedBit.serial,
        replaceCurrentRig: this.props.currentRig,
      })

      .then(suc => {
        this.setState({ loading: false });
        // TODO wys success message en nav uit view uit
        this.props.push("/user/pexunits");
        //console.log(suc);
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <Row
            type="flex"
            //justify='start'
            //align='middle'
            style={{
              margin: "-20px",
              marginBottom: "0.5em",
              background: "rgb(0, 58, 152)",
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              // height: "7.5em",
            }}
          >
            <Col
              xs={12}
              md={12}
              style={{
                paddingTop: "0.5em",
                background: "rgb(0, 58, 152)",
                // borderBottom: "1px solid white",
              }}
            >
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  marginLeft: 10,
                  marginBottom: -10,
                  fontFamily: "Roboto",
                }}
              >
                Welcome
              </span>
              <br />
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FF9D66",
                  marginLeft: 10,

                  fontFamily: "Roboto",
                }}
              >
                {this.props.user.name}
              </span>
            </Col>
            <Col
              xs={12}
              md={12}
              style={{
                background: "rgb(0, 58, 152)",
                // borderBottom: "1px solid white",
                paddingTop: "0.5em",
              }}
            >
              {/* <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                Drilling Month
              </span>
              <br />
              <span
                style={{
                  position: "absolute",
                  right: 0,
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FF9D66",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                1 Sep - 30 Sep
              </span> */}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  float: "right",
                  marginRight: 10,
                  fontFamily: "Roboto",
                }}
              >
                {this.props.sites
                  ? this.props.sites.find(
                    site => site._id === this.props.user.access
                  )
                    ? this.props.sites.find(
                      site => site._id === this.props.user.access
                    ).name
                    : "Unknown"
                  : "Unknown"}
              </span>
              <br />
              <span
                style={{
                  position: "absolute",
                  right: 0,
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FF9D66",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                {moment().format("DD/MM/YY")}
              </span>
            </Col>
            {/* <Col
              xs={12}
              md={12}
              style={{
                background: "rgb(0, 58, 152)",
                paddingTop: "0.5em",
              }}
            >
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  marginLeft: 10,
                  marginBottom: -10,
                  fontFamily: "Roboto",
                }}
              >
                {this.props.sites
                  ? this.props.sites.find(
                      site => site._id === this.props.user.access
                    )
                    ? this.props.sites.find(
                        site => site._id === this.props.user.access
                      ).name
                    : "Unknown"
                  : "Unknown"}
              </span> */}

            {/* <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  marginLeft: 10,
                  marginBottom: -10,
                  fontFamily: "Roboto",
                }}
              >
                Shift Meters:{" "}
                <span style={{ color: "rgb(255, 157, 102)" }}>
                  {rig.meters}
                </span>
              </span> */}
            {/* </Col> */}
            {/* <Col
              xs={12}
              md={12}
              style={{
                background: "rgb(0, 58, 152)",
                paddingTop: "0.5em",
              }}
            >
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                {moment().format("DD/MM/YY")}
              </span> */}
            {/* <br />
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                Day Shift
              </span> */}
            {/* </Col> */}
          </Row>
          {/*<Row style={{ marginTop: "1em" }}>
            {" "}
            <Col span={11}>
              <StyledCard
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  marginBottom: 10,
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                }}
              >
                <Col span={11}>
                  <img
                    src={SharpIcon}
                    style={{
                      marginBottom: -29,
                      marginLeft: 0,
                      width: "3em",
                    }}
                  />
                </Col>
                <Link to='/user/requisition'>
                  <Col span={11}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#8C8C8C",
                        marginLeft: -10,
                      }}
                    >
                      Total Rigs
                    </span>{" "}
                    <br />
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "#343434",
                        marginTop: -8,
                        marginBottom: 0,
                        marginLeft: -10,
                      }}
                    >
                      {this.props.stock.length}
                    </p>
                  </Col>
                </Link>
              </StyledCard>
            </Col>{" "}
            <Col span={1} />
            <Col span={11}>
              <StyledCard
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                }}
              >
                <Col span={11}>
                  <img
                    src={NewIcon}
                    style={{
                      marginBottom: -29,
                      marginLeft: 0,
                      width: "3em",
                    }}
                  />
                </Col>
                <Link to='/user/requisition'>
                  <Col span={12}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#8C8C8C",
                        marginLeft: -10,
                      }}
                    >
                      Total Tools
                    </span>{" "}
                    <br />
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "#343434",
                        marginTop: -8,
                        marginBottom: 0,
                        marginLeft: -10,
                      }}
                    >
                      {this.props.bits.length}
                    </p>
                  </Col>
                </Link>
              </StyledCard>
            </Col>
          </Row>*/}
          {/*<Row style={{ marginTop: "1em" }}>
            {" "}
            <Col span={11}>
              <StyledCard
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                }}
              >
                <Col span={11}>
                  <img
                    src={DamagedIcon}
                    style={{
                      marginBottom: -29,
                      marginLeft: 0,
                      width: "3em",
                    }}
                  />
                </Col>
                <Link to='/user/requisition'>
                  <Col span={12}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#8C8C8C",
                        marginLeft: -10,
                      }}
                    >
                      Damaged
                    </span>{" "}
                    <br />
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "#343434",
                        marginTop: -8,
                        marginBottom: 0,
                        marginLeft: -10,
                      }}
                    >
                      {this.props.bits.length + 7}
                    </p>
                  </Col>
                </Link>
              </StyledCard>
            </Col>
            <Col span={1} />
            <Col span={11}>
              <StyledCard
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                }}
              >
                <Col span={11}>
                  <img
                    src={LostIcon}
                    style={{
                      marginBottom: -29,
                      marginLeft: 0,
                      width: "3em",
                    }}
                  />
                </Col>
                <Link to='/user/requisition'>
                  <Col span={12}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#8C8C8C",
                        marginLeft: -10,
                      }}
                    >
                      Lost Tools
                    </span>{" "}
                    <br />
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "#343434",
                        marginTop: -8,
                        marginBottom: 0,
                        marginLeft: -10,
                      }}
                    >
                      {this.props.bits.length + 9}
                    </p>
                  </Col>
                </Link>
              </StyledCard>
            </Col>
          </Row>{" "}*/}
          {this.state.visible ? (
            <Row
              style={{
                maxWidth: "100vw",
                //display: this.state.addStockVisible ? "flex" : "none",
              }}
            >
              <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
                <RigForm
                  findAllStock={this.props.findAllStock}
                  findAllBits={this.props.findAllBits}
                  addBits={this.props.addBits}
                  addStock={this.props.addStock}
                  editStock={this.state.editStock}
                  bits={this.props.bits}
                  wrappedComponentRef={form => (this.rigForm = form)}
                  onOk={this.handleAddStock}
                  onCancel={e =>
                    this.setState({
                      addStockVisible: false,
                      visible: false,
                      selectedBit: null,
                    })
                  }
                  user={this.props.user}
                  okText={this.state.editStock ? "Save" : "Add Rig"}
                />
              </Col>
            </Row>
          ) : (
            <div>
              <Spin spinning={this.state.loading}>
                <StyledModal
                  style={{
                    top: 0,
                  }}
                  title={this.state.editStock ? "Edit Rig" : "Add New Rig"}
                  visible={this.state.addStockVisible}
                  onOk={this.handleOk}
                  onCancel={e =>
                    this.setState({
                      addStockVisible: false,
                      visible: false,
                      selectedBit: null,
                    })
                  }
                >
                  <Row
                    style={{
                      maxWidth: "100vw",
                      //display: this.state.addStockVisible ? "flex" : "none",
                    }}
                  >
                    <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
                      <StockForm
                        findAllStock={this.props.findAllStock}
                        findAllBits={this.props.findAllBits}
                        addBits={this.props.addBits}
                        addStock={this.props.addStock}
                        editStock={this.state.editStock}
                        bits={this.props.bits}
                        wrappedComponentRef={form => (this.stockForm = form)}
                        onOk={this.handleAddStock}
                        onCancel={e =>
                          this.setState({
                            addStockVisible: false,
                            visible: false,
                            selectedBit: null,
                          })
                        }
                        okText={this.state.editStock ? "Save" : "Add Rig"}
                      />
                    </Col>
                  </Row>
                </StyledModal>
                {/* <StyledModalRig
              style={{
                top: 0,
                overflow: "hidden",
                minHeight: "100vh",
                minmaxWidth: "100vw",
              }}
              title={this.state.editStock ? "Edit Rig" : "Add New Rig"}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={e =>
                this.setState({
                  addStockVisible: false,
                  visible: false,
                  selectedBit: null,
                })
              }
            >
              {this.state.visible ?
              <Row
                style={{
                  maxWidth: "100vw",
                  //display: this.state.addStockVisible ? "flex" : "none",
                }}
              >
                <Col span={20} style={{ margin: "auto", minWidth: "20em" }}>
                  <RigForm
                    findAllStock={this.props.findAllStock}
                    findAllBits={this.props.findAllBits}
                    addBits={this.props.addBits}
                    addStock={this.props.addStock}
                    editStock={this.state.editStock}
                    bits={this.props.bits}
                    wrappedComponentRef={form => (this.rigForm = form)}
                    onOk={this.handleAddStock}
                    onCancel={e =>
                      this.setState({
                        addStockVisible: false,
                        visible: false,
                        selectedBit: null,
                      })
                    }
                    user={this.props.user}
                    okText={this.state.editStock ? "Save" : "Add Rig"}
                  />
                </Col>
              </Row>
              :null}
            </StyledModalRig> */}
                {/* ) : this.state.addBatchVisible ? ( */}

                {/* ) : ( */}
                <Row
                  style={{
                    marginTop: 5,
                    textAlign: "center",
                    //display:
                    //  !this.state.addStockVisible && !this.state.addBatchVisible
                    //    ? "flex"
                    //    : "none",
                  }}
                >
                  {/* <Col
                span={11}
                style={{ margin: 'auto', marginTop: '0.5em', marginLeft: 0 }}
                onClick={this.onAddStock}
              >
                <StyledCard
                  style={{
                    border: '1px solid #7D7D7D',
                    borderRadius: '1em',
                    padding: '0em',
                    whiteSpace: 'nowrap',
                    fontFamily: 'Poppins',
                    height: '4.5em'
                  }}
                >
                  <Col span={8}>
                    <div
                      style={{
                        backgroundColor: '#7D7D7D',
                        height: '4.5em',
                        margin: 'auto',
                        textAlign: 'center',
                        borderRadius: '0.7em 0em 0em 0.7em',
                        marginLeft: -10,
                        marginTop: -10,
                        paddingTop: 15
                      }}
                    >
                      <EyeOutlined
                        style={{
                          fontWeight: 500,
                          fontSize: 25,
                          color: 'white',
                          marginTop: 4,
                          marginBottom: -0,
                          marginLeft: 0
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={4} />
                  <Col span={12}>
                    <p
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                        color: '#7D7D7D',
                        marginTop: 6,
                        marginBottom: 0,
                        marginLeft: -15
                      }}
                    >
                      Add Rig
                    </p>
                  </Col>
                </StyledCard>
              </Col> */}
                  <Col
                    span={24}
                    style={{
                      margin: "auto",
                      marginTop: "0.5em",
                      marginLeft: 0,
                    }}
                  >
                    {" "}
                    <Link to="/user/dashboard">
                      <StyledCard
                        style={{
                          border: "1px solid #009BDF",
                          borderRadius: "1em",
                          padding: "0em",
                          whiteSpace: "nowrap",
                          backgroundColor: "#009BDF",
                          fontFamily: "Poppins",
                          height: "4.5em",
                        }}
                      >
                        <Col span={24}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 20,
                              color: "white",
                              textAlign: "center",
                              marginTop: 5,
                              marginBottom: 0,
                            }}
                          >
                            <EyeOutlined style={{ marginRight: "0.5em" }} />{" "}
                            View Tools
                          </p>
                        </Col>

                        {/*<StyledBadge
                    color="#EE4C4C"
                    style={{ position: "absolute", right: 0, top: 0 }}
                  />*/}
                      </StyledCard>{" "}
                    </Link>
                  </Col>
                  <Col
                    span={24}
                    style={{ textAlign: "center", marginTop: "1em" }}
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        color: "black",
                        fontFamily: "Poppins",
                      }}
                    >
                      Please Select Rig
                    </span>
                  </Col>
                  {/*<p>
                {stock.itemCode}{" "}
                <a onClick={(e) => this.onEditStock(stock)}>Edit</a>
                <Divider type='vertical' />
                <a
                  onClick={(e) => {
                    this.setState({
                      deleteVisible: true,
                      deleteStock: stock,
                    });
                  }}
                >
                  Delete
                </a>
              </p>*/}{" "}
                  {this.props.stock.map(stock => (
                    <Col
                      span={5}
                      style={{
                        margin: "auto",
                        marginRight: "4%",
                        marginTop: "1em",
                        marginLeft: 0,
                      }}
                    >
                      <CloseCircleOutlined
                        style={{
                          display: "none",
                          fontSize: "20px",
                          /* float: right; */
                          position: "absolute",
                          right: "-5px",
                          top: "-3px",
                          background: "white",
                          color: "grey",
                          borderRadius: "3em",
                          zIndex: 1,
                        }}
                      // onClick={e => {
                      //   this.setState({
                      //     deleteVisible: true,
                      //     deleteStock: stock
                      //   });
                      // }}
                      />
                      {/* <Link to="/user/myprofile"> */}
                      <StyledCard
                        onClick={e => this.onEditStock(stock)}
                        style={{
                          border: "1px solid #009BDF",
                          borderRadius: "1em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "0em",
                          whiteSpace: "nowrap",
                          fontFamily: "Poppins",
                          height: "4.5em",
                        }}
                      >
                        <Col span={24}>
                          <div
                            style={{
                              backgroundColor: "#009BDF",
                              height: "4.5em",
                              margin: "auto",
                              textAlign: "center",
                              borderRadius: "0.7em 0em 0em 0.7em",
                              marginLeft: -10,
                              marginRight: -10,
                              marginTop: -10,
                              paddingTop: 15,
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 500,
                                fontSize: 25,
                                color: "white",
                                marginTop: -2,
                                marginBottom: -0,
                                marginLeft: 0,
                              }}
                            >
                              {stock.currentRig}
                            </p>
                          </div>
                        </Col>
                      </StyledCard>

                      {/* </Link> */}
                    </Col>
                  ))}
                </Row>
              </Spin>
            </div>
          )}
          <Modal
            title={"Delete Rig"}
            visible={this.state.deleteVisible}
            onOk={this.handleDeleteStock}
            onCancel={this.cancelDelete}
            okText="Accept"
            cancelText="Cancel"
          >
            <span>
              Are you sure you want to delete rig{" "}
              {this.props.stock.map(stock => (
                <span>{stock.currentRig}</span>
              ))}
              ?
            </span>
          </Modal>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.status,
});

const mapDispatchToProps = {
  updateRig1,
  updateRig2,
  updateRig3,
  updateRig4,
  updateRig5,
  updateRig6,
  updateRig7,
  updateRig8,
  updateRig9,
  updateRig10,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stock);
