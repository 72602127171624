import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { sendNotification } from "src/redux/actions/notifications";
import { addSites, deleteSites, findAllSites } from "src/redux/actions/sites";

import { withRouter, Link } from "react-router-dom";

class Sites extends Component {
  render = () => {
    const {
      Layout,
      sites,
      user,
      findAllSites,
      addSites,
      deleteSites,
      sendNotification,
      push,
      history,
      findAllSitesUpdatedToday,
    } = this.props;
    return (
      <Layout
        sites={sites}
        user={user}
        addSites={addSites}
        findAllSites={findAllSites}
        deleteSites={deleteSites}
        sendNotification={sendNotification}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  sites: state.sites.allSites || [],
  user: state.user.user || {},
});

const mapDispatchToProps = {
  addSites,
  deleteSites,
  findAllSites,
  sendNotification,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sites)
);
