import React from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  InputNumber,
} from "antd";
import {
  BellOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import Notify from "src/components/meta/Notification";
import Highlighter from "react-highlight-words";
const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;

const { Option } = Select;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-selector {
    width: 100%;

    padding-top: 0px !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-top: 0em !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    margin-top: 10px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
    padding-top: 10px !important;
  }
`;
function callback(key) {
  console.log(key);
}
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

function handleChange(value) {
  console.log(`selected ${value}`);
}
class SitesFormClass extends React.Component {
  state = {
    descriptions: this.props.descriptions,
  };
  selectItem = value => {
    if (value.substr(0, 4) === "Add ") {
      let parsedValue = value.substr(4, value.length);
      this.props.form.setFieldsValue({ description: parsedValue });
      let descriptions = this.state.descriptions;
      descriptions.pop();
      descriptions.push({ value: parsedValue });
      this.setState({ descriptions: descriptions });
    }
  };
  searchItem = (value, b) => {
    let descriptions = this.state.descriptions;
    if (descriptions[descriptions.length - 1].value.substr(0, 4) === "Add ") {
      descriptions.pop();
    }
    if (
      !descriptions.find(
        descriptions => descriptions.value.toLowerCase() === value.toLowerCase()
      ) &&
      value !== ""
    ) {
      descriptions.push({ value: "Add " + value });
    }
    this.setState({ descriptions: descriptions });
  };
  render() {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={11}>
            <FormItem label={<span>Site Name</span>}>
              {this.props.form.getFieldDecorator("name", {
                rules: [{ required: true, message: "Site name is required" }],
                initialValue: this.props.editSites
                  ? this.props.editSites.name
                  : "",
              })(<Input placeholder={"Please enter site name"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={2} />
          <Col span={11}>
            <FormItem label={<span>Address</span>}>
              {this.props.form.getFieldDecorator("address", {
                rules: [{ required: true, message: "Address is required" }],
                initialValue: this.props.editSites
                  ? this.props.editSites.address
                  : "",
              })(<Input placeholder={"Please enter address"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={11}>
            <FormItem label={<span>Contact Name</span>}>
              {this.props.form.getFieldDecorator("contactName", {
                rules: [{ required: true, message: "Contact is required" }],
                initialValue: this.props.editSites
                  ? this.props.editSites.contactName
                  : "",
              })(<Input placeholder={"Please enter Contact name"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={2} />
          <Col span={11}>
            <FormItem label={<span>Number</span>}>
              {this.props.form.getFieldDecorator("number", {
                rules: [{ required: true, message: "Number is required" }],
                initialValue: this.props.editSites
                  ? this.props.editSites.number
                  : "",
              })(<Input placeholder={"Please enter Number"} />)}
            </FormItem>
          </Col>{" "}
          <Col span={24}>
            <FormItem label={<span>Emails</span>}>
              {this.props.form.getFieldDecorator("email", {
                rules: [{ required: true, message: "Email is required" }],
                initialValue: this.props.editSites
                  ? this.props.editSites.email
                  : undefined,
              })(
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  tokenSeparators={[","]}
                >
                  {/* {children} */}
                </Select>
              )}
            </FormItem>{" "}
          </Col>
        </Row>
      </Form>
    );
  }
}
const SitesForm = Form.create()(SitesFormClass);

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editSites: undefined,
      size: "large",
      flag: false,
      currentTab: "All",
    };
  }

  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllSites()
      .then(() => {
        this.setState({ loading: false, currentTab: "All" });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addSitesVisible) {
      this.setState({ addSitesVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  sitesForm;
  onAddSites = e => {
    this.setState({ addSitesVisible: true, editSites: undefined });
  };

  handleAddSites = e => {
    e.preventDefault();
    this.sitesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editSites) {
          values._id = this.state.editSites._id;
        }
        this.props
          .addSites(values)
          .then(() => {
            this.props
              .findAllSites()
              .then(() => {
                if (this.state.editSites) {
                  Notify("success", "Successfully saved site");
                } else {
                  Notify("success", "Successfully added site");
                }
                this.sitesForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addSitesVisible: false,
                  editSites: undefined,
                  currentTab: "All",
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleEditSites = e => {
    this.setState({ addSitesVisible: true, editSites: e });
  };

  handleDeleteSites = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this site?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteSites(id)
          .then(() => {
            that.props
              .findAllSites()
              .then(() => {
                that.setState({ loading: false, currentTab: "All" });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify("success", "Successfully deleted site");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };

  render() {
    let descriptions = this.props.sites.map(site => {
      return { value: site.description };
    });
    const { size } = this.state;
    const { sites } = this.props;
    let currentSites;
    if (this.state.currentTab === "All") {
      currentSites = sites;
    } else {
      currentSites = sites.filter(site => site.size === this.state.currentTab);
    }
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Spin spinning={this.state.loading}>
          {this.state.addSitesVisible ? (
            <Card
              bordered={false}
              extra={
                <Title
                  style={{
                    paddingRight: "75em",
                    fontSize: "20px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.editSites ? "Edit Site" : "Add Site"}
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      marginTop: "0.5em",
                      marginBottom: "0em",
                    }}
                  >
                    {this.state.editSites
                      ? "Please click save to edit site"
                      : "Fill in all fields and click save to add a new site"}
                  </p>
                </Title>
              }
              style={{
                margin: "auto",
                marginTop: "3em",
                width: "50em",
                backgroundColor: "white",
                borderRadius: "0.8em",
                boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div
                style={{
                  padding: "3em",
                  paddingTop: "1em",
                  paddingBottom: "4em",
                  margin: "auto",
                }}
              >
                <SitesForm
                  descriptions={descriptions}
                  editSites={this.state.editSites}
                  wrappedComponentRef={form => (this.sitesForm = form)}
                />{" "}
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleAddSites}
                  size="large"
                  style={{
                    float: "right",
                    fontSize: "14px",
                    width: "7em",
                    height: "3em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  Save
                </Button>
                <Button
                  shape="round"
                  onClick={e => this.setState({ addSitesVisible: false })}
                  style={{
                    fontSize: "14px",
                    width: "7em",
                    height: "3em",
                    float: "right",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "#EEEFF0",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Card>
          ) : (
            <Row>
              {" "}
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.onAddSites}
                  icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                  size="large"
                  style={{
                    marginBottom: "0.5em",
                    height: "3em",
                    width: "9em",
                  }}
                >
                  Add Site
                </Button>

                <StyledTable
                  bordered={false}
                  dataSource={currentSites}
                  rowKey="_id"
                  size="middle"
                  pagination={
                    false
                    //defaultPageSize: 25,
                  }
                  style={{
                    minWidth: "15em",
                    marginTop: "0.5em",
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps("name")}
                    title="Site Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Address"
                    dataIndex="address"
                    key="address"
                  />
                  <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Contact Name"
                    dataIndex="contactName"
                    key="contactName"
                  />
                  <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Number"
                    dataIndex="number"
                    key="number"
                  />
                  {/* <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Email"
                    dataIndex="email"
                    key="email"
                  /> */}
                  <Column
                    //{...this.getColumnSearchProps("actions")}
                    title="Actions"
                    width={150}
                    render={(text, sites) => (
                      <span>
                        <Button
                          style={{
                            backgroundColor: "#009BDF",
                            color: "white",
                            borderRadius: "3em",
                          }}
                          onClick={e => this.handleEditSites(sites)}
                        >
                          Edit
                        </Button>
                        <Divider type="vertical" />
                        <DeleteFilled
                          style={{
                            backgroundColor: "#CFD5E3",
                            color: "white",
                            padding: "0.5em",
                            borderRadius: "3em",
                          }}
                          data={sites._id}
                          onClick={this.handleDeleteSites}
                        />
                      </span>
                    )}
                  />
                </StyledTable>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedSites = Form.create()(Sites);
export default WrappedSites;
