import React from "react";
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox,
} from "antd";
import Highlighter from "react-highlight-words";
import Notify from "src/components/meta/Notification";
import styled from "styled-components";
import { regExpLiteral } from "@babel/types";
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;
const numeral = require("numeral");
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
class UserFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <FormItem label={<span>Full Name</span>}>
              {this.props.form.getFieldDecorator("name", {
                rules: [
                  { required: true, message: "User full name required" },
                  {
                    pattern: RegExp(
                      /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                    ),
                    message: "A name and surname is required",
                  },
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.name
                  : "",
              })(<Input />)}
            </FormItem>
          </Col>{" "}
          <Col span={1} />
          <Col span={24}>
            <FormItem label={<span>Number</span>}>
              {this.props.form.getFieldDecorator("number", {
                rules: [
                  { required: true, message: "User number required" },
                  {
                    pattern: RegExp(/\d/g),
                    message: "A valid number is required",
                  },
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.number
                  : "",
              })(<Input maxLength={10} />)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label={<span>Email</span>}>
              {this.props.form.getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Email address is required.",
                  },
                  {
                    type: "email",
                    message: "A valid email is required.",
                  },
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.email
                  : "",
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={1} />
          {/* <Col span={11}>
            <FormItem label={<span>Site Access</span>}>
              {this.props.form.getFieldDecorator("access", {
                rules: [
                  {
                    required: false,
                    message: "Access required",
                  },
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.access
                  : [],
              })(
                // <Select mode="multiple">
                <Select mode="multiple">
                  {this.props.sites.map(site => (
                    <Option key={site._id} value={site._id}>
                      {site.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col> */}
        </Row>
      </Form>
    );
  }
}
const UserForm = Form.create()(UserFormClass);
class ManagerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Manager full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.name
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: true, message: "Manager number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Email address is required.",
              },
              {
                type: "email",
                message: "A valid email is required.",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.email
              : "",
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}
const ManagerForm = Form.create()(ManagerFormClass);
class BuyerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editBuyer ? this.props.editBuyer.name : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: true, message: "Manager number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Email address is required.",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.email
              : "",
          })(<Input />)}
        </FormItem>

        <FormItem label={<span>Site Access</span>}>
          {this.props.form.getFieldDecorator("access", {
            rules: [
              {
                required: false,
                message: "Access required",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.access
              : [],
          })(
            // <Select mode="multiple">
            <Select
              mode="multiple"

            >
              {this.props.sites.map(site => (
                <Option key={site._id} value={site._id}>
                  {site.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>

        {/* <FormItem label={<span>Dealer Name</span>}>
          {this.props.form.getFieldDecorator('dealerName', {
            // normalize: (input) => input.toUpperCase(),
            rules: [
              {
                required: false,
                message: 'Condition is required'
              }
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.dealerName
              : ''
          })(
            <Select showSearch>

              <Option key={'KIA'} value={'KIA'}>
                KIA
              </Option>

            </Select>
          )}
        </FormItem> */}
      </Form>
    );
  }
}
const BuyerForm = Form.create()(BuyerFormClass);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editDealer: undefined,
      editManager: undefined,
      editUser: undefined,
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllUsers()
      .then(() => {
        this.props
          .findAllManagers()
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                this.props
                  .findAllSites()
                  .then(() => {
                    this.setState({ loading: false });
                  })
                  .catch(e => {
                    this.setState({ loading: false });
                    Notify("error", e);
                  });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/userdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === "ascend") {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  userForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddUser = e => {
    e.preventDefault();
    this.userForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editUser) {
          values._id = this.state.editUser._id;
        } else {
          values.type = "user";
          values.password = "pass";
          values.firstLogin = true;
        }
        values.manager = [values.manager];
        this.props
          .addUser(values)
          .then(() => {
            this.props
              .findAllUsers()
              .then(() => {
                if (this.state.editUser) {
                  Notify("success", "Successfully saved user");
                } else {
                  Notify("success", "Successfully added user");
                }
                this.userForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addUserVisible: false,
                  editUser: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditUser = e => {
    this.setState({ addUserVisible: true, editUser: e });
  };
  handleDeleteUser = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this user?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllUsers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted user");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  managerForm;
  onAddManager = e => {
    this.setState({ addManagerVisible: true, editManager: undefined });
  };
  handleAddManager = e => {
    e.preventDefault();
    this.managerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editManager) {
          values._id = this.state.editManager._id;
        } else {
          values.type = "manager";
          values.password = "pass";
          values.firstLogin = true;
        }
        this.props
          .addManager(values)
          .then(() => {
            this.props
              .findAllManagers()
              .then(() => {
                if (this.state.editManager) {
                  Notify("success", "Successfully saved manager");
                } else {
                  Notify("success", "Successfully added manager");
                }
                this.managerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addManagerVisible: false,
                  editManager: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditManager = e => {
    this.setState({ addManagerVisible: true, editManager: e });
  };
  handleDeleteManager = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this manager?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllManagers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted manager");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  buyerForm;
  onAddBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: undefined });
  };
  handleAddBuyer = e => {
    e.preventDefault();
    this.buyerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBuyer) {
          values._id = this.state.editBuyer._id;
        } else {
          values.type = "buyer";
          values.password = "pass";
          values.firstLogin = true;
        }
        this.props
          .addBuyer(values)
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                if (this.state.editBuyer) {
                  Notify("success", "Successfully saved client");
                } else {
                  Notify("success", "Successfully added client");
                }
                this.buyerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addBuyerVisible: false,
                  editBuyer: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: e });
  };
  handleDeleteBuyer = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this client?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllBuyers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted client");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  render() {
    const { user } = this.props;
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Spin spinning={this.state.loading}>
          <Tabs
            style={{ overflow: "visible" }}
            tabBarExtraContent={{
              left: (
                <span
                  style={{
                    fontWeight: 750,
                    fontSize: 25,
                    marginRight: "1em",
                  }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.onAddUser}
                    icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                    size="large"
                    style={{
                      marginRight: "1em",
                      height: "3em",
                      width: "9em",
                    }}
                  >
                    Add User
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.onAddBuyer}
                    icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                    size="large"
                    style={{
                      marginRight: "1em",
                      height: "3em",
                      width: "9em",
                    }}
                  >
                    Add Clients
                  </Button>
                </span>
              ),
            }}
          >
            <TabPane tab="Users" key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    expandedRowRender={
                      window.innerWidth <= 420
                        ? (record, index, indent, expanded) => (
                          <Card
                            size="small"
                            title={record.name}
                            style={{ width: 300, marginLeft: -35 }}
                          >
                            <p>Number: {record.number}</p>
                            <p>Email: {record.email}</p>
                          </Card>
                        )
                        : null
                    }
                    dataSource={this.props.users}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title="Username"
                      dataIndex="username"
                      key="username"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("name")}
                        title="Full Name"
                        dataIndex="name"
                        key="name"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("number")}
                        title="Number"
                        dataIndex="number"
                        key="number"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("email")}
                        title="Email"
                        dataIndex="email"
                        key="email"
                      />
                    ) : null}
                    {/* {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("access")}
                        title="Site Access"
                        dataIndex="access"
                        key="access"
                        render={(text, record) => {
                          return (
                            <span>
                              {this.props.sites.find(
                                sSite => sSite._id === text
                              )
                                ? this.props.sites.find(
                                    sSite => sSite._id === text
                                  ).name
                                : "Unknown site"}
                            </span>
                          );
                        }}
                      />
                    ) : null} */}
                    {/*{window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("brand")}
                        title='Brand'
                        dataIndex='brand'
                        key='brand'
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("branch")}
                        title='Branch'
                        dataIndex='branch'
                        key='branch'
                      />
                    ) : null}*/}

                    {/*{window.innerWidth > 420 ? (
                      <Column
                        width={70}
                        title='Batch Request'
                        render={(text, request) => (
                          <span>{request.isBatch ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='Submit Stock Out'
                        render={(text, request) => (
                          <span>{request.isStockOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Add PEX'
                        render={(text, request) => (
                          <span>{request.isPexAdd ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Edit PEX'
                        render={(text, request) => (
                          <span>{request.isPexEdit ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Del PEX'
                        render={(text, request) => (
                          <span>{request.isPexDelete ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='PEX Unit Out'
                        render={(text, request) => (
                          <span>{request.isPexOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}*/}
                    <Column
                      title="Actions"
                      render={(text, user) => (
                        <span>
                          <Button
                            type="primary"
                            style={{
                              color: "white",
                              borderRadius: "3em",
                            }}
                            onClick={e => this.handleEditUser(user)}
                          >
                            Edit
                          </Button>
                          <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            data={user._id}
                            onClick={this.handleDeleteUser}
                          />
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            {/* <TabPane tab='Clients' key='2'>
              <Row type='flex' justify='start' align='middle'>
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey='_id'
                    size='middle'
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    dataSource={this.props.managers}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title="Username"
                      dataIndex="username"
                      key="username"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("name")}
                        title="Full Name"
                        dataIndex="name"
                        key="name"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("number")}
                        title="Number"
                        dataIndex="number"
                        key="number"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("email")}
                        title="Email"
                        dataIndex="email"
                        key="email"
                      />
                    ) : null}

                    <Column
                      title='Actions'
                      render={(text, manager) => (
                        <span>
                          <a onClick={(e) => this.handleEditManager(manager)}>
                            Edit
                          </a>
                          <Divider type='vertical' />
                          <a
                            data={manager._id}
                            onClick={this.handleDeleteManager}
                          >
                            Delete
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane> */}
            <TabPane tab='Clients' key='2'>
              <Row type='flex' justify='start' align='middle'>
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey='_id'
                    size='middle'
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    dataSource={this.props.buyers}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title="Username"
                      dataIndex="username"
                      key="username"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("name")}
                        title="Full Name"
                        dataIndex="name"
                        key="name"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("number")}
                        title="Number"
                        dataIndex="number"
                        key="number"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("email")}
                        title="Email"
                        dataIndex="email"
                        key="email"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("access")}
                        title="Site Access"
                        dataIndex="access"
                        key="access"
                        render={(text, record) => {
                          const accessId = Array.isArray(text) ? text[0] : text; // Accessing the string element if text is an array
                          const matchingSite = this.props.sites.find(site => site._id === accessId);
                          return (
                            <span>
                              {matchingSite ? matchingSite.name : "Unknown site"}
                            </span>
                          );
                        }}
                      // render={(text, record) => {
                      //   return (
                      //     <span>
                      //       {this.props.sites.find(
                      //         sSite => sSite._id === text
                      //       )
                      //         ? this.props.sites.find(
                      //           sSite => sSite._id === text
                      //         ).name
                      //         : "Unknown site"}
                      //     </span>
                      //   );
                      // }}
                      />
                    ) : null}
                    <Column
                      title='Actions'
                      render={(text, buyer) => (
                        <span>
                          <Button
                            type="primary"
                            style={{
                              color: "white",
                              borderRadius: "3em",
                            }}
                            onClick={e => this.handleEditBuyer(buyer)}
                          >
                            Edit
                          </Button>
                          <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            data={buyer._id}
                            onClick={this.handleDeleteBuyer}
                          />
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
        {this.state.addUserVisible ? (
          <Modal
            title="Add User"
            visible={this.state.addUserVisible}
            onOk={this.handleAddUser}
            onCancel={e => this.setState({ addUserVisible: false })}
            okText={this.state.editUser ? "Save" : "Add User"}
            cancelText="Cancel"
          >
            <UserForm
              managers={this.props.managers}
              editUser={this.state.editUser}
              sites={this.props.sites}
              wrappedComponentRef={form => (this.userForm = form)}
            />
          </Modal>
        ) : null}
        <Modal
          title="Add Manager"
          visible={this.state.addManagerVisible}
          onOk={this.handleAddManager}
          onCancel={e => this.setState({ addManagerVisible: false })}
          okText={this.state.editManager ? "Save" : "Add Manager"}
          cancelText="Cancel"
        >
          <ManagerForm
            editManager={this.state.editManager}
            wrappedComponentRef={form => (this.managerForm = form)}
          />
        </Modal>
        <Modal
          title="Add Client"
          visible={this.state.addBuyerVisible}
          onOk={this.handleAddBuyer}
          onCancel={e => this.setState({ addBuyerVisible: false })}
          okText={this.state.editBuyer ? "Save" : "Add Client"}
          cancelText="Cancel"
        >
          <BuyerForm
            editBuyer={this.state.editBuyer}
            sites={this.props.sites}
            wrappedComponentRef={form => (this.buyerForm = form)}
          />
        </Modal>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
