/* global window */
import React from "react";
import {
  CheckOutlined,
  StockOutlined,
  TeamOutlined,
  DashboardOutlined,
  SettingOutlined,
  PoweroffOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  ToolOutlined,
  UploadOutlined,
  TableOutlined,
  FundOutlined,
  AppstoreAddOutlined,
  DisconnectOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Col, Space, Button, Popover } from "antd";
import LogoSmall from "../../static/logo-small.svg";
import logo from "src/static/logo-white.svg";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import { getSidebarCollapsed } from "src/redux/selectors";
import { collapseSidebar } from "src/redux/actions/status";
import { MenuItem } from "rc-menu";
import { GiScrew } from "@react-icons/all-files/gi/GiScrew";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaUserFriends } from "@react-icons/all-files/fa/FaUserFriends";
import { FaCopy } from "@react-icons/all-files/fa/FaCopy";
import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
const StyledLink = styled(Link)`
  color: #fff !important;
  font-size: 15px;
  :hover {
    text-decoration: none !important;
  }
  .anticon svg {
    display: inline-block;
    font-size: 20px !important;
    color: white !important;
  }
`;

const { Sider } = Layout;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: "gcm", // apns, gcm, mpns
            },
            function (status) {
              if (status.error) {
                console.log("operation failed w/ error:", status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("basic");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      collapsedWidth="90"
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      width={210}
      style={{
        //height: "calc(100vh - 50px)",
        //marginTop: "50px",
        // border: '1px solid #009BDF3b',
        //zIndex: 9,
        //position: 'fixed',
        // background: " rgb(0, 21, 41)",
        textAlign: "center",
        //height: 'calc(100vh - 40px)',
        //left: 0,
        //top: 40
      }}
    >
      <img
        onClick={props.collapseSidebar}
        src={props.collapsed ? LogoSmall : logo}
        alt="Logo"
        style={{
          width: "90%",
          margin: "auto",
          marginTop: 30,
        }}
      />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["dashboard"]}
        style={{
          background: "transparent",
          marginTop: "3vh",
          position: "sticky",
          top: 0,
        }}
      >
        {/* <Menu.Item
          key="dashboard"
          icon={
            <FaDashcube
              style={{
                fontSize: "20px",
                color: "white",
                marginTop: props.collapsed ? "0.5em" : 0,
              }}
            />
          }
        >
          <StyledLink to="/buyer/dashboard">
            <span>Dashboard</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          key="usermanagement"
          icon={
            <FaUserFriends
              style={{
                fontSize: "20px",
                color: "white",
                marginTop: props.collapsed ? "0.5em" : 0,
              }}
            />
          }
        >
          <StyledLink to="/buyer/users">
            <span>User Management</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          icon={
            <FaBuilding
              style={{
                fontSize: "20px",
                color: "white",
                marginTop: props.collapsed ? "0.5em" : 0,
              }}
            />
          }
          key="sitemanagement"
        >
          <StyledLink to="/buyer/sitemanagement">
            <span>Site Management</span>
          </StyledLink>
        </Menu.Item> */}
        <Menu.Item
          icon={
            <FaCopy
              style={{
                fontSize: "20px",
                color: "white",
                marginTop: props.collapsed ? "0.5em" : 0,
              }}
            />
          }
          key="rigmanagement"
        >
          <StyledLink to="/buyer/stock">
            <span>Employee Management</span>
          </StyledLink>
        </Menu.Item>

        {/* <Popover
          trigger="click"
          style={{ position: "absolute" }}
          placement="topRight"
          content={
            <div style={{ width: "17em", padding: "0.5em" }}>
              <Row justify="space-between">
                <Col span={8}>
                  <Button
                    type="default"
                    style={{
                      borderRadius: "5em",
                      background: "#f15b2b",
                      color: "#fff",
                      height: "3em",
                      width: "3em",
                      marginTop: "0.5em",
                      fontSize: "1.3em",
                    }}
                    onClick={null}
                  >
                    SA
                  </Button>
                </Col>
                <Col span={16}>
                  <p
                    style={{
                      color: "#878787",
                      marginBottom: "0.5em",
                    }}
                  >
                    Super Admin
                  </p>
                  <StyledLink
                    style={{ fontSize: '20px', color: 'white' }}
                    to={'/user/myprofile'}
                  >
                    <Button type="primary" onClick={'/user/myprofile'}>
                      My Profile
                    </Button>
                  </StyledLink>
                </Col>
              </Row>
              <Row justify="start">
                <Col
                  span={24}
                  style={{
                    marginTop: "1.4em",
                    marginLeft: "0.5em",
                  }}
                >
                  <a style={{ color: "#878787", fontSize: "16px" }} href="/">
                    <PoweroffOutlined onClick={props.signOut} />{" "}
                    &nbsp;&nbsp;Sign out
                  </a>
                </Col>
              </Row>
            </div>
          }
        >
          <div
            style={{
              transition: "0.3s",
              position: "absolute",
              bottom: 20,
              margin: "auto",
              textAlign: "center",
              width: props.collapsed ? 90 : 210,
              paddingLeft: "auto",
              paddingRight: "auto",
            }}
          >
            <Button
              onClick={"/user/myprofile"}
              type="default"
              style={{
                transition: "0.3s",
                borderRadius: "5em",
                fontWeight: 600,
                border: "2px solid #f15b2b",
                background: "transparent",
                color: "#f15b2b",
                height: "3em",
                width: "3em",
                marginTop: "0.5em",
                fontSize: "1.3em",
              }}
            >
              SA
            </Button>
          </div>
        </Popover> */}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
  };
};

const mapDispatchToProps = {
  collapseSidebar,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
