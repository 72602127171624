import { getToken } from "src/redux/selectors";

export function approveRequest(request) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/approverequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(request),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to approve request" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}
export function findAllRigs() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/findallrigs",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "RIGS_FIND_ALLRIGS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}
export function rejectRequest(request) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/rejectrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(request),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject request" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function approvePexRequest(request) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/approvepexrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(request),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to approve request" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectPexRequest(request) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/rejectpexrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(request),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject request" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function makeAnOffer(id, amount) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/makeanoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id, offerAmount: amount }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to make an offer" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setStockAuctionDate(stock) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/setstockauctiondate",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(stock),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to set stock auction date" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function auctionStock(ids, onAuction) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/auctionstock",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ ids: ids, onAuction: onAuction }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to auction stock" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function completeDeal(id, failed) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/completedeal",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id, failed: failed }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to complete deal" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateFollowUp(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/updatefollowup",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to update follow-up date" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateNotes(id, note) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/updatenotes",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id, note: note }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to update follow-up date" });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteStock(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/deletestock",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete stock" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function changeManager(data) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/changemanager",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(data),
        }
      )
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          } else {
            return resolve({ message: await res.text() });
          }
        })
        .catch(async error => {
          return reject(error);
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findStockDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/findstockdetails?id=" +
          id,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "ADMIN_FIND_VEHICLEDETAILS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findStockBidInfo(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/findstockbidinfo?id=" +
          id,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "ADMIN_FIND_VEHICLEBIDINFO",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/findallstock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_ALLVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealStock(type) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/finddealstock?type=" +
          type,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_DEALVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findApprovedStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/findapprovedstock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_APPROVEDVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionHistoryStock(dateFrom, dateTo) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/findauctionhistorystock?dateFrom=" +
          dateFrom +
          "&dateTo=" +
          dateTo,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_AUCTIONHISTORYVEHICLES",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setStockDetailsId(id) {
  return dispatch =>
    dispatch({
      type: "ADMIN_SET_VEHICLEDETAILSID",
      data: id,
    });
}

export function setFromHistory(boolean) {
  return dispatch =>
    dispatch({
      type: "ADMIN_SET_FROMHISTORY",
      data: boolean,
    });
}

export function findAllUsers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/findallusers",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_ALLUSERS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addUser(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/adduser", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(values),
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteUser(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/deleteuser",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllManagers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/admin/findallmanagers",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_ALLMANAGERS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addManager(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/adduser", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(values),
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllBuyers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/admin/findallbuyers",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "ADMIN_FIND_ALLBUYERS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addBuyer(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/adduser", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(values),
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}
