export const initialState = {
  bitsUpdatedToday: [],
  allBits: [],
  editBits: undefined,
  timeStamp: undefined,
  bits140: [],
  bits171: []
};

export default function bitsReducer(state = initialState, action) {
  switch (action.type) {
    case 'BITS_UPDATED_TODAY': {
      if (action.data) {
        const bitsUpdatedToday = action.data;

        return {
          ...state,
          bitsUpdatedToday
        };
      }
      return initialState;
    }
    case 'BITS_FIND_ALLBITS': {
      if (action.data) {
        const allBits = action.data.allBits;

        return {
          ...state,
          allBits: allBits
        };
      }
      return initialState;
    }
    case 'BITS_FIND_BITS140': {
      if (action.data) {
        const bits140 = action.data.bits140;

        return {
          ...state,
          bits140: bits140
        };
      }
      return initialState;
    }
    case 'BITS_FIND_BITS171': {
      if (action.data) {
        const bits171 = action.data.bits171;

        return {
          ...state,
          bits171: bits171
        };
      }
      return initialState;
    }
    case 'BITS_FIND_RIG38': {
      if (action.data) {
        const rig38 = action.data.rig38;

        return {
          ...state,
          rig38: rig38
        };
      }
      return initialState;
    }
    case 'BIT_SET_EDITBITS': {
      if (action.data) {
        const editBits = action.data;

        return {
          ...state,
          editBits: editBits
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
