import React from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  TableOutlined,
  DeleteFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Typography,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const { Text } = Typography;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const numeral = require("numeral");
const { TabPane } = Tabs;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);
const StyledDatePicker = styled(RangePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const dataSource = [
  {
    key: "1",
    serial: "Bw113",
    toolType: "Rod",
    name: "9.144m rod",
    age: 11,
    address: "R120.00",
    totals: "R1340.00",
    status: "New",
    meters: 29,
    cost: "R168.00",
  },
  {
    key: "2",
    serial: "Kg001",
    toolType: "Bit",
    name: "Bottom sub",
    age: 3,
    address: "R999.99",
    totals: "R3899.98",
    status: "Used",
    meters: 80,
    cost: "R789.00",
  },
  {
    key: "3",
    serial: "Bw099",
    toolType: "Rod",
    name: "9.144m rod”",
    age: 9,
    address: "R400.00",
    totals: "R3600.00",
    status: "Out",
    meters: 122,
    cost: "R1380.65",
  },
  {
    key: "4",
    serial: "Bw001",
    toolType: "Rod",
    name: "9.144m rod”",
    age: 3,
    address: "R80.00",
    totals: "R240.00",
    status: "None",
    meters: 3,
    cost: "R293.10",
  },
  {
    key: "5",
    serial: "32244",
    toolType: "Bit",
    name: "171mmbit",
    age: 10,
    address: "R120.00",
    totals: "R1200.00",
    status: "New",
    meters: 99,
    cost: "R673.50",
  },
];
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const EditableContext = React.createContext();
const columns = [
  {
    title: "Stock Nr.",
    dataIndex: "serial",
    key: "serial",
    width: "90%",
  },
  {
    title: "Tool Type",
    dataIndex: "toolType",
    key: "toolType",
    width: "90%",
  },
  {
    title: "Description",
    dataIndex: "name",
    key: "name",
    width: "90%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "90%",
  },
  {
    title: "Meters",
    dataIndex: "meters",
    key: "meters",
    width: "90%",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
    width: "90%",
  },
];
const data = []; // rowSelection objects indicates the need for row selection

for (let i = 50; i < 81; i++) {
  data.push({
    key: i,
    name: `Tool ${i}`,
    age: 32,
    address: <span> R {i}.00</span>,
    totals: <span> R {i}.00</span>,
  });
}
const fixedColumns = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: true,
    width: 100,
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];
class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      qtyError: false,
      itemCodes: [],
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock()
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              "Part: " + stock.itemCode + " - Location: " + stock.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addStockVisible) {
      this.setState({ addStockVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  onAddStock = e => {
    this.setState({ addStockVisible: true, editStock: undefined });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e });
  };
  onEditStockApprove = stock => {
    this.setState({ loading: true });
    if (stock.status === "In Request") {
      stock.qty = parseInt(stock.qty) + 1;
    } else if (stock.status === "Out Request") {
      stock.qty = parseInt(stock.qty) - 1;
    }
    stock.status = "None";
    this.props
      .addStock(stock)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            this.props
              .sendNotification(
                stock.requestId,
                [stock.requestId],
                "Admin has approved one of your requests",
                "adminChanged",
                stock._id,
                false,
                "Asambo Admin"
              )
              .then(() => {
                this.setState({ loading: false });
                Notify("success", "Successfully approved request");
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  onEditStockOutVisible = stock => {
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    this.setState({
      stockOut: stock,
      outVisible: true,
    });
  };
  onEditStockOut = () => {
    let stock = this.state.stockOut;
    if (this.state.qtyError === true || this.state.qty === 0) {
      return;
    }
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    const id = stock._id;
    let request = {
      qty: this.state.qty,
      jobNumber: this.state.jobNumber,
      stockId: id,
      type: "Out",
      itemCode: stock.itemCode,
      description: stock.description,
      userName: this.state.userName,
      requisitionNumber: this.state.requisitionNumber,
      approved: true,
    };
    this.setState({ loading: true, outVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ itemCodes: itemCodes });
            const { vfs } = vfsFonts.pdfMake;
            pdfMake.vfs = vfs;
            let data = Object.assign({}, request);
            prepDefinition(data).then(definition => {
              pdfMake
                .createPdf(definition)
                .download(
                  stock.itemCode +
                  " " +
                  moment().format("YYYY-MM-DD") +
                  " Approved Request.pdf",
                  () => {
                    this.setState({ loading: false, qty: 1, qtyError: false });
                    Notify("success", "Successfully submitted");
                  }
                );
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleEditBatchStockOut = e => {
    e.preventDefault();
    this.requisitionForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        for (var i = 0; i < this.requisitionForm.state.parts.length; i++) {
          if (!this.requisitionForm.state.parts[i].addButton) {
            if (!this.requisitionForm.state.parts[i].qty) {
              return;
            }
          }
        }
        this.setState({ loading: true });
        if (this.state.editRequisition) {
          values._id = this.state.editRequisition._id;
        }
        values.parts = this.requisitionForm.state.parts;
        values.type = "Out";
        values.approved = true;
        this.props
          .addBatchRequest(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                const { vfs } = vfsFonts.pdfMake;
                pdfMake.vfs = vfs;
                let data = Object.assign({}, values);
                prepDefinition2(data).then(definition => {
                  pdfMake
                    .createPdf(definition)
                    .download(
                      values.requisitionNumber +
                      " " +
                      moment().format("YYYY-MM-DD") +
                      " Approved Request.pdf",
                      () => {
                        this.setState({
                          loading: false,
                          addBatchVisible: false,
                        });
                        Notify("success", "Successfully submitted");
                      }
                    );
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleSearchJob = e => {
    if (e.length > 3) {
      this.props
        .findJobNumbers(e)
        .then(suc => {
          this.setState({
            jobNumbers: suc.jobNumbers,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        jobNumbers: [],
      });
    }
  };
  handleSearchTechnician = e => {
    if (e.length > 3) {
      this.props
        .findTechnicians(e)
        .then(suc => {
          this.setState({
            technicians: suc.technicians,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        technicians: [],
      });
    }
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== "" && setQty !== null) {
      if (this.state.stockOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true,
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false,
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false,
      });
    }
  };
  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  render() {
    const { stock } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + " - " + d.username.split("|")[1]}
      </Option>
    ));
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            textAlign: "left",
            maxWidth: "90em",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.3em" }}
          >
            {/*<StockOutlined
              style={{ fontSize: 25, color: "#009BDF", marginRight: 20 }}
            />*/}
            <span
              style={{
                fontWeight: 500,
                fontSize: 20,
                color: "black",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Tools Issued to rig
              {/* <span style={{ color: '#FF9D66' }}>
                {moment().format('DD/MM/YY')}
              </span> */}
              {/*{this.props.local.rig1 ? "38" : this.props.local.rig2 ? "91" : ""}*/}
              {/*{this.state.addStockVisible ? "Add Stock" : "Stock"}*/}
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "-1em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <StyledDatePicker
                onChange={e => {
                  this.setState({
                    startDate: e[0],
                    endDate: e[1],
                  });
                }}
                value={[this.state.startDate, this.state.endDate]}
                format={dateFormat}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <div style={{ maxWidth: "90em", margin: "auto" }}>
          <Spin spinning={this.state.loading}>
            {/* ) : ( */}
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display:
                  !this.state.addStockVisible && !this.state.addBatchVisible
                    ? "flex"
                    : "none",
              }}
              type="flex"
              justify="start"
              align="middle"
            >
              <Col span={24}>
                <StyledTable
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{
                    // x: 1500,
                    y: 600,
                  }}
                  pagination={{
                    defaultPageSize: 7,
                  }}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row
                        style={{
                          backgroundColor: "#F0F3F9",
                          // color: 'red',
                          fontWeight: 500,
                          fontSize: "15px",
                        }}
                      >
                        <Table.Summary.Cell
                          index={5}
                          colSpan={5}
                        // style={{ color: 'red' }}
                        >
                          Total Cost
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          // style={{ color: 'red' }}
                          index={5}
                          colSpan={1}
                        >
                          R3 304.25
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                >
                  {" "}
                  <Column
                    //{...this.getColumnSearchProps("status")}
                    title="Tool Status"
                    dataIndex="status"
                    key="status"
                    render={status => {
                      switch (status) {
                        case "New":
                          return this.renderStatus("New", "#4BED5B");
                        case "Used":
                          return this.renderStatus("Used", "#EE4C4C");
                        case "Out":
                          return this.renderStatus("Out", "#FF8F28");
                        case "Sharp":
                          return this.renderStatus("Sharp", "#EDAC4B");
                        case "Lost":
                          return this.renderStatus("Lost", "#D4D4D4");
                        case "Damaged":
                          return this.renderStatus("Damaged", "#EE4C4C");
                        default:
                          return this.renderStatus("No Status", "lightgrey");
                      }
                    }}
                  />
                </StyledTable>

                {/* <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    defaultPageSize: 25
                  }}
                  expandedRowRender={
                    window.innerWidth <= 420
                      ? record => (
                        <Card
                          size="small"
                          title={record.itemCode}
                          style={{ width: 300, marginLeft: -35 }}
                        >
                          <p>Brand: {record.brand}</p>
                          <p>Description: {record.description}</p>
                          <p>Quantity: {record.qty}</p>
                          <p>Location: {record.location}</p>
                          <p>Status: {record.status}</p>
                        </Card>
                      )
                      : null
                  }
                  dataSource={this.props.stock}
                  onChange={this.handleSort}
                  onRow={record => {
                    if (parseInt(record.qty) === 0) {
                      return { style: { background: '#ffd3d3' } };
                    }
                  }}
                >
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('itemCode')}
                      title="Rig Number"
                      dataIndex="currentRig"
                      key="currentRig"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('currentRig')}
                      title="Current Tool"
                      dataIndex="itemCode"
                      key="itemCode"
                    />
                  ) : null}
                  <Column
                    {...this.getColumnSearchProps('toolType')}
                    title="Tool Type"
                    dataIndex="toolType"
                    key="dtoolType"
                  />
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('itemDescription')}
                      title="Description"
                      dataIndex="itemDescription"
                      key="itemDescription"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('removedMeters')}
                      title="Meters"
                      dataIndex="removedMeters"
                      key="removedMeters"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      //{...this.getColumnSearchProps("status")}
                      title="Tool Status"
                      dataIndex="status"
                      key="status"
                      render={status => {
                        switch (status) {
                          case 'New':
                            return this.renderStatus('New', '#4BED5B');
                          case 'Used':
                            return this.renderStatus('Used', '#EE4C4C');
                          case 'Out':
                            return this.renderStatus('Out', '#FF8F28');
                          case 'Sharp':
                            return this.renderStatus('Sharp', '#EDAC4B');
                          case 'Lost':
                            return this.renderStatus('Lost', '#D4D4D4');
                          case 'Damaged':
                            return this.renderStatus('Damaged', '#EE4C4C');
                          default:
                            return this.renderStatus('No Status', 'lightgrey');
                        }
                      }}
                    />
                  ) : null}

                  <Column
                    {...this.getColumnSearchProps("actions")}
                    title="Actions"
                    render={(text, stock) => (
                      <span>
                        <Button
                          style={{
                            backgroundColor: '#009BDF',
                            color: 'white',
                            borderRadius: '3em'
                          }}
                          onClick={e => this.onEditStock(stock)}
                        >
                          View Rig
                        </Button>
                        <Divider type="vertical" />
                        <DeleteFilled
                          style={{
                            backgroundColor: '#CFD5E3',
                            color: 'white',
                            padding: '0.5em',
                            borderRadius: '3em'
                          }}
                          onClick={e => {
                            this.setState({
                              deleteVisible: true,
                              deleteStock: stock
                            });
                          }}
                        />
                      </span>
                    )}
                  />
                </StyledTable> */}
              </Col>
            </Row>
            {/* )} */}
          </Spin>
        </div>
        {/* <Modal
          title="Add Stock"
          visible={this.state.addStockVisible}
          onOk={this.handleAddStock}
          onCancel={e => this.setState({ addStockVisible: false })}
          okText={this.state.editStock ? 'Save' : 'Add Stock'}
          cancelText="Cancel"
        >
          <StockForm
            editStock={this.state.editStock}
            wrappedComponentRef={form => (this.stockForm = form)}
          />
        </Modal> */}
        <Modal
          title={"Delete stock"}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteStock}
          onCancel={this.cancelDelete}
          okText="Accept"
          cancelText="Cancel"
        >
          <span>
            {" "}
            Are you sure you want to delete rig{" "}
            {this.props.stock.map(stock => (
              <span>{stock.currentRig}</span>
            ))}
          </span>
        </Modal>
        <Modal
          title={"Stock Out"}
          visible={this.state.outVisible}
          onOk={this.onEditStockOut}
          onCancel={e =>
            this.setState({ outVisible: false, qty: 1, qtyError: false })
          }
          okText="Submit"
          cancelText="Cancel"
        >
          <span>Qty</span>
          <InputNumber
            min={1}
            style={{ marginBottom: 15, width: "100%" }}
            onChange={this.checkQty}
            value={this.state.qty}
          />
          {this.state.qtyError ? (
            <p style={{ color: "red", width: "100%" }}>
              There is not enough stock to satisfy this quantity
            </p>
          ) : null}
          <span>Requisition Number</span>
          <Input
            style={{ marginBottom: 15, width: "100%" }}
            value={this.state.requisitionNumber}
            onChange={e => this.setState({ requisitionNumber: e.target.value })}
          />
          <span>Assign stock to technician</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: "100%", marginBottom: 14 }}
            onSearch={this.handleSearchTechnician}
            value={this.state.userName}
            placeholder={"Type in at least 4 characters to start search"}
            onChange={e => this.setState({ userName: e })}
          >
            {technicianOptions}
          </Select>
          <span>Assign stock to job</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: "100%" }}
            onSearch={this.handleSearchJob}
            value={this.state.jobNumber}
            placeholder={"Type in at least 4 characters to start search"}
            onChange={e => this.setState({ jobNumber: e })}
          >
            {jobOptions}
          </Select>
        </Modal>
      </div>
    );
  }
}

export default Request;
