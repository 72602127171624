export function collapseSidebar() {
  return dispatch =>
    new Promise(resolve =>
      resolve(
        dispatch({
          type: "SIDEBAR_COLLAPSE",
        })
      )
    );
}
export function updateRig1() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG1",
    });
}
export function updateRig2() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG2",
    });
}
export function updateRig3() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG3",
    });
}
export function updateRig4() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG4",
    });
}
export function updateRig5() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG5",
    });
}
export function updateRig6() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG6",
    });
}
export function updateRig7() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG7",
    });
}
export function updateRig8() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG8",
    });
}
export function updateRig9() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG9",
    });
}
export function updateRig10() {
  return dispatch =>
    dispatch({
      type: "UPDATE_RIG10",
    });
}

export function updateSerial() {
  return dispatch =>
    dispatch({
      type: "UPDATE_SERIAL",
    });
}
