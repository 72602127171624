import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { sendNotification } from 'src/redux/actions/notifications';
import { findMyRequest } from 'src/redux/actions/request';
import { withRouter, Link } from 'react-router-dom';

class Request extends Component {
  render = () => {
    const {
      Layout,
      request,
      user,
      findMyRequest,
      sendNotification,
      push,
      history
    } = this.props;
    return (
      <Layout
        request={request}
        user={user}
        findMyRequest={findMyRequest}
        sendNotification={sendNotification}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  request: state.request.myRequest || [],
  user: state.user.user || {}
});

const mapDispatchToProps = {
  findMyRequest,
  sendNotification,
  push
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Request)
);
