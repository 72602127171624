import { getToken } from "src/redux/selectors";

export function addBits(values, activeDisplayId) {
  if (activeDisplayId) {
    values.activeDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/addbits",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add tool" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateBits(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/updatebits",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to update tool " });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveBits(values, activeDisplayId) {
  if (activeDisplayId) {
    values.activeDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/setbits",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to edit tool" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteBits(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/deletebits",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete tool" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllBits(access) {
  let url;
  if (access) {
    url =
      process.env.REACT_APP_GLOBAL_SERVER +
      "asambo/api/bits/findallbits?access=" +
      access;
  } else {
    url = process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/findallbits";
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "BITS_FIND_ALLBITS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllBitsUpdatedToday(access) {
  let url;
  if (access) {
    url =
      process.env.REACT_APP_GLOBAL_SERVER +
      "asambo/api/bits/todayupdatedbits?access=" +
      access;
  } else {
    url =
      process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/todayupdatedbits";
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "BITS_UPDATED_TODAY",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find140mm() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/bits140", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "BITS_FIND_BITS140",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find38() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/find38", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "BITS_FIND_RIG38",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function find171mm() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/bits/bits171", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "BITS_FIND_BITS171",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditBits(bits) {
  return dispatch =>
    dispatch({
      type: "BIT_SET_EDITBITS",
      data: bits,
    });
}
