import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { sendNotification } from 'src/redux/actions/notifications';
import { addStock, deleteStock, findAllStock } from 'src/redux/actions/stock';
import {
  addBits,
  deleteBits,
  findAllBits,
  findAllBitsUpdatedToday,
  updateBits
} from 'src/redux/actions/bits';
import {
  addRequest,
  addBatchRequest,
  findJobNumbers,
  findTechnicians
} from 'src/redux/actions/request';

import { withRouter, Link } from 'react-router-dom';

class PexUnits extends Component {
  render = () => {
    const {
      Layout,
      stock,
      user,
      findAllStock,
      addStock,
      deleteStock,
      addRequest,
      addBatchRequest,
      findJobNumbers,
      findTechnicians,
      sendNotification,
      push,
      history,
      findAllBits,
      addBits,
      deleteBits,
      bits,
      updateBits,
      findAllBitsUpdatedToday
    } = this.props;
    return (
      <Layout
        stock={stock}
        user={user}
        addStock={addStock}
        findAllStock={findAllStock}
        addRequest={addRequest}
        findTechnicians={findTechnicians}
        addBatchRequest={addBatchRequest}
        findJobNumbers={findJobNumbers}
        deleteStock={deleteStock}
        sendNotification={sendNotification}
        push={push}
        history={history}
        bits={bits}
        findAllBits={findAllBits}
        deleteBits={deleteBits}
        addBits={addBits}
        updateBits={updateBits}
        findAllBitsUpdatedToday={findAllBitsUpdatedToday}
      />
    );
  };
}

const mapStateToProps = state => ({
  stock: state.stock.allStock || [],
  user: state.user.user || {},
  bits: state.bits.allBits || []
});

const mapDispatchToProps = {
  addStock,
  deleteStock,
  findAllStock,
  addRequest,
  sendNotification,
  push,
  addBatchRequest,
  findJobNumbers,
  findTechnicians,
  findAllBits,
  addBits,
  updateBits,
  findAllBitsUpdatedToday
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PexUnits)
);
