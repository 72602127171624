import { getToken } from "src/redux/selectors";

export function sendNotification(
  id,
  channels,
  message,
  type,
  stockId,
  email,
  subject
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/notifications/postnotification",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({
            id: id,
            channels: channels,
            message: message,
            type: type,
            stockId: stockId,
            email: email,
            subject: subject,
          }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to send notification" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function removeNotification(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/notifications/removenotification",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add stock" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findNotifications() {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "asambo/api/notifications/getnotifications",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "NOTIFICATIONS_FIND",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}
