import React, { PureComponent } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  BellFilled,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  MessageOutlined,
  BellOutlined,
  PhoneOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  MailOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Popover,
  Menu,
  Modal,
  Upload,
  Badge,
  notification,
  List,
  Space,
  Typography,
  Button,
  PageHeader,
} from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import logo from "src/static/logo-white.svg";
import MenuIcon from "src/static/menu-icon.svg";
import MenuOpenIcon from "src/static/menu-open-icon.svg";
import logoWhite from "src/static/logo-white.svg";
import { Cookies } from "react-cookie";
import { saveAccountInfo } from "src/redux/actions/user";
import {
  getSidebarCollapsed,
  getUser,
  getNotifications,
} from "src/redux/selectors";

import { ButtonSquare } from "src/components/elements/Button";
import { push } from "connected-react-router";
import { collapseSidebar } from "src/redux/actions/status";
import Resizer from "react-image-file-resizer";
import Notify from "src/components/meta/Notification";
import { setStockDetailsId } from "src/redux/actions/stock";
import {
  findNotifications,
  removeNotification,
} from "src/redux/actions/notifications";
import LogoSmall from "../../static/logo-small.svg";
const { Title } = Typography;
const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #009bdf;
  }
`;
const StyledBadge = styled(Badge)`
  margin: auto !important;
  .ant-badge {
    margin: auto;
  }
`;
const signOut = s => {
  if (window.cordova) {
    window.FirebasePlugin.getToken(
      token => {
        window.pubnub.push.deleteDevice(
          {
            device: token,
            pushGateway: "gcm", // apns, gcm, mpns
          },
          function (status) {
            if (status.error) {
              console.log("operation failed w/ error:", status);
            }
          }
        );
      },
      error => {
        console.error(error);
      }
    );
  }

  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("basic");
  localStorage.clear();
  window.location.reload();
};
const Logo = () => (
  <SVG src={logo} style={{ margin: "auto", height: 40, width: "400px" }} />
);
if (window.innerWidth <= 1100) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
export class ResHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      imageVisible: false,
      notifVisible: false,
    };
  }
  componentWillMount = () => {
    // this.props
    //   .findNotifications()
    //   .then(() => { })
    //   .catch(e => {
    //     Notify("error", "Error retrieving notification history");
    //   });
    // window.pubnub.addListener({
    //   message: msg => {
    //     this.props
    //       .findNotifications()
    //       .then(() => {})
    //       .catch(e => {
    //         Notify("error", "Error retrieving notification history");
    //       });
    //   },
    // });
    // if (window.cordova) {
    //   document.addEventListener("backbutton", this.handleBack);
    // }
  };
  // componentWillUnMount = () => {
  //   if (window.cordova) {
  //     document.removeEventListener("backbutton", this.handleBack);
  //   }
  // };
  collapseSidebar = () => {
    this.props.collapseSidebar();
  };
  signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: "gcm", // apns, gcm, mpns
            },
            function (status) {
              if (status.error) {
                console.log("operation failed w/ error:", status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("basic");
    localStorage.clear();
    window.location.reload();
  };
  cancelProfileImage = e => {
    this.setState({ imageVisible: false });
  };
  exit = false;
  handleBack = () => {
    this.props.history.goBack();
  };
  handleProfileImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined,
    });
  };
  handleAcceptProfileImage = e => {
    let user = this.props.user;
    user.image = this.state.image;
    this.props
      .saveAccountInfo(user)
      .then(() => {
        this.setState({ loading: false, imageVisible: false, visible: false });
        Notify("success", "Success", "Successfully saved account information");
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", window.i18n.translate("Notify3"), e);
      });
  };
  handleNotificationClick = e => {
    let item = JSON.parse(e.currentTarget.getAttribute("data"));
    this.setState({ notifVisible: false }, () => {
      if (item.message.type === "request") {
        this.props.push("/admin/requests");
      }
      if (item.message.type === "adminChanged") {
        this.props.push("/user/stock");
      }
      if (item.message.type === "requisitionAdd") {
        this.props.push("/buyer/requisition");
      }

      this.props
        .removeNotification(item._id)
        .then(() => {
          this.props
            .findNotifications()
            .then(() => { })
            .catch(e => {
              Notify("error", "Error retrieving notifictation history");
            });
        })
        .catch(e => {
          Notify("error", "Error removing notification");
        });
    });
  };
  clearNotifications = e => {
    this.props
      .removeNotification()
      .then(() => {
        this.props
          .findNotifications()
          .then(() => { })
          .catch(e => {
            Notify("error", "Error retrieving notifictation history");
          });
      })
      .catch(e => {
        Notify("error", "Error removing notification");
      });
  };
  render() {
    return (
      <Layout.Header
        style={{
          zIndex: 99,
          display: window.isPhone ? "none" : "",
          background: "white",
          height: 50,
          position: "fixed",
          width: this.props.collapsed
            ? "calc(100% - 50px)"
            : "calc(100% - 210px)",
          marginLeft: this.props.collapsed ? 90 : 210,
          paddingLeft: 10,
          //border: '1px solid #00878e57'
        }}
      >
        <div style={{}}>
          <Button
            onClick={signOut}
            style={{
              zIndex: 99,
              float: "right",
              borderRadius: "5em",
              height: "3.2em",
              backgroundColor: "rgb(0, 21, 41)",
              color: "white",
              marginRight: this.props.collapsed ? "-0em" : "-3em",
              marginTop: "0.2em",
              boxShadow: "rgb(0 0 0 / 7%) 0px 5px 10px 1px",
            }}
          >
            <PoweroffOutlined />
          </Button>
          {/* <Button
            style={{
              marginLeft: "0.5em",
              float: "right",
              borderRadius: "5em",
              marginLeft: "0.5em",
              height: "3.2em",
              marginTop: "0.2em",
              boxShadow: "rgb(0 0 0 / 7%) 0px 5px 10px 1px",
            }}
          >
            <BellOutlined />
          </Button>
          <Button
            style={{
              float: "right",
              borderRadius: "5em",
              marginTop: "0.2em",
              height: "3.2em",
              boxShadow: "rgb(0 0 0 / 7%) 0px 5px 10px 1px",
            }}
          >
            <MessageOutlined />
          </Button> */}
        </div>
        {/* <Row type='flex' align='middle' justify="start" style={{ height: '100%' }}> */}
        {/* <Col
          style={{
            height: 40,
            width: 140,
            display: 'flex',
            borderBottom: '0px solid #E5E5E5'
          }}
          span={1}
        > */}
        {/*{this.props.collapsed ? (
          <MenuFoldOutlined
            style={{ color: "rgb(0, 58, 152)", fontSize: "25px" }}
            onClick={this.collapseSidebar}
          />
        ) : (
          <MenuUnfoldOutlined
            style={{ color: "rgb(0, 58, 152)", fontSize: "25px" }}
            onClick={this.collapseSidebar}
          />
        )}*/}

        {/* </Col> */}
        {/*<img src={logoWhite} style={{ marginBottom: 18, marginLeft: 10 }} />*/}
        {/*
        <div
          span={2}
          style={{
            marginTop: '-1em',
            float: 'right'
          }}
        >
          <img
            src={this.props.collapsed ? MenuIcon : MenuOpenIcon}
            className="trigger"
            //type={this.props.collapsed ? "menu" : {MenuOpenIcon }}
            onClick={this.collapseSidebar}
            style={{ width: 86, margin: 'auto' }}
          />
        </div>
        */}
        {/*<Col
          span={2}
          style={{
            float: "right",
            display: "flex",
            height: 40,
            width: 40,
            borderLeft: "0px solid #E5E5E5",
          }}
        >
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={6}>
                    {this.props.user.image ? (
                      <div
                        onClick={(e) =>
                          this.setState({
                            imageVisible: true,
                            image: this.props.user.image,
                          })
                        }
                        style={{
                          border: "1px solid #009BDF3b",
                          cursor: "pointer",
                          height: 40,
                          width: 40,
                          margin: "auto",
                          borderRadius: "50%",
                          display: "flex",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{ margin: "auto", width: 40 }}
                          alt="Profile"
                          src={this.props.user.image}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={(e) => this.setState({ imageVisible: true })}
                        style={{
                          cursor: "pointer",
                          height: 40,
                          width: 40,
                          margin: "auto",
                          borderRadius: "50%",
                          backgroundColor: "#009BDF",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            margin: "auto",
                            lineHeight: "40px",
                            fontSize: 35,
                            color: "white",
                          }}
                        >
                          {this.props.user.name.substr(0, 1)}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col span={16}>
                    <div style={{ margin: "0px 0px 8px 0px", width: 250 }}>
                      <span style={{ fontSize: 13 }}>
                        {this.props.user.username}
                      </span>
                    </div>

                    <ButtonSquare
                      style={{ height: 25 }}
                      onClick={(e) => {
                        if (this.props.user.type === "manager") {
                          this.props.push("/manager/account");
                        } else if (this.props.user.type === "user") {
                          this.props.push("/user/account");
                        } else if (this.props.user.type === "admin") {
                          this.props.push("/admin/account");
                        }
                      }}
                      type="primary"
                    >
                      My Settings
                    </ButtonSquare>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: 20 }}
                >
                  <Menu
                    style={{ background: "none" }}
                    theme="light"
                    mode="inline"
                  >
                    <Menu.Item onClick={this.signOut} key="signout">
                      <PoweroffOutlined />
                      <span>Sign Out</span>
                    </Menu.Item>
                  </Menu>
                </Row>
              </div>
            }
            trigger="click"
            // visible={this.state.visible}
          >
            {this.props.user.image ? (
              <div
                onClick={(e) => this.setState({ visible: !this.state.visible })}
                style={{
                  border: "1px solid #009BDF3b",
                  cursor: "pointer",
                  height: 40,
                  width: 40,
                  margin: "auto",
                  borderRadius: "50%",
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ margin: "auto", width: 40 }}
                  alt="Profile"
                  src={this.props.user.image}
                />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  this.setState({ visible: !this.state.visible });
                }}
                style={{
                  cursor: "pointer",
                  height: 30,
                  width: 30,
                  margin: "auto",
                  borderRadius: "50%",
                  backgroundColor: "#009BDF",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    margin: "auto",
                    lineHeight: "30px",
                    fontSize: 20,
                    color: "white",
                  }}
                >
                  {this.props.user.name.substr(0, 1)}
                </span>
              </div>
            )}
          </Popover>
        </Col>*/}
        {/*<Col
          span={1}
          style={{
            float: "right",
            display: "flex",
            height: 40,
            width: 40,
            borderRight: "1px solid #E5E5E5",
            borderLeft: "1px solid #E5E5E5",
          }}
        >
          <Popover
            title="Your Notifications"
            placement="bottomRight"
            content={
              <div>
                <a
                  onClick={this.clearNotifications}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    display:
                      this.props.notifications.length === 0 ? "none" : "block",
                  }}
                >
                  Clear All
                </a>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={24}>
                    <List
                      locale={{ emptyText: "No Notifications" }}
                      pagination={{
                        onChange: (page) => {
                          console.log(page);
                        },
                        pageSize: 5,
                      }}
                      itemLayout="horizontal"
                      dataSource={this.props.notifications}
                      renderItem={(item) => (
                        <List.Item
                          style={{ cursor: "pointer" }}
                          data={JSON.stringify(item)}
                          onClick={this.handleNotificationClick}
                        >
                          <Col span={5}>
                            <div
                              style={{
                                cursor: "pointer",
                                height: 50,
                                width: 50,
                                margin: "auto",
                                marginLeft: "0px",
                                borderRadius: "50%",
                                background: "#009BDF",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  margin: "auto",
                                  lineHeight: "0px",
                                  fontSize: 30,
                                  color: "white",
                                }}
                              >
                                <NotificationOutlined />
                              </span>
                            </div>
                          </Col>
                          <List.Item.Meta
                            title={item.message.sentBy}
                            description={item.message.message}
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            }
            trigger="click"
            // visible={this.state.notifVisible}
            // onChange={e => this.setState({notifVisible: !this.state.notifVisible})}
          >
            <div style={{ margin: "auto", height: 26, display: "flex" }}>
              <StyledBadge
                onClick={(e) =>
                  // this.handleBack()
                  this.setState({ notifVisible: !this.state.notifVisible })
                }
                count={
                  this.props.notifications.length > 0 ? (
                    <div
                      style={{
                        borderRadius: "50%",
                        background: "red",
                        color: "white",
                        width: 11,
                        fontSize: 9,
                        height: 11,
                        textAlign: "center",
                      }}
                    >
                      {this.props.notifications.length}
                    </div>
                  ) : null
                }
              >
                <BellFilled
                  style={{ fontSize: 26, color: "#009BDF", cursor: "pointer" }}
                />
              </StyledBadge>
            </div>
          </Popover>
        </Col>*/}
        <Modal
          title={"Profile Image"}
          visible={this.state.imageVisible}
          onOk={this.handleAcceptProfileImage}
          onCancel={this.cancelProfileImage}
          okText="Accept"
          cancelText="Cancel"
        >
          {/* <div style={{ width: '100%', height: 154, display: 'flex' }}> */}
          <Row type="flex" justify="center">
            <Col
              style={{
                display: "flex",
                marginBottom: 20,
              }}
              span={24}
            >
              <div style={{ margin: "auto" }}>
                {this.state.image ? (
                  <div
                    style={{
                      border: "1px solid #009BDF3b",
                      height: 200,
                      width: 200,
                      margin: "auto",
                      borderRadius: "50%",
                      display: "flex",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{ margin: "auto", width: 200 }}
                      alt="Profile"
                      src={this.state.image}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: 200,
                      width: 200,
                      margin: "auto",
                      borderRadius: "50%",
                      background: "#009BDF",
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        margin: "auto",
                        lineHeight: "200px",
                        fontSize: 130,
                        color: "white",
                      }}
                    >
                      {this.props.user.name.substr(0, 1)}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col style={{ display: "flex" }} span={24}>
              <div style={{ margin: "auto" }}>
                <Upload
                  name="mainImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleProfileImage}
                  accept="image/*"
                >
                  <ButtonSquare style={{ height: 45 }} type="primary">
                    Upload Profile Image
                  </ButtonSquare>
                </Upload>
                <ButtonSquare
                  style={{ marginLeft: 5, height: 45 }}
                  onClick={this.removeImage}
                >
                  Remove
                </ButtonSquare>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </Modal>
      </Layout.Header>
    );
  }
}

const mapStateToProps = state => ({
  collapsed: getSidebarCollapsed(state),
  notifications: getNotifications(state) || [],
  user: getUser(state) || {},
});

const mapDispatchToProps = {
  collapseSidebar,
  push,
  saveAccountInfo,
  setStockDetailsId,
  findNotifications,
  removeNotification,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResHeader)
);
