import React, { useState } from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { withRouter, Link } from "react-router-dom";
import "@ant-design/compatible/assets/index.css";
import NewIcon from "src/static/new-icon.svg";
import SharpIcon from "src/static/sharp-icon.svg";
import DamagedIcon from "src/static/damaged-icon.svg";
import LostIcon from "src/static/lost-icon.svg";
import RigImg from "src/static/rig-img.png";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
} from "antd";
import moment from "moment";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { ButtonRadius } from "src/components/elements/Button";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import SignatureCanvas from "react-signature-canvas";
import {
  updateRig1,
  updateRig2,
  updateRig3,
  updateRig4,
  updateRig5,
  updateRig6,
  updateRig7,
  updateRig8,
  updateRig9,
  updateRig10,
} from "src/redux/actions/status";
import { connect } from "react-redux";
import { thisExpression } from "@babel/types";
function onChange(date, dateString) {
  console.log(date, dateString);
}
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const numeral = require("numeral");
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledCard = styled(Card)`
  .ant-card-body {
    height: 5.4em;
    padding: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 3px !important;
    display: flex;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 55px !important;
  border-radius: 0.5em !important;
  padding-top: 0px !important;
  .ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.45) !important;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  border-radius: 0.5em !important;
  ::placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.8em !important;
    width: 0.8em !important;
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
`;
const EditableContext = React.createContext();

class StockFormClassBit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Bit
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: false, message: "Serial required" }],
                })(
                  <StyledInput
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "Removed Status",
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" style={{}} /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" style={{}} /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" style={{}} /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" style={{}} /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassHammer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleHammer: false,
    };
  }
  showModalHammer = () => {
    this.setState({
      visibleHammer: true,
    });
  };

  handleOkHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  handleCancelHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Hammer
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: false, message: "Serial required" }],
                })(
                  <StyledInput
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "Removed Status",
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" style={{}} /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" style={{}} /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" style={{}} /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" style={{}} /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
function checkAdult(bitStatus) {
  return bitStatus === "nNw";
}
class StockFormClassRod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleRod: false,
    };
  }
  showModalRod = () => {
    this.setState({
      visibleRod: true,
    });
  };

  handleOkRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };

  handleCancelRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Rod
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: false, message: "Serial required" }],
                })(
                  <StyledInput
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "Removed Status",
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" style={{}} /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" style={{}} /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" style={{}} /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" style={{}} /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Serial"
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Stock Nr."
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassAdapter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleAdapter: false,
    };
  }
  showModalAdapter = () => {
    this.setState({
      visibleAdapter: true,
    });
  };

  handleOkAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };

  handleCancelAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Adapter
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: false, message: "Serial required" }],
                })(
                  <StyledInput
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "Removed Status",
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" style={{}} /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" style={{}} /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" style={{}} /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" style={{}} /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Stock Nr."
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassRefurbish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleRefurbish: false,
    };
  }
  showModalRefurbish = () => {
    this.setState({
      visibleRefurbish: true,
    });
  };

  handleOkRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };

  handleCancelRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Refurbish
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <FormItem>
                {this.props.form.getFieldDecorator("removedMeters", {
                  rules: [{ required: false, message: "Serial required" }],
                })(
                  <StyledInput
                    placeholder="Meters"
                    style={{ width: "100%", height: 55 }}
                    onChange={e =>
                      this.setState({ removedMeters: e.target.value })
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5em" }}>
            <Col span={24} style={{ marginTop: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("status", {
                  rules: [{ required: true, message: "Status is required" }],
                  initialValue: this.props.editBits
                    ? this.props.editBits.status
                    : "Removed Status",
                })(
                  <StyledSelect
                    placeholder="Removed Status"
                    style={{ width: "100%", height: 55 }}
                  >
                    <Option value={"New"}>
                      {" "}
                      <StyledBadge color="#4BED5B" style={{}} /> New
                    </Option>
                    <Option value="Used">
                      {" "}
                      <StyledBadge color="#EE4C4C" style={{}} /> Used
                    </Option>
                    <Option value="Out">
                      {" "}
                      <StyledBadge color="#FF8F28" style={{}} /> Out
                    </Option>
                    <Option value="None">
                      {" "}
                      <StyledBadge color="lightgrey" style={{}} /> None
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledSelect
                  placeholder="Serial"
                  style={{ width: "100%" }}
                  onChange={bitSerial => {
                    const selectedBit = this.props.bits.find(
                      bit => bit.serial === bitSerial
                    );
                    this.setState({ selectedBit });
                  }}
                >
                  {this.props.bits.map(bitserial => {
                    return (
                      <Option value={bitserial.serial}>
                        {bitserial.serial}
                      </Option>
                    );
                  })}{" "}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("toolType", {
                rules: [{ required: true, message: "Type required" }],
                initialValue:
                  selectedBit && selectedBit.size
                    ? selectedBit.size
                    : "No Size",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceSize: e })}
                >
                  {this.props.bits.map(bitsize => {
                    return <Option value={bitsize.size}>{bitsize.size}</Option>;
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Stock Nr."
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemStatus", {
                rules: [{ required: true, message: "Status required" }],
                initialValue:
                  selectedBit && selectedBit.status
                    ? selectedBit.status
                    : "No Status",
              })(
                <StyledSelect
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  disabled
                  onChange={e => this.setState({ replaceStatus: e })}
                >
                  <Option value={"New"}>
                    {" "}
                    <StyledBadge color="#4BED5B" style={{}} /> New
                  </Option>
                  <Option value="Used">
                    {" "}
                    <StyledBadge color="#EE4C4C" style={{}} /> Used
                  </Option>
                  <Option value="Out">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> Out
                  </Option>
                  <Option value="None">
                    {" "}
                    <StyledBadge color="#FF8F28" style={{}} /> None
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row style={{ marginTop: "-0.5em" }}>
          <Col span={24}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemDescription", {
                rules: [{ required: true, message: "Description required" }],
                initialValue:
                  selectedBit && selectedBit.description
                    ? selectedBit.description
                    : "No Description",
              })(
                <StyledSelect
                  disabled
                  //hidden={!selectedBit ? true : false}
                  style={{ width: "100%", height: 55 }}
                  onChange={e => this.setState({ replaceDesc: e })}
                >
                  {this.props.bits.map(bitdesc => {
                    return (
                      <Option value={bitdesc.description}>
                        {bitdesc.description}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassRigMeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftDamages: false,
    };
  }
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <span>
        <Form layout="horizontal">
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            <Col
              xs={12}
              md={12}
              style={{ marginLeft: "-0.8em", marginTop: "0em" }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "#FF9D66",
                  marginLeft: 10,
                  fontFamily: "Roboto",
                }}
              >
                Rig Meters
              </span>{" "}
              <br />
            </Col>
          </Row>
          <span>
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display: this.state.addBitsVisible ? "flex" : "none",
              }}
              type="flex"
              justify="space-around"
              align="middle"
            />
            <Row style={{ marginTop: "1em" }}>
              <Col span={11}>
                <StyledInput
                  placeholder="Rig Meters"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </Col>
              <Col span={2} />
              <Col span={11}>
                <FormItem>
                  {this.props.form.getFieldDecorator("Pit Drilled", {
                    rules: [
                      { required: false, message: "Pit Drilled required" },
                    ],
                  })(
                    <StyledInput
                      placeholder="Pit Drilled"
                      //value={
                      //  this.props.editStock ? this.props.editStock.itemCode : ""
                      //}
                      style={{ width: "100%", height: 55 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </span>
        </Form>
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </span>
    );
  }
}
class StockFormClassShiftMeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftMeters: false,
    };
  }
  showModalShiftMeters = () => {
    this.setState({
      visibleShiftMeters: true,
    });
  };

  handleOkShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  handleCancelShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        {" "}
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.6em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Shift Meters
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col span={18} style={{ float: "right", whiteSpace: "nowrap" }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "lightgrey",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                }}
              />
            </Col>
            <br />
          </Col>
        </Row>
        <span>
          <Col
            xs={24}
            md={12}
            style={{
              marginLeft: "-0.8em",
              marginTop: "1.5em",
              marginBottom: "0.5em",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              Removed tool
            </span>
          </Col>
          <Row
            style={{
              background: "white",
              borderRadius: 5,
              marginTop: 20,
              display: this.state.addBitsVisible ? "flex" : "none",
            }}
            type="flex"
            justify="space-around"
            align="middle"
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={11}>
              <StyledInput
                disabled
                placeholder="Serial"
                //value={
                //  this.props.editStock ? this.props.editStock.itemCode : ""
                //}
                style={{ width: "100%", height: 55 }}
              />
            </Col>
            <Col span={2} />
            <Col span={11}>
              <StyledSelect
                disabled
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col span={24}>
              <FormItem>
                <StyledInput
                  disabled
                  placeholder="Description"
                  //value={
                  //  this.props.editStock ? this.props.editStock.toolType : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              </FormItem>
            </Col>
          </Row>
        </span>
        <Col
          xs={24}
          md={12}
          style={{
            marginLeft: "-0.8em",
            marginTop: "1.5em",
            marginBottom: "0.5em",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "black",
              marginLeft: 10,
              fontFamily: "Poppins",
            }}
          >
            Replacement tool
          </span>
          <Link to="/user/dashboard">
            <span
              style={{
                float: "right",
                fontWeight: 500,
                fontSize: 15,
                color: "rgb(255, 157, 102)",
                marginLeft: 10,
                fontFamily: "Poppins",
              }}
            >
              <PlusCircleOutlined /> View Tools
            </span>
          </Link>
        </Col>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("itemCode", {
                rules: [{ required: true, message: "Serial required" }],
              })(
                <StyledInput
                  placeholder="Stock Nr."
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              <StyledSelect
                defaultValue="None"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"New"}>
                  {" "}
                  <StyledBadge color="#4BED5B" style={{}} /> New
                </Option>
                <Option value="Used">
                  {" "}
                  <StyledBadge color="#EE4C4C" style={{}} /> Used
                </Option>
                <Option value="Out">
                  {" "}
                  <StyledBadge color="#FF8F28" style={{}} /> Out
                </Option>
                <Option value="None">
                  {" "}
                  <StyledBadge color="lightgrey" style={{}} /> None
                </Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-0.5em" }}>
          {" "}
          <Col span={11}>
            <FormItem>
              {this.props.form.getFieldDecorator("stockNr", {
                rules: [{ required: true, message: "Stock Numberrequired" }],
              })(
                <StyledInput
                  placeholder="Serial"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {/*{this.props.form.getFieldDecorator("description", {
                rules: [{ required: true, message: "Description required" }],
                initialValue: this.props.editPexunit
                  ? this.props.editPexunit.description
                  : "",
              })(*/}
              <StyledInput
                placeholder="Description"
                style={{ width: "100%", height: 55 }}
              />
              {/*)}*/}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
class StockFormClassDamages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visibleShiftDamages: false,
    };
  }
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <Form layout="horizontal">
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            borderBottom: "1px solid #DDDDDD",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "0em" }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 26,
                color: "#FF9D66",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              Damages
            </span>{" "}
            <br />
          </Col>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <Col span={11}>
            <FormItem label={<span>Bit</span>}>
              {this.props.form.getFieldDecorator("itemCode", {})(
                <StyledInput
                  placeholder="Stock Number"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem style={{ marginTop: "2.1em" }}>
              <StyledSelect
                placeholder="Reason"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"Stuck"}>Stuck</Option>
                <Option value="Break">Break</Option>
                <Option value="Formation">Formation</Option>
                <Option value="Lost in hole">Lost in hole</Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-1em" }}>
          <Col span={11}>
            <FormItem label={<span>Hammer</span>}>
              {this.props.form.getFieldDecorator("itemCode", {})(
                <StyledInput
                  placeholder="Stock Number"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem style={{ marginTop: "2.1em" }}>
              <StyledSelect
                placeholder="Reason"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"Stuck"}>Stuck</Option>
                <Option value="Break">Break</Option>
                <Option value="Formation">Formation</Option>
                <Option value="Lost in hole">Lost in hole</Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-1em" }}>
          <Col span={11}>
            <FormItem label={<span>Rod</span>}>
              {this.props.form.getFieldDecorator("itemCode", {})(
                <StyledInput
                  placeholder="Stock Number"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem style={{ marginTop: "2.1em" }}>
              <StyledSelect
                placeholder="Reason"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"Stuck"}>Stuck</Option>
                <Option value="Break">Break</Option>
                <Option value="Formation">Formation</Option>
                <Option value="Lost in hole">Lost in hole</Option>
                <Option value="Lost in hole">Bend</Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-1em" }}>
          <Col span={11}>
            <FormItem label={<span>Adapter</span>}>
              {this.props.form.getFieldDecorator("itemCode", {})(
                <StyledInput
                  placeholder="Stock Number"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem style={{ marginTop: "2.1em" }}>
              <StyledSelect
                placeholder="Reason"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"Stuck"}>Stuck</Option>
                <Option value="Break">Break</Option>
                <Option value="Formation">Formation</Option>
                <Option value="Lost in hole">Lost in hole</Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: "-1em" }}>
          <Col span={11}>
            <FormItem label={<span>Refurbish</span>}>
              {this.props.form.getFieldDecorator("itemCode", {})(
                <StyledInput
                  placeholder="Stock Number"
                  //value={
                  //  this.props.editStock ? this.props.editStock.itemCode : ""
                  //}
                  style={{ width: "100%", height: 55 }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem style={{ marginTop: "2.1em" }}>
              <StyledSelect
                placeholder="Reason"
                style={{ width: "100%", height: 55 }}
              >
                <Option value={"Stuck"}>Stuck</Option>
                <Option value="Break">Break</Option>
                <Option value="Formation">Formation</Option>
                <Option value="Lost in hole">Lost in hole</Option>
              </StyledSelect>
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle">
          {" "}
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "1px solid #009BDF",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "white",
              color: "#009BDF",
            }}
            type="primary"
            onClick={this.props.onCancel}
            size="large"
            block
          >
            Cancel
          </ButtonRadius>
          <ButtonRadius
            //disabled={!selectedBit}
            style={{
              marginTop: "0em",
              height: 55,
              width: "45%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
              color: "#fff",
            }}
            type="primary"
            onClick={this.props.onOk}
            size="large"
            block
          >
            Save
          </ButtonRadius>
        </Row>
      </Form>
    );
  }
}
const StockFormBit = Form.create()(StockFormClassBit);
const StockFormHammer = Form.create()(StockFormClassHammer);
const StockFormRod = Form.create()(StockFormClassRod);
const StockFormAdapter = Form.create()(StockFormClassAdapter);
const StockFormRefurbish = Form.create()(StockFormClassRefurbish);
const StockFormRigMeters = Form.create()(StockFormClassRigMeters);
const StockFormShiftMeters = Form.create()(StockFormClassShiftMeters);
const StockFormDamages = Form.create()(StockFormClassDamages);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
      visibleHammer: false,
      visibleShiftDamages: false,
      visibleRod: false,
      visibleAdapter: false,
      visibleRefurbish: false,
      visibleRigMeters: false,
      visibleShiftMeters: false,
      visibleBit: false,
    };
  }
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };

  handleCancelBit = e => {
    console.log(e);
    this.setState({
      visibleBit: false,
    });
  };
  showModalBit = () => {
    this.setState({
      visibleBit: true,
    });
  };

  handleOkShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };

  handleCancelShiftMeters = e => {
    console.log(e);
    this.setState({
      visibleShiftMeters: false,
    });
  };
  showModalRigMeters = () => {
    this.setState({
      visibleRigMeters: true,
    });
  };

  handleOkRigMeters = e => {
    console.log(e);
    this.setState({
      visibleRigMeters: false,
    });
  };

  handleCancelRigMeters = e => {
    console.log(e);
    this.setState({
      visibleRigMeters: false,
    });
  };
  showModalRefurbish = () => {
    this.setState({
      visibleRefurbish: true,
    });
  };

  handleOkRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };

  handleCancelRefurbish = e => {
    console.log(e);
    this.setState({
      visibleRefurbish: false,
    });
  };
  showModalAdapter = () => {
    this.setState({
      visibleAdapter: true,
    });
  };

  handleOkAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };

  handleCancelAdapter = e => {
    console.log(e);
    this.setState({
      visibleAdapter: false,
    });
  };
  showModalRod = () => {
    this.setState({
      visibleRod: true,
    });
  };

  handleOkRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };

  handleCancelRod = e => {
    console.log(e);
    this.setState({
      visibleRod: false,
    });
  };
  showModalDamages = () => {
    this.setState({
      visibleDamages: true,
    });
  };

  handleOkDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };

  handleCancelDamages = e => {
    console.log(e);
    this.setState({
      visibleDamages: false,
    });
  };
  showModalHammer = () => {
    this.setState({
      visibleHammer: true,
    });
  };

  handleOkHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  handleCancelHammer = e => {
    console.log(e);
    this.setState({
      visibleHammer: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock(this.props.user.access)
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              "Part: " + stock.itemCode + " - Location: " + stock.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.props
      .findAllBits(this.props.user.access)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllBits(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.addEventListener("backbutton", this.handleBack);
    //}
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.removeEventListener("backbutton", this.handleBack);
    //}
  };
  onRigUpdate = e => {
    this.props.updateRig1();
  };
  onAddStock = e => {
    this.setState({
      addStockVisible: true,
      editStock: null,
      visible: true,
    });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e, visible: true });
  };
  handleAddStock = e => {
    const { selectedBit } = this.state;
    //this.props.updateBits({
    //  // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
    //  // TODO removedId: jy moet die selected bit se id hier sit,
    //  removedMeters: this.state.removedMeters || selectedBit.meter,
    //  removedStatus: selectedBit.status,
    //  replaceId: selectedBit.serial,
    //  replaceSize: this.state.replaceSize || selectedBit.size,
    //  replaceStatus: this.state.newBitStatus || selectedBit.status,
    //  replaceLastUser: this.props.user.name,
    //  replaceSerial: selectedBit.serial,
    //  replaceCurrentRig: this.props.currentRig,
    //});
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .findAllBits(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.props.user.access)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: null,
                  visible: false,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };

  handleUpdateBits = () => {
    const { selectedBit } = this.state;

    this.props
      .updateBits({
        // TODO rigId: jy moet die selected rig se id of naam of watever hom link aan die rig hier sit,
        // TODO removedId: jy moet die selected bit se id hier sit,
        removedMeters: this.state.removedMeters || selectedBit.meter,
        removedStatus: selectedBit.status,
        replaceId: selectedBit.serial,
        replaceSize: this.state.replaceSize || selectedBit.size,
        replaceStatus: this.state.newBitStatus || selectedBit.status,
        replaceLastUser: this.props.user.name,
        replaceSerial: selectedBit.serial,
        replaceCurrentRig: this.props.currentRig,
      })

      .then(suc => {
        this.setState({ loading: false });
        // TODO wys success message en nav uit view uit
        this.props.push("/user/pexunits");
        //console.log(suc);
      })
      .catch(e => {
        this.setState({ loading: false });
        // TODO wys error message
        //console.log(e);
      });
  };

  render() {
    let { selectedBit } = this.state;
    const { stock } = this.props;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              margin: "-20px",
              marginBottom: "0.5em",
              textAlign: "center",
            }}
          >
            <Col xs={24} md={24} style={{ background: "rgb(0, 58, 152)" }}>
              {" "}
              <Link to="/user/pexunits">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "20px",
                    top: "0.6em",
                    left: "0.5em",
                    position: "absolute",
                    color: "white",
                  }}
                />
              </Link>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 26,
                  color: "#FF9D66",
                  marginLeft: 10,
                  fontFamily: "Roboto",
                }}
              >
                Rig
                {this.props.stock.currentRig}
              </span>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1em",
              textAlign: "center",
            }}
          >
            <Col span={24}>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "black",

                  fontFamily: "Poppins",
                }}
              >
                Please Select Tool
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalBit}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Bit
                  </p>
                </StyledCard>
              </Col>
              <Col span={1} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalHammer}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Hammer
                  </p>
                </StyledCard>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalRod}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Rod
                  </p>
                </StyledCard>
              </Col>
              <Col span={1} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalAdapter}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Adapter
                  </p>
                </StyledCard>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={24}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalRefurbish}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Refurbishment
                  </p>
                </StyledCard>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalDamages}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Damages
                  </p>
                </StyledCard>
              </Col>
              <Col span={1} />{" "}
              <Col
                span={11}
                style={{ margin: "auto", marginTop: "0.5em", marginLeft: 0 }}
                onClick={this.showModalRigMeters}
              >
                <StyledCard
                  style={{
                    border: "1px solid #009BDF",
                    borderRadius: "1em",
                    padding: "0em",
                    whiteSpace: "nowrap",
                    backgroundColor: "white",
                    fontFamily: "Poppins",
                    height: "4.5em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#009BDF",
                      marginTop: 5,
                    }}
                  >
                    Rig Meters
                  </p>
                </StyledCard>
              </Col>
            </Row>
          </Spin>
          <Modal
            title={"Delete Rig"}
            visible={this.state.deleteVisible}
            onOk={this.handleDeleteStock}
            onCancel={this.cancelDelete}
            okText="Accept"
            cancelText="Cancel"
          >
            <span>
              Are you sure you want to delete rig{" "}
              {this.props.stock.map(stock => (
                <span>{stock.currentRig}</span>
              ))}
              ?
            </span>
          </Modal>
        </div>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Bit"
          visible={this.state.visibleBit}
          onOk={this.handleOkBit}
          onCancel={this.handleCancelBit}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormBit
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleBit: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleBit: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Hammer"
          visible={this.state.visibleHammer}
          onOk={this.handleOkHammer}
          onCancel={this.handleCancelHammer}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormHammer
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleHammer: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleHammer: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Rod"
          visible={this.state.visibleRod}
          onOk={this.handleOkRod}
          onCancel={this.handleCancelRod}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormRod
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleRod: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleRod: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          title="Adapter"
          visible={this.state.visibleAdapter}
          onOk={this.handleOkRod}
          onCancel={this.handleCancelAdapter}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormAdapter
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleAdapter: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleAdapter: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>{" "}
        <StyledModal
          style={{
            top: 0,
          }}
          title="Refurbish"
          visible={this.state.visibleRefurbish}
          onOk={this.handleOkRefurbish}
          onCancel={this.handleCancelRefurbish}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormRefurbish
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleRefurbish: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleRefurbish: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          title="RigMeters"
          visible={this.state.visibleRigMeters}
          onOk={this.handleOkRigMeters}
          onCancel={this.handleCancelRigMeters}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormRigMeters
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleRigMeters: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleRigMeters: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
        <StyledModal
          style={{
            top: 0,
          }}
          visible={this.state.visibleDamages}
          onOk={this.handleOkDamages}
          onCancel={this.handleCancelDamages}
        >
          <Row
            style={{
              width: "100vw",
              //display: this.state.addStockVisible ? "flex" : "none",
            }}
          >
            <Col style={{}} span={20}>
              <StockFormDamages
                editStock={this.state.editStock}
                bits={this.props.bits}
                wrappedComponentRef={form => (this.stockForm = form)}
                onOk={e =>
                  this.setState({
                    visibleDamages: false,
                  })
                }
                onCancel={e =>
                  this.setState({
                    visibleDamages: false,
                  })
                }
                okText={this.state.editStock ? "Save" : "Add Rig"}
              />
            </Col>
          </Row>
        </StyledModal>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.status,
});

const mapDispatchToProps = {
  updateRig1,
  updateRig2,
  updateRig3,
  updateRig4,
  updateRig5,
  updateRig6,
  updateRig7,
  updateRig8,
  updateRig9,
  updateRig10,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stock);
