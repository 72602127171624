import React from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  TableOutlined,
  DeleteFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Typography,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const { Text } = Typography;
const numeral = require("numeral");
const { TabPane } = Tabs;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledDatePicker = styled(RangePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-imquantity: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const dataSource = [
  {
    key: "1",
    stockNumber: "120-221",
    name: "171 Bit",
    quantity: 110,
    meters: 110,
    price: "R120.00",
    totals: "R13 400.00",
  },
  {
    key: "2",
    stockNumber: "220-322",
    name: "New Hammer 6”",
    quantity: 3,
    meters: 110,
    price: "R999.99",
    totals: "R3899.98",
  },
  {
    key: "3",
    stockNumber: "220-31",
    name: "New Hammer 5”",
    quantity: 90,
    meters: 110,
    price: "R400.00",
    totals: "R36 000.00",
  },
  {
    key: "4",
    stockNumber: "120-221",
    name: "DK Rod”",
    quantity: 30,
    meters: 110,
    price: "R80.00",
    totals: "R2400.00",
  },
  {
    key: "5",
    stockNumber: "120202",
    name: "L8 Rod",
    quantity: 100,
    meters: 110,
    price: "R120.00",
    totals: "R12 000.00",
  },
];
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const EditableContext = React.createContext();
const columns = [
  {
    title: "Stock nr.",
    dataIndex: "serial",
    key: "serial",
    width: "90%",
  },
  {
    title: "Tool",
    dataIndex: "description",
    key: "description",
    width: "90%",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 200,
  },
  {
    title: "Meters",
    dataIndex: "meters",
    key: "meters",
    width: 200,
  },
  {
    title: "Price",
    dataIndex: "price",
    width: 200,
    key: "price",
  },
  {
    title: "Total",
    dataIndex: "totals",
    width: 200,
    key: "totals",
  },
];
const data = []; // rowSelection objects indicates the need for row selection

for (let i = 50; i < 81; i++) {
  data.push({
    key: i,
    name: `Tool ${i}`,
    quantity: 32,
    price: <span> R {i}.00</span>,
    totals: <span> R {i}.00</span>,
  });
}
const fixedColumns = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: true,
    width: 100,
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  addDescription = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, datasource } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            {
              validator: this.props.validateQty
                ? (a, b, c) =>
                  this.props.validateQty(a, b, c, this.props.record)
                : null,
              message: `There is not enough stock to satisfy this quantity`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <AutoComplete
            dataSource={datasource}
            placeholder={title + "..."}
            ref={node => (this.input = node)}
            //onPressEnter={this.save}
            onBlur={this.save}
            onSelect={this.addDescription}
            filterOption={(inputValue, option) =>
              inputValue.length > 2 &&
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {!children[2] && !record.addButton ? (
          <span style={{ opacity: 0.7 }}>
            {dataIndex === "itemCode"
              ? "Type 3 chars to search"
              : "Click to edit..."}
          </span>
        ) : (
          children
        )}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class RequisitionFormClass extends React.Component {
  editRequisition;
  state = {
    parts: [],
  };
  componentWillMount = () => {
    if (this.props.editRequisition) {
      this.editRequisition = JSON.parse(
        JSON.stringify(this.props.editRequisition)
      );
      let parts = this.editRequisition.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>,
      });
      this.setState({ parts: parts });
    } else {
      let parts = this.state.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>,
      });
      this.setState({ parts: parts });
    }
  };
  componentWillUnmount = () => {
    this.editRequisition = undefined;
  };
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          itemCode: result.text,
        });
      },
      error => {
        alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: "Place a barcode inside the scan area",
      }
    );
  };
  addPart = () => {
    let parts = this.state.parts;
    parts.splice(parts.length - 1, 0, {
      key: parts.length - 1,
      itemCode: undefined,
      qty: undefined,
      description: undefined,
    });
    this.setState({ parts: parts });
  };
  deletePart = part => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => part.key === item.key);
    parts.splice(index, 1);
    this.setState({ parts: parts });
  };
  handleSave = row => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => row.key === item.key);
    const item = parts[index];
    const codeCheck = row.itemCode.split("Part:")[1]
      ? row.itemCode
        .split("Part:")[1]
        .split("Location:")[0]
        .substring(1)
        .slice(0, -3)
      : row.itemCode;
    const stockIndex = this.props.stock.findIndex(
      stock => stock.itemCode === codeCheck
    );
    if (stockIndex > -1) {
      row.description = this.props.stock[stockIndex].description;
      row.location = this.props.stock[stockIndex].location;
      row.itemCode = this.props.stock[stockIndex].itemCode;
    }
    parts.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ parts: parts });
  };
  validateQty = (rule, value, callback, record) => {
    const stockIndex = this.props.stock.findIndex(
      stock => stock.itemCode === record.itemCode
    );
    if (stockIndex > -1) {
      if (parseInt(this.props.stock[stockIndex].qty) >= parseInt(value)) {
        callback();
      } else {
        callback(false);
      }
    } else {
      callback(false);
    }
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    return (
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <FormItem label={<span>Requisition Number</span>}>
          {this.props.form.getFieldDecorator("requisitionNumber", {
            // rules: [{ required: true, message: 'Description required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.requisitionNumber
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Job</span>}>
          {this.props.form.getFieldDecorator("jobNumber", {
            // rules: [{ required: true, message: 'Job required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.jobNumber
              : undefined,
          })(
            <Select
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              showSearch
              style={{ width: "100%" }}
              onSearch={this.props.handleSearchJob}
              placeholder={"Type in at least 4 characters to start search"}
            >
              {this.props.jobOptions}
            </Select>
          )}
        </FormItem>

        <FormItem label={<span>Technician</span>}>
          {this.props.form.getFieldDecorator("userName", {
            // rules: [{ required: true, message: 'Job required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.userName
              : undefined,
          })(
            <Select
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              showSearch
              style={{ width: "100%" }}
              onSearch={this.props.handleSearchTechnician}
              placeholder={"Type in at least 4 characters to start search"}
            >
              {this.props.technicianOptions}
            </Select>
          )}
        </FormItem>
        <FormItem label={<span>Parts List</span>}>
          <Table
            style={{ background: "white" }}
            components={components}
            size="middle"
            pagination={{
              defaultPageSize: 10,
            }}
            dataSource={this.state.parts}
          >
            <Column
              title="No"
              dataIndex="key"
              key="key"
              render={(text, record, index) => {
                if (!record.addButton) {
                  return <span>{index + 1}</span>;
                }
              }}
            />
            <Column
              title="Part Number"
              dataIndex="itemCode"
              key="itemCode"
              onCell={record => ({
                record,
                datasource: this.props.itemCodes,
                editable: true,
                dataIndex: "itemCode",
                title: "Part Number",
                handleSave: this.handleSave,
              })}
            />
            <Column
              title="Description"
              dataIndex="description"
              key="description"
              onCell={record => ({
                record,
                editable: true,
                dataIndex: "description",
                title: "Description",
                handleSave: this.handleSave,
              })}
            />
            <Column title="Location" dataIndex="location" key="location" />
            <Column
              title="Quantity"
              dataIndex="qty"
              key="qty"
              onCell={record => ({
                record,
                editable: true,
                dataIndex: "qty",
                title: "Quantity",
                record: record,
                validateQty: this.validateQty,
                handleSave: this.handleSave,
              })}
            />
            <Column
              title="Meters"
              dataIndex="meters"
              key="meters"
              onCell={record => ({
                record,
                editable: true,
                dataIndex: "meters",
                title: "Meters",
                record: record,
                validateQty: this.validateQty,
                handleSave: this.handleSave,
              })}
            />
            <Column
              title="Actions"
              key="actions"
              render={(text, requisition) => {
                if (!requisition.addButton) {
                  return (
                    <span>
                      <a onClick={e => this.deletePart(requisition)}>Delete</a>
                    </span>
                  );
                } else {
                  return requisition.addButton;
                }
              }}
            />
          </Table>
        </FormItem>
        <Row type="flex" justify="space-around" align="middle">
          <Button type="primary" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type="primary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>
      </Form>
    );
  }
}
const RequisitionForm = Form.create()(RequisitionFormClass);
class StockFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      replaceStatus: null,
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
    };
  }
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          itemCode: result.text,
        });
      },
      error => {
        alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: "Place a barcode inside the scan area",
      }
    );
  };

  render() {
    let { selectedBit } = this.state;
    const bitStatus = {
      damaged: <StyledBadge color="#EE4C4C" text="Damaged" />,
      Used: <StyledBadge color="#EDAC4B" text="Used" />,
      new: <StyledBadge color="#4BED5B" text="New" />,
      lost: <StyledBadge color="#D4D4D4" text="Lost" />,
      used: <StyledBadge color="#EE4C4C" text="Used" />,
      out: <StyledBadge color="#FF8F28" text="Out" />,
    };
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <FormItem label={<span>Rig Number</span>}>
            {this.props.form.getFieldDecorator("currentRig", {
              rules: [
                {
                  required: true,
                  message: "Rig Number required",
                },
              ],
              initialValue: this.props.editStock
                ? this.props.editStock.currentRig
                : "",
            })(
              <Input
                disabled
              //type={"number"}
              />
            )}
          </FormItem>
          <FormItem label={<span>Tool Serial</span>}>
            {this.props.form.getFieldDecorator("itemCode", {
              rules: [{ required: true, message: "Part number required" }],
              initialValue: this.props.editStock
                ? this.props.editStock.itemCode
                : "",
            })(
              <Input
                disabled
                suffix={
                  window.cordova ? (
                    <ScanOutlined onClick={this.barcodeScanner} />
                  ) : null
                }
              />
            )}
          </FormItem>
          <FormItem label={<span>Tool Type</span>}>
            {this.props.form.getFieldDecorator("toolType", {
              initialValue: this.props.editStock
                ? this.props.editStock.toolType
                : "",
            })(<Input disabled />)}
          </FormItem>
          <FormItem label={<span>Tool Description</span>}>
            {this.props.form.getFieldDecorator("itemDescription", {
              rules: [{ required: true, message: "Description required" }],
              initialValue: this.props.editStock
                ? this.props.editStock.itemDescription
                : "",
            })(<Input disabled />)}
          </FormItem>
          <FormItem label={<span>Tool Status</span>}>
            {this.props.form.getFieldDecorator("Status", {
              rules: [{ required: false, message: "Serial required" }],
              initialValue: this.props.editStock
                ? this.props.editStock.Status
                : "",
            })(
              <Select
                disabled
                placeholder="Status"
                onChange={newBitStatus => this.setState({ newBitStatus })}
              >
                <Option value="Damaged">
                  <StyledBadge color="#EE4C4C" text="Damaged" />
                </Option>
                <Option value="Used">
                  <StyledBadge color="#EDAC4B" text="Used" />
                </Option>
                <Option value="New">
                  <StyledBadge color="#4BED5B" text="New" />
                </Option>
                <Option value="Lost">
                  <StyledBadge color="#D4D4D4" text="Lost" />
                </Option>
              </Select>
            )}
          </FormItem>
          <FormItem label={<span>Meters</span>}>
            {this.props.form.getFieldDecorator("removedMeters", {
              rules: [{ required: false, message: "Serial required" }],
              initialValue: this.props.editStock
                ? this.props.editStock.removedMeters
                : "",
            })(
              <Input
                disabled
                placeholder="Meters"
                onChange={e => this.setState({ removedMeters: e.target.value })}
              />
            )}
          </FormItem>
          {/*<FormItem>
          {this.props.form.getFieldDecorator("itemCode", {
            rules: [{ required: true, message: "Serial required" }],
          })(
            <Select
              placeholder='Serial'
              onChange={(bitSerial) => {
                const selectedBit = this.props.bits.find(
                  (bit) => bit.serial === bitSerial
                );
                this.setState({ selectedBit });
              }}
            >
              {this.props.bits.map((bitserial) => {
                return (
                  <Option value={bitserial.serial}>{bitserial.serial}</Option>
                );
              })}{" "}
              <Option value='test'>test</Option>
            </Select>
          )}
        </FormItem>
        <FormItem>
          {this.props.form.getFieldDecorator("toolType", {
            rules: [{ required: true, message: "Type required" }],
            initialValue:
              selectedBit && selectedBit.size ? selectedBit.size : "No Size",
          })(
            <Select
              disabled
              //hidden={!selectedBit ? true : false}

              onChange={(e) => this.setState({ replaceSize: e })}
            >
              {this.props.bits.map((bitsize) => {
                return <Option value={bitsize.size}>{bitsize.size}</Option>;
              })}
            </Select>
          )}
        </FormItem>
        <FormItem>
          {this.props.form.getFieldDecorator("itemStatus", {
            rules: [{ required: true, message: "Status required" }],
            initialValue:
              selectedBit && selectedBit.status
                ? selectedBit.status
                : "No Status",
          })(
            <Select
              //hidden={!selectedBit ? true : false}
              disabled
              onChange={(e) => this.setState({ replaceStatus: e })}
            >
              <Option value='Damaged'>
                <StyledBadge color='#EE4C4C' text='Damaged' />
              </Option>
              <Option value='Used'>
                <StyledBadge color='#EDAC4B' text='Used' />
              </Option>
              <Option value='New'>
                <StyledBadge color='#4BED5B' text='New' />
              </Option>
              <Option value='Lost'>
                <StyledBadge color='#D4D4D4' text='Lost' />
              </Option>
            </Select>
          )}
        </FormItem>{" "}
        <FormItem>
          {this.props.form.getFieldDecorator("itemDescription", {
            rules: [{ required: true, message: "Description required" }],
            initialValue:
              selectedBit && selectedBit.description
                ? selectedBit.description
                : "No Description",
          })(
            <Select
              disabled
              //hidden={!selectedBit ? true : false}

              onChange={(e) => this.setState({ replaceDesc: e })}
            >
              {this.props.bits.map((bitdesc) => {
                return (
                  <Option value={bitdesc.description}>
                    {bitdesc.description}
                  </Option>
                );
              })}
            </Select>
          )}
        </FormItem>*/}
          <Row>
            {/*<Button
              type='primary'
              shape='round'
              onClick={this.props.onOk}
              size='large'
              style={{
                float: "right",
                fontSize: "14px",
                width: "7em",
                height: "3em",
                //height: "3em",
                marginLeft: "0.7em",
              }}
            >
              Save
            </Button>*/}
            <Button
              shape="round"
              onClick={this.props.onCancel}
              style={{
                fontSize: "14px",
                width: "7em",
                height: "3em",

                border: "1px solid #EEEFF0",
                backgroundColor: "#EEEFF0",
                float: "right",
              }}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}
const StockForm = Form.create()(StockFormClass);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      qtyError: false,
      itemCodes: [],
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      siteSelected: this.props.sites.length ? this.props.sites[0]._id : "None",
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock()
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              "Part: " + stock.itemCode + " - Location: " + stock.location
            );
          }
        });
        this.props
          .findAllBits()
          .then(() => {
            this.props
              .findAllSites()
              .then(() => {
                this.setState({
                  loading: false,
                  itemCodes: itemCodes,
                  siteSelected: this.props.sites[0]._id,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addStockVisible) {
      this.setState({ addStockVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  onAddStock = e => {
    this.setState({ addStockVisible: true, editStock: undefined });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e });
  };
  onEditStockApprove = stock => {
    this.setState({ loading: true });
    if (stock.status === "In Request") {
      stock.qty = parseInt(stock.qty) + 1;
    } else if (stock.status === "Out Request") {
      stock.qty = parseInt(stock.qty) - 1;
    }
    stock.status = "None";
    this.props
      .addStock(stock)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            this.props
              .sendNotification(
                stock.requestId,
                [stock.requestId],
                "Admin has approved one of your requests",
                "adminChanged",
                stock._id,
                false,
                "Asambo Admin"
              )
              .then(() => {
                this.setState({ loading: false });
                Notify("success", "Successfully approved request");
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  onEditStockOutVisible = stock => {
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    this.setState({
      stockOut: stock,
      outVisible: true,
    });
  };
  onEditStockOut = () => {
    let stock = this.state.stockOut;
    if (this.state.qtyError === true || this.state.qty === 0) {
      return;
    }
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    const id = stock._id;
    let request = {
      qty: this.state.qty,
      jobNumber: this.state.jobNumber,
      stockId: id,
      type: "Out",
      itemCode: stock.itemCode,
      description: stock.description,
      userName: this.state.userName,
      requisitionNumber: this.state.requisitionNumber,
      approved: true,
    };
    this.setState({ loading: true, outVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ itemCodes: itemCodes });
            const { vfs } = vfsFonts.pdfMake;
            pdfMake.vfs = vfs;
            let data = Object.assign({}, request);
            prepDefinition(data).then(definition => {
              pdfMake
                .createPdf(definition)
                .download(
                  stock.itemCode +
                  " " +
                  moment().format("YYYY-MM-DD") +
                  " Approved Request.pdf",
                  () => {
                    this.setState({ loading: false, qty: 1, qtyError: false });
                    Notify("success", "Successfully submitted");
                  }
                );
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleEditBatchStockOut = e => {
    e.preventDefault();
    this.requisitionForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        for (var i = 0; i < this.requisitionForm.state.parts.length; i++) {
          if (!this.requisitionForm.state.parts[i].addButton) {
            if (!this.requisitionForm.state.parts[i].qty) {
              return;
            }
          }
        }
        this.setState({ loading: true });
        if (this.state.editRequisition) {
          values._id = this.state.editRequisition._id;
        }
        values.parts = this.requisitionForm.state.parts;
        values.type = "Out";
        values.approved = true;
        this.props
          .addBatchRequest(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                const { vfs } = vfsFonts.pdfMake;
                pdfMake.vfs = vfs;
                let data = Object.assign({}, values);
                prepDefinition2(data).then(definition => {
                  pdfMake
                    .createPdf(definition)
                    .download(
                      values.requisitionNumber +
                      " " +
                      moment().format("YYYY-MM-DD") +
                      " Approved Request.pdf",
                      () => {
                        this.setState({
                          loading: false,
                          addBatchVisible: false,
                        });
                        Notify("success", "Successfully submitted");
                      }
                    );
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  handleSearchJob = e => {
    if (e.length > 3) {
      this.props
        .findJobNumbers(e)
        .then(suc => {
          this.setState({
            jobNumbers: suc.jobNumbers,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        jobNumbers: [],
      });
    }
  };
  handleSearchTechnician = e => {
    if (e.length > 3) {
      this.props
        .findTechnicians(e)
        .then(suc => {
          this.setState({
            technicians: suc.technicians,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        technicians: [],
      });
    }
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== "" && setQty !== null) {
      if (this.state.stockOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true,
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false,
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false,
      });
    }
  };
  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  render() {
    const { stock } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + " - " + d.username.split("|")[1]}
      </Option>
    ));
    let unBits = [];
    this.props.bits.forEach(bit => {
      if (bit.access.includes(this.state.siteSelected)) {
        unBits.push(bit);
      }
    });
    let bits = [];
    let unProcessedBits = JSON.parse(JSON.stringify(unBits));
    unProcessedBits.forEach(bit => {
      if (bit.cost) {
        bit.cost.forEach(cost => {
          if (
            moment(cost.date).isBetween(
              this.state.startDate,
              this.state.endDate,
              moment.startOf,
              "[]"
            )
          ) {
            bit.totalQuantity =
              bit.totalQuantity && bit.totalQuantity !== "None"
                ? parseInt(bit.totalQuantity) + 1
                : 1;
            bit.totalMeters = bit.totalMeters
              ? parseInt(bit.totalMeters) + parseInt(cost.meters)
              : parseInt(cost.meters);
            if (bits.findIndex(bit2 => bit2._id === bit._id) > -1) {
              bits[bits.findIndex(bit2 => bit2._id === bit._id)] = bit;
            } else {
              bits.push(bit);
            }
          }
        });
      }
    });
    let totalMeters = 0;
    let totalAmount = 0;
    bits.forEach(bit => {
      bit.total = (bit.totalQuantity * parseFloat(bit.price)) / bit.totalMeters;
      totalAmount = totalAmount + bit.total;
      totalMeters = totalMeters + bit.totalMeters;
    });
    let totalRandPerMeter = totalAmount / totalMeters;
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: "center", maxWidth: "90em", margin: "auto" }}
        >
          <Col xs={24} md={12} style={{ textAlign: "left" }}>
            <span style={{ fontWeight: 750, fontSize: 30 }}>
              Costs -
              {/* <span style={{ color: '#FF9D66', fontWeight: 750, fontSize: 30 }}>
                {moment().format('DD/MM/YY')}
              </span> */}
            </span>{" "}
            <StyledSelect
              value={this.state.siteSelected}
              onChange={e => {
                this.setState({ siteSelected: e });
              }}
              defaultValue={this.state.siteSelected}
              style={{ height: 55, marginLeft: "1em" }}
            >
              {this.props.sites.map(site => (
                <Option value={site._id}>{site.name}</Option>
              ))}
            </StyledSelect>
            <br />
            <br />
            <StyledDatePicker
              onChange={e => {
                this.setState({
                  startDate: e[0],
                  endDate: e[1],
                });
              }}
              value={[this.state.startDate, this.state.endDate]}
              format={dateFormat}
            />
          </Col>
        </Row>

        <div style={{ maxWidth: "90em", margin: "auto" }}>
          <Spin spinning={this.state.loading}>
            {/* {this.state.addStockVisible ? ( */}
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display: this.state.addStockVisible ? "flex" : "none",
              }}
              type="flex"
              justify="space-around"
              align="middle"
            >
              <Col style={{ padding: 20 }} span={20}>
                <StockForm
                  editStock={this.state.editStock}
                  wrappedComponentRef={form => (this.stockForm = form)}
                  onOk={this.handleAddStock}
                  onCancel={e => this.setState({ addStockVisible: false })}
                  okText={this.state.editStock ? "Save" : "Add Stock"}
                />
              </Col>
            </Row>
            {/* ) : this.state.addBatchVisible ? ( */}
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display: this.state.addBatchVisible ? "flex" : "none",
              }}
              type="flex"
              justify="space-around"
              align="middle"
            >
              <Col style={{ padding: 20 }} span={20}>
                <RequisitionForm
                  handleSearchJob={this.handleSearchJob}
                  handleSearchTechnician={this.handleSearchTechnician}
                  jobOptions={jobOptions}
                  technicianOptions={technicianOptions}
                  itemCodes={this.state.itemCodes}
                  stock={this.props.stock}
                  editRequisition={this.state.editRequisition}
                  wrappedComponentRef={form => (this.requisitionForm = form)}
                  onOk={this.handleEditBatchStockOut}
                  onCancel={e => this.setState({ addBatchVisible: false })}
                  okText={
                    this.state.editRequisition
                      ? "Save"
                      : "Submit Batch Stock Out"
                  }
                />
              </Col>
            </Row>
            {/* ) : ( */}
            <Row
              style={{
                background: "white",
                borderRadius: 5,
                marginTop: 20,
                display:
                  !this.state.addStockVisible && !this.state.addBatchVisible
                    ? "flex"
                    : "none",
              }}
              type="flex"
              justify="start"
              align="middle"
            >
              <Col span={24}>
                <StyledTable
                  dataSource={bits}
                  scroll={{
                    // x: 1500,
                    y: 600,
                  }}
                  pagination={{
                    defaultPageSize: 7,
                  }}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row
                        style={{
                          backgroundColor: "#F0F3F9",
                          color: "#525F7F",
                          fontWeight: 500,
                          fontSize: "15px",
                        }}
                      >
                        <Table.Summary.Cell index={0} colSpan={5}>
                          Total Meters
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={1}>
                          {totalMeters ? totalMeters : 0}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row
                        style={{
                          backgroundColor: "#F0F3F9",
                          color: "#525F7F",
                          fontWeight: 500,
                          fontSize: "15px",
                        }}
                      >
                        <Table.Summary.Cell index={2} colSpan={5}>
                          Total Cost per Meter
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} colSpan={1}>
                          R{" "}
                          {totalRandPerMeter ? totalRandPerMeter.toFixed(2) : 0}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row
                        style={{
                          backgroundColor: "#F0F3F9",
                          color: "#525F7F",
                          fontWeight: 500,
                          fontSize: "15px",
                        }}
                      >
                        <Table.Summary.Cell index={4} colSpan={5}>
                          Invoice amount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} colSpan={1}>
                          R {totalAmount.toFixed(2)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                >
                  <Column
                    title="Stock nr."
                    dataIndex="serial"
                    key="serial"
                    width={"90%"}
                  />
                  <Column
                    title="Tool"
                    dataIndex="description"
                    key="description"
                    width={"90%"}
                  />
                  <Column
                    title="Quantity"
                    dataIndex="totalQuantity"
                    key="totalQuantity"
                    width={200}
                  />
                  <Column
                    title="Meters"
                    dataIndex="totalMeters"
                    key="totalMeters"
                    width={200}
                  />
                  <Column
                    title="Price"
                    dataIndex="price"
                    key="price"
                    width={200}
                    render={text => {
                      return <span>R {text.toFixed(2)}</span>;
                    }}
                  />
                  <Column
                    title="Total"
                    dataIndex="total"
                    key="total"
                    width={200}
                    render={text => {
                      return <span>R {text.toFixed(2)}</span>;
                    }}
                  />
                </StyledTable>
                {/* <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    defaultPageSize: 25
                  }}
                  expandedRowRender={
                    window.innerWidth <= 420
                      ? record => (
                        <Card
                          size="small"
                          title={record.itemCode}
                          style={{ width: 300, marginLeft: -35 }}
                        >
                          <p>Brand: {record.brand}</p>
                          <p>Description: {record.description}</p>
                          <p>Quantity: {record.qty}</p>
                          <p>Location: {record.location}</p>
                          <p>Status: {record.status}</p>
                        </Card>
                      )
                      : null
                  }
                  dataSource={this.props.stock}
                  onChange={this.handleSort}
                  onRow={record => {
                    if (parseInt(record.qty) === 0) {
                      return { style: { background: '#ffd3d3' } };
                    }
                  }}
                >
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('itemCode')}
                      title="Rig Number"
                      dataIndex="currentRig"
                      key="currentRig"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('currentRig')}
                      title="Current Tool"
                      dataIndex="itemCode"
                      key="itemCode"
                    />
                  ) : null}
                  <Column
                    {...this.getColumnSearchProps('toolType')}
                    title="Tool Type"
                    dataIndex="toolType"
                    key="dtoolType"
                  />
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('itemDescription')}
                      title="Description"
                      dataIndex="itemDescription"
                      key="itemDescription"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('removedMeters')}
                      title="Meters"
                      dataIndex="removedMeters"
                      key="removedMeters"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      //{...this.getColumnSearchProps("status")}
                      title="Tool Status"
                      dataIndex="status"
                      key="status"
                      render={status => {
                        switch (status) {
                          case 'New':
                            return this.renderStatus('New', '#4BED5B');
                          case 'Used':
                            return this.renderStatus('Used', '#EE4C4C');
                          case 'Out':
                            return this.renderStatus('Out', '#FF8F28');
                          case 'Sharp':
                            return this.renderStatus('Sharp', '#EDAC4B');
                          case 'Lost':
                            return this.renderStatus('Lost', '#D4D4D4');
                          case 'Damaged':
                            return this.renderStatus('Damaged', '#EE4C4C');
                          default:
                            return this.renderStatus('No Status', 'lightgrey');
                        }
                      }}
                    />
                  ) : null}

                  <Column
                    {...this.getColumnSearchProps("actions")}
                    title="Actions"
                    render={(text, stock) => (
                      <span>
                        <Button
                          style={{
                            backgroundColor: '#009BDF',
                            color: 'white',
                            borderRadius: '3em'
                          }}
                          onClick={e => this.onEditStock(stock)}
                        >
                          View Rig
                        </Button>
                        <Divider type="vertical" />
                        <DeleteFilled
                          style={{
                            backgroundColor: '#CFD5E3',
                            color: 'white',
                            padding: '0.5em',
                            borderRadius: '3em'
                          }}
                          onClick={e => {
                            this.setState({
                              deleteVisible: true,
                              deleteStock: stock
                            });
                          }}
                        />
                      </span>
                    )}
                  />
                </StyledTable> */}
              </Col>
            </Row>
            {/* )} */}
          </Spin>
        </div>
        {/* <Modal
          title="Add Stock"
          visible={this.state.addStockVisible}
          onOk={this.handleAddStock}
          onCancel={e => this.setState({ addStockVisible: false })}
          okText={this.state.editStock ? 'Save' : 'Add Stock'}
          cancelText="Cancel"
        >
          <StockForm
            editStock={this.state.editStock}
            wrappedComponentRef={form => (this.stockForm = form)}
          />
        </Modal> */}
        <Modal
          title={"Delete stock"}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteStock}
          onCancel={this.cancelDelete}
          okText="Accept"
          cancelText="Cancel"
        >
          <span>
            {" "}
            Are you sure you want to delete rig{" "}
            {this.props.stock.map(stock => (
              <span>{stock.currentRig}</span>
            ))}
          </span>
        </Modal>
        <Modal
          title={"Stock Out"}
          visible={this.state.outVisible}
          onOk={this.onEditStockOut}
          onCancel={e =>
            this.setState({ outVisible: false, qty: 1, qtyError: false })
          }
          okText="Submit"
          cancelText="Cancel"
        >
          <span>Qty</span>
          <InputNumber
            min={1}
            style={{ marginBottom: 15, width: "100%" }}
            onChange={this.checkQty}
            value={this.state.qty}
          />
          {this.state.qtyError ? (
            <p style={{ color: "red", width: "100%" }}>
              There is not enough stock to satisfy this quantity
            </p>
          ) : null}
          <span>Requisition Number</span>
          <Input
            style={{ marginBottom: 15, width: "100%" }}
            value={this.state.requisitionNumber}
            onChange={e => this.setState({ requisitionNumber: e.target.value })}
          />
          <span>Assign stock to technician</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: "100%", marginBottom: 14 }}
            onSearch={this.handleSearchTechnician}
            value={this.state.userName}
            placeholder={"Type in at least 4 characters to start search"}
            onChange={e => this.setState({ userName: e })}
          >
            {technicianOptions}
          </Select>
          <span>Assign stock to job</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: "100%" }}
            onSearch={this.handleSearchJob}
            value={this.state.jobNumber}
            placeholder={"Type in at least 4 characters to start search"}
            onChange={e => this.setState({ jobNumber: e })}
          >
            {jobOptions}
          </Select>
        </Modal>
      </div>
    );
  }
}

export default Stock;
