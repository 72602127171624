import { getToken } from "src/redux/selectors";

export function signUpUser(values) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/registeruser",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      )
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          res.text();
        })
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            return resolve({ message: "Successfully registered account" });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function signUpManager(values) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/registermanager",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      )
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          res.text();
        })
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            return resolve({
              message: "Successfully registered account",
            });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function signIn(values, type) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      // Validation checks
      if (!values.username) return reject({ message: "Email required" });
      if (!values.password) return reject({ message: "Password required" });
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/signin",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ ...values, type: type }),
        }
      )
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          return resolve(
            dispatch({
              type: "USER_LOGIN",
              data: await res.json(),
            })
          );
        })
        .catch(async error => {
          return reject(error);
        });
    }).catch(async err => {
      throw err.message;
    });
}

export function signInGoogle(token, type) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/signingoogle",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ token: token, type: type }),
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "USER_LOGIN",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(async err => {
      throw err.message;
    });
}

export function signInFacebook(name, username, type) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/signinfacebook",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ name: name, username: username, type: type }),
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "USER_LOGIN",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(async err => {
      throw err.message;
    });
}

export function saveAccountInfo(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/updateuser",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      )
        .then(res => res.text())
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "USER_UPDATE",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
export function changePassword(values, token) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/user/changepassword",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: token ? "Bearer " + token : "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      )
        .then(res => res.text())
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "USER_LOGIN",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
