import React, { useState } from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

import { withRouter, Link } from "react-router-dom";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
  Tag,
  Space,
} from "antd";
import moment from "moment";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { ButtonRadius } from "src/components/elements/Button";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import SignatureCanvas from "react-signature-canvas";
import Pexunit from "./PexUnits";
import { updateRig1, updateRig2 } from "src/redux/actions/status";
import { connect } from "react-redux";

function onChange(date, dateString) {
  console.log(date, dateString);
}
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const numeral = require("numeral");
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

function handleChange(value) {
  console.log(`selected ${value}`);
}
const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 55px !important;
  border-radius: 0.5em !important;
  padding-top: 0px !important;
  .ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.45) !important;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  border-radius: 0.5em !important;
  ::placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.8em !important;
    width: 0.8em !important;
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
`;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
const Signature = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Link to="/user/pexunits" style={{ width: "100%" }}>
        <ButtonRadius
          style={{
            marginTop: "0em",
            height: 55,
            width: "100%",
            fontSize: 14,
            border: "none",
            fontFamily: "Open Sans",
            textShadow: "none",
            backgroundColor: "#009BDF",
          }}
          type="primary"
          //onClick={showModal}
          size="large"
          block
        >
          Update
        </ButtonRadius>
      </Link>
      <StyledModal
        title={<p>Add Signature</p>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <SignatureCanvas
          penColor="rgb(236, 129, 68)"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <Link to="/user/pexunits" style={{ width: "100%" }}>
          <ButtonRadius
            style={{
              marginTop: "0em",
              height: 55,
              width: "100%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#009BDF",
            }}
            type="primary"
            onClick={showModal}
            size="large"
            block
          >
            Submit Signature
          </ButtonRadius>
        </Link>
      </StyledModal>
    </>
  );
};

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  addDescription = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, datasource } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            {
              validator: this.props.validateQty
                ? (a, b, c) =>
                  this.props.validateQty(a, b, c, this.props.record)
                : null,
              message: `There is not enough stock to satisfy this quantity`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <AutoComplete
            dataSource={datasource}
            placeholder={title + "..."}
            ref={node => (this.input = node)}
            //onPressEnter={this.save}
            onBlur={this.save}
            onSelect={this.addDescription}
            filterOption={(inputValue, option) =>
              inputValue.length > 2 &&
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {!children[2] && !record.addButton ? (
          <span style={{ opacity: 0.7 }}>
            {dataIndex === "itemCode"
              ? "Type 3 chars to search"
              : "Click to edit..."}
          </span>
        ) : (
          children
        )}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class RequisitionFormClass extends React.Component {
  editRequisition;
  state = {
    parts: [],
  };
  componentWillMount = () => {
    if (this.props.editRequisition) {
      this.editRequisition = JSON.parse(
        JSON.stringify(this.props.editRequisition)
      );
      let parts = this.editRequisition.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>,
      });
      this.setState({ parts: parts });
    } else {
      let parts = this.state.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>,
      });
      this.setState({ parts: parts });
    }
  };
  componentWillUnmount = () => {
    this.editRequisition = undefined;
  };
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          itemCode: result.text,
        });
      },
      error => {
        alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: "Place a barcode inside the scan area",
      }
    );
  };
  addPart = () => {
    let parts = this.state.parts;
    parts.splice(parts.length - 1, 0, {
      key: parts.length - 1,
      itemCode: undefined,
      qty: undefined,
      description: undefined,
    });
    this.setState({ parts: parts });
  };
  deletePart = part => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => part.key === item.key);
    parts.splice(index, 1);
    this.setState({ parts: parts });
  };
  handleSave = row => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => row.key === item.key);
    const item = parts[index];
    const codeCheck = row.itemCode.split("Part:")[1]
      ? row.itemCode
        .split("Part:")[1]
        .split("Location:")[0]
        .substring(1)
        .slice(0, -3)
      : row.itemCode;
    const stockIndex = this.props.stock.findIndex(
      stock => stock.itemCode === codeCheck
    );
    if (stockIndex > -1) {
      row.description = this.props.stock[stockIndex].description;
      row.location = this.props.stock[stockIndex].location;
      row.itemCode = this.props.stock[stockIndex].itemCode;
    }
    parts.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ parts: parts });
  };
  validateQty = (rule, value, callback, record) => {
    const stockIndex = this.props.stock.findIndex(
      stock => stock.itemCode === record.itemCode
    );
    if (stockIndex > -1) {
      if (parseInt(this.props.stock[stockIndex].qty) >= parseInt(value)) {
        callback();
      } else {
        callback(false);
      }
    } else {
      callback(false);
    }
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    return (
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <FormItem label={<span>Requisition Number</span>}>
          {this.props.form.getFieldDecorator("requisitionNumber", {
            // rules: [{ required: true, message: 'Description required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.requisitionNumber
              : "",
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Job</span>}>
          {this.props.form.getFieldDecorator("jobNumber", {
            // rules: [{ required: true, message: 'Job required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.jobNumber
              : undefined,
          })(
            <Select
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              showSearch
              style={{ width: "100%" }}
              onSearch={this.props.handleSearchJob}
              placeholder={"Type in at least 4 characters to start search"}
            >
              {this.props.jobOptions}
            </Select>
          )}
        </FormItem>

        <FormItem label={<span>Technician</span>}>
          {this.props.form.getFieldDecorator("userName", {
            // rules: [{ required: true, message: 'Job required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.userName
              : undefined,
          })(
            <Select
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              showSearch
              style={{ width: "100%" }}
              onSearch={this.props.handleSearchTechnician}
              placeholder={"Type in at least 4 characters to start search"}
            >
              {this.props.technicianOptions}
            </Select>
          )}
        </FormItem>
        <FormItem label={<span>Parts List</span>}>
          <Table
            style={{ background: "white" }}
            components={components}
            size="middle"
            pagination={{
              defaultPageSize: 10,
            }}
            dataSource={this.state.parts}
          >
            <Column
              title="No"
              dataIndex="key"
              key="key"
              render={(text, record, index) => {
                if (!record.addButton) {
                  return <span>{index + 1}</span>;
                }
              }}
            />
            <Column
              title="Part Number"
              dataIndex="itemCode"
              key="itemCode"
              onCell={record => ({
                record,
                datasource: this.props.itemCodes,
                editable: true,
                dataIndex: "itemCode",
                title: "Part Number",
                handleSave: this.handleSave,
              })}
            />
            <Column
              title="Description"
              dataIndex="description"
              key="description"
              onCell={record => ({
                record,
                editable: true,
                dataIndex: "description",
                title: "Description",
                handleSave: this.handleSave,
              })}
            />
            <Column title="Location" dataIndex="location" key="location" />
            <Column
              title="Quantity"
              dataIndex="qty"
              key="qty"
              onCell={record => ({
                record,
                editable: true,
                dataIndex: "qty",
                title: "Quantity",
                record: record,
                validateQty: this.validateQty,
                handleSave: this.handleSave,
              })}
            />
            <Column
              title="Actions"
              key="actions"
              render={(text, requisition) => {
                if (!requisition.addButton) {
                  return (
                    <span>
                      <a onClick={e => this.deletePart(requisition)}>Delete</a>
                    </span>
                  );
                } else {
                  return requisition.addButton;
                }
              }}
            />
          </Table>
        </FormItem>
        <Row type="flex" justify="space-around" align="middle">
          <Button type="primary" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type="primary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>
      </Form>
    );
  }
}
const RequisitionForm = Form.create()(RequisitionFormClass);

class StockFormClass extends React.Component {
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          itemCode: result.text,
        });
      },
      error => {
        alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: "Place a barcode inside the scan area",
      }
    );
  };

  render() {
    return (
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <FormItem label={<span>Brand</span>}>
          {this.props.form.getFieldDecorator("brand", {
            rules: [{ required: true, message: "Brand required" }],
            initialValue: this.props.editStock
              ? this.props.editStock.brand
              : "",
          })(
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a brand"
              optionFilterProp="children"
            >
              <Option key={"Barlows"} value={"Barlows"}>
                {"Barlows"}
              </Option>
              <Option key={"Cummins"} value={"Cummins"}>
                {"Cummins"}
              </Option>
              <Option key={"Komatsu"} value={"Komatsu"}>
                {"Komatsu"}
              </Option>
              <Option key={"Hitachi"} value={"Hitachi"}>
                {"Hitachi"}
              </Option>
              <Option key={"Babcock"} value={"Babcock"}>
                {"Babcock"}
              </Option>
              <Option key={"ESP"} value={"ESP"}>
                {"ESP"}
              </Option>
              <Option
                key={"Bearing International"}
                value={"Bearing International"}
              >
                {"Bearing International"}
              </Option>
              <Option key={"ITR"} value={"ITR"}>
                {"ITR"}
              </Option>
              <Option key={"Volvo"} value={"Volvo"}>
                {"Volvo"}
              </Option>
            </Select>
          )}
        </FormItem>
        <FormItem label={<span>Part Number</span>}>
          {this.props.form.getFieldDecorator("itemCode", {
            rules: [{ required: true, message: "Part number required" }],
            initialValue: this.props.editStock
              ? this.props.editStock.itemCode
              : "",
          })(
            <Input
              suffix={
                window.cordova ? (
                  <ScanOutlined onClick={this.barcodeScanner} />
                ) : null
              }
            />
          )}
        </FormItem>
        <FormItem label={<span>PEX Unit</span>}>
          {this.props.form.getFieldDecorator("pexUnit", {
            valuePropName: "checked",
            initialValue: this.props.editStock
              ? this.props.editStock.pexUnit
              : "",
          })(<Switch />)}
        </FormItem>
        <FormItem label={<span>Description</span>}>
          {this.props.form.getFieldDecorator("description", {
            rules: [{ required: true, message: "Description required" }],
            initialValue: this.props.editStock
              ? this.props.editStock.description
              : "",
          })(<Input />)}
        </FormItem>

        <FormItem label={<span>Low Stock Quantity</span>}>
          {this.props.form.getFieldDecorator("lowStock", {
            rules: [
              {
                required: true,
                message: "Quantity required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.lowStock
              : "",
          })(<Input type={"number"} />)}
        </FormItem>
        <FormItem label={<span>Total in stock</span>}>
          {this.props.form.getFieldDecorator("qty", {
            rules: [
              {
                required: true,
                message: "Quantity required",
              },
            ],
            initialValue: this.props.editStock ? this.props.editStock.qty : "",
          })(<Input type={"number"} />)}
        </FormItem>
        <FormItem label={<span>Location</span>}>
          {this.props.form.getFieldDecorator("location", {
            rules: [
              {
                required: true,
                message: "Location required",
              },
            ],
            initialValue: this.props.editStock
              ? this.props.editStock.location
              : "",
          })(<Input />)}
        </FormItem>
        <Row type="flex" justify="space-around" align="middle">
          <Button type="primary" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type="primary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>
      </Form>
    );
  }
}
const StockForm = Form.create()(StockFormClass);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      jobNumbers: [],
      outVisible: false,
      stockOutVisible: false,
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      qtyError: false,
      itemCodes: [],
      sizeValue: false,
      sizeValue1: false,
      todayUpdatedBits: [],
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock(this.props.user.access)
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              "Part: " + stock.itemCode + " - Location: " + stock.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (
      this.state.inVisible ||
      this.state.outVisible ||
      this.state.stockOutVisible
    ) {
      this.setState({
        inVisible: false,
        outVisible: false,
        stockOutVisible: false,
      });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
    document.documentElement.scrollTop = 0;
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  handleSearchJob = e => {
    if (e.length > 3) {
      this.props
        .findJobNumbers(e)
        .then(suc => {
          this.setState({
            jobNumbers: suc.jobNumbers,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        jobNumbers: [],
      });
    }
  };

  handleSearchTechnician = e => {
    if (e.length > 3) {
      this.props
        .findTechnicians(e)
        .then(suc => {
          this.setState({
            technicians: suc.technicians,
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    } else {
      this.setState({
        technicians: [],
      });
    }
  };
  onEditStockOutVisible = (stock, submit) => {
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    let outVisible = true;
    let stockOutVisible = false;
    if (submit) {
      outVisible = false;
      stockOutVisible = true;
    }
    this.setState({
      stockOut: stock,
      outVisible: outVisible,
      stockOutVisible: stockOutVisible,
    });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== "" && setQty !== null) {
      if (this.state.stockOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true,
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false,
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false,
      });
    }
  };
  onEditStockOut = sumbit => {
    let stock = this.state.stockOut;
    if (this.state.qtyError === true || this.state.qty === 0) {
      return;
    }
    if (parseInt(stock.qty) === 0) {
      Notify("error", "There is no stock left");
      return;
    }
    const id = stock._id;
    let request = {};
    if (sumbit) {
      request = {
        qty: this.state.qty,
        jobNumber: this.state.jobNumber,
        stockId: id,
        type: "Out",
        itemCode: stock.itemCode,
        description: stock.description,
        userName: this.state.userName,
        requisitionNumber: this.state.requisitionNumber,
        approved: true,
      };
    } else {
      request = {
        qty: this.state.qty,
        jobNumber: this.state.jobNumber,
        userId: this.props.user._id,
        stockId: id,
        type: "Out",
        itemCode: stock.itemCode,
        description: stock.description,
      };
    }
    this.setState({ loading: true, outVisible: false, stockOutVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            if (sumbit) {
              let itemCodes = [];
              this.props.stock.forEach(stock => {
                if (stock.itemCode) {
                  itemCodes.push(
                    "Part: " + stock.itemCode + " - Location: " + stock.location
                  );
                }
              });
              this.setState({ itemCodes: itemCodes });
              const { vfs } = vfsFonts.pdfMake;
              pdfMake.vfs = vfs;
              let data = Object.assign({}, request);
              prepDefinition(data).then(definition => {
                pdfMake
                  .createPdf(definition)
                  .download(
                    stock.itemCode +
                    " " +
                    moment().format("YYYY-MM-DD") +
                    " Approved Request.pdf",
                    () => {
                      this.setState({
                        loading: false,
                        qty: 1,
                        qtyError: false,
                      });
                      Notify("success", "Successfully submitted");
                    }
                  );
              });
            } else {
              this.props
                .sendNotification(
                  "admin",
                  ["admin"],
                  this.props.user.name +
                  " has created a stock out request for stock item " +
                  stock.description,
                  "request",
                  stock._id,
                  false,
                  "Asambo"
                )
                .then(() => {
                  this.setState({ loading: false, qty: 1, qtyError: false });
                  Notify("success", "Successfully sent request");
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify("error", e);
                });
            }
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  onEditStockInVisible = stock => {
    this.setState({
      stockIn: stock,
      inVisible: true,
    });
  };
  onEditStockIn = () => {
    let stock = this.state.stockIn;
    const id = stock._id;
    let request = {
      qty: this.state.qty,
      jobNumber: "",
      userId: this.props.user._id,
      stockId: id,
      type: "In",
      itemCode: stock.itemCode,
      description: stock.description,
    };
    this.setState({ loading: true, inVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            this.props
              .sendNotification(
                "admin",
                ["admin"],
                this.props.user.name +
                " has created a stock in request for stock item " +
                stock.description,
                "request",
                stock._id,
                false,
                "Asambo"
              )
              .then(() => {
                this.setState({ loading: false });
                Notify("success", "Successfully sent request");
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  handleBack = () => {
    if (this.state.addStockVisible) {
      this.setState({ addStockVisible: false });
    }
  };
  onAddStock = e => {
    this.setState({ addStockVisible: true, editStock: undefined });
  };
  onEditStock = e => {
    this.setState({ addStockVisible: true, editStock: e });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.props.user.access)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved stock");
                } else {
                  Notify("success", "Successfully added stock");
                }
                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  addStockVisible: false,
                  editStock: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditBatchStockOut = e => {
    e.preventDefault();
    this.requisitionForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        for (var i = 0; i < this.requisitionForm.state.parts.length; i++) {
          if (!this.requisitionForm.state.parts[i].addButton) {
            if (!this.requisitionForm.state.parts[i].qty) {
              return;
            }
          }
        }
        this.setState({ loading: true });
        if (this.state.editRequisition) {
          values._id = this.state.editRequisition._id;
        }
        values.parts = this.requisitionForm.state.parts;
        values.type = "Out";
        values.approved = true;
        this.props
          .addBatchRequest(values)
          .then(() => {
            this.props
              .findAllStock(this.props.user.access)
              .then(() => {
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                this.setState({ itemCodes: itemCodes });
                const { vfs } = vfsFonts.pdfMake;
                pdfMake.vfs = vfs;
                let data = Object.assign({}, values);
                prepDefinition2(data).then(definition => {
                  pdfMake
                    .createPdf(definition)
                    .download(
                      values.requisitionNumber +
                      " " +
                      moment().format("YYYY-MM-DD") +
                      " Approved Request.pdf",
                      () => {
                        this.setState({
                          loading: false,
                          addBatchVisible: false,
                        });
                        Notify("success", "Successfully submitted");
                      }
                    );
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.props.user.access)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted stock", "Successfully deleted stock.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };

  onRigUpdate = e => {
    this.props.updateRig1();
  };

  componentWillMount = () => {
    this.setState({ loading: true });

    this.props
      .findAllBitsUpdatedToday(this.props.user.access)
      .then(res => {
        this.setState({ todayUpdatedBits: res.data });
        // console.log('getTodayBits ', getTodayBits())
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
        this.setState({ loading: false });
      });

    this.props
      .findAllBits(this.props.user.access)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleAddBits = e => {
    e.preventDefault();
    this.bitsForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBits) {
          values._id = this.state.editBits._id;
        }
        this.props
          .addBits(values)
          .then(() => {
            this.props
              .findAllBits(this.props.user.access)
              .then(() => {
                if (this.state.editBits) {
                  Notify("success", "Successfully saved tool");
                } else {
                  Notify("success", "Successfully added tool");
                }
                this.bitsForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addBitsVisible: false,
                  editBits: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  render() {
    const { bits } = this.props;
    const { stock } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + " - " + d.username.split("|")[1]}
      </Option>
    ));
    const columns = [
      {
        title: "Serial",
        dataIndex: "serial",
        key: "serial",
        render: text => <a>{text}</a>,
      },
      {
        title: "Description",
        dataIndex: "size",
        key: "size",
      },

      {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: tags => (
          <>
            {tags.map(tag => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "Damaged") {
                color = "#F66E6F";
              }
              if (tag === "Sharp") {
                color = "#FF8F28";
              }
              if (tag === "New") {
                color = "#7BF387";
              }
              if (tag === "Lost") {
                color = "#D9D9D9";
              }
              return (
                <Tag
                  key={tag}
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    textAlign: "center",
                  }}
                >
                  <StyledBadge
                    color={color}
                    key={tag}
                  //color="#EE4C4C"
                  //text="Damaged"
                  />
                </Tag>
              );
            })}
          </>
        ),
      },
    ];

    const data = [
      {
        key: "1",
        serial: "1020-01",
        size: "171mm",
        tags: ["New"],
      },
      {
        key: "2",
        serial: "1024-05",
        size: "171mm",
        tags: ["Lost"],
      },
      {
        key: "3",
        serial: "1025-06",
        size: "140mm",
        tags: ["Damaged"],
      },
      {
        key: "4",
        serial: "1027-08",
        size: "171mm",
        tags: ["New"],
      },
      {
        key: "5",
        serial: "1028-09",
        size: "140mm",
        tags: ["Damaged"],
      },
      {
        key: "6",
        serial: "1020-15",
        size: "171mm",
        tags: ["Sharp"],
      },
      {
        key: "7",
        serial: "1020-18",
        size: "140mm",
        tags: ["Lost"],
      },
    ];
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ borderBottom: "1px solid #DDDDDD", height: "3.3em" }}
        >
          <Col
            xs={12}
            md={12}
            style={{ marginLeft: "-0.8em", marginTop: "-1.3em" }}
          >
            {/*<StockOutlined
              style={{ fontSize: 25, color: "#009BDF", marginRight: 20 }}
            />*/}
            <span
              style={{
                fontWeight: 500,
                fontSize: 20,
                color: "black",
                marginLeft: 10,
                fontFamily: "Roboto",
              }}
            >
              {this.props.user.name}
              {/*{this.props.local.rig1 ? "38" : this.props.local.rig2 ? "91" : ""}*/}
              {/*{this.state.addStockVisible ? "Add Stock" : "Stock"}*/}
            </span>{" "}
            <br />
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              marginTop: "0.8em",
              textAlign: "right",
            }}
          >
            <Col
              span={18}
              style={{ float: "right", width: "7.5em", whiteSpace: "nowrap" }}
            >
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                  color: "#FF9D66",
                  marginLeft: 0,
                  fontFamily: "Roboto",
                  marginTop: "-0.3em",
                }}
              >
                {moment().format("DD/MM/YY")}
              </p>
            </Col>
            <br />
          </Col>

          {/*} <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            xs={24}
            md={12}
          >
            {!this.state.addStockVisible && !this.state.addBatchVisible ? (
              <div
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {this.props.user.isBatch ? (
                  <Button
                    type="primary"
                    style={{ width: 190, height: "3em", marginRight: 10 }}
                    onClick={(e) => this.setState({ addBatchVisible: true })}
                  >
                    Submit Batch Stock Out
                  </Button>
                ) : null}
                {this.props.user.isAdd ? (
                  <Button
                    type="primary"
                    style={{ width: 190, height: "3em" }}
                    onClick={this.onAddStock}
                  >
                    Add Stock
                  </Button>
                ) : null}
              </div>
            ) : null}
          </Col>*/}
        </Row>
        <StyledTable
          bordered={false}
          // dataSource={this.props.bits}
          dataSource={this.state.todayUpdatedBits}
          rowKey="_id"
          size="middle"
          pagination={
            false
            //defaultPageSize: 25,
          }
          style={{
            minWidth: "15em",
            marginTop: "0.5em",
          }}
        >
          <Column
            //{...this.getColumnSearchProps("serial")}
            title="Serial"
            dataIndex="serial"
            key="serial"
          />
          <Column
            //{...this.getColumnSearchProps("currentRig")}
            title="Current Rig"
            dataIndex="currentRig"
            key="currentRig"
          />
          {/*<Column
            //{...this.getColumnSearchProps("size")}
            title='Description'
            dataIndex='description'
            key='description'
          />*/}

          {/*{window.innerWidth > 420 ? (
            <Column
              //{...this.getColumnSearchProps("currentRig")}
              title="Current Rig"
              dataIndex="currentRig"
              key="currentRig"
            />
          ) : null}
          {window.innerWidth > 420 ? (
            <Column
              //{...this.getColumnSearchProps("lastUser")}
              title="Last User"
              dataIndex="lastUser"
              key="lastUser"
            />
          ) : null}
          {window.innerWidth > 420 ? (
            <Column
              //{...this.getColumnSearchProps("meters")}
              title="Meters"
              dataIndex="meters"
              key="meters"
            />
          ) : null}*/}
          <Column
            //{...this.getColumnSearchProps("lastUser")}
            title="Last User"
            dataIndex="lastUser"
            key="lastUser"
          />
          <Column
            //{...this.getColumnSearchProps("status")}
            title="Status"
            dataIndex="status"
            key="status"
            render={status => {
              switch (status) {
                case "New":
                  return this.renderStatus("New", "#4BED5B");
                case "Used":
                  return this.renderStatus("Used", "#EE4C4C");
                case "Out":
                  return this.renderStatus("Out", "#FF8F28");
                case "Sharp":
                  return this.renderStatus("Sharp", "#EDAC4B");
                case "Lost":
                  return this.renderStatus("Lost", "#D4D4D4");
                case "Damaged":
                  return this.renderStatus("Damaged", "#EE4C4C");
                default:
                  return this.renderStatus("No Status", "lightgrey");
              }
            }}
          />

          {/*<Column
            //{...this.getColumnSearchProps("actions")}
            title="Actions"
            render={(text, bits) => (
              <span>
                <Button
                  style={{
                    backgroundColor: "#009BDF",
                    color: "white",
                    borderRadius: "3em",
                  }}
                  onClick={(e) => this.handleEditBits(bits)}
                >
                  Edit
                </Button>
                <Divider type="vertical" />
                <DeleteFilled
                  style={{
                    backgroundColor: "#CFD5E3",
                    color: "white",
                    padding: "0.5em",
                    borderRadius: "3em",
                  }}
                  data={bits._id}
                  onClick={this.handleDeleteBits}
                />
              </span>
            )}
          />*/}
        </StyledTable>{" "}
        {/*<Table pagination={false} columns={columns} dataSource={data} />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.status,
});

const mapDispatchToProps = {
  updateRig1,
  updateRig2,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stock);
