import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import Logo from "src/static/logo2.svg";
import BackImg from "src/static/home-background.png";
import AppLink from "src/static/360-app/Asambo-360-1.16.apk";
import LogoTransp from "src/static/logo-transparent.png";
import Background from "src/static/sign-in-background.png";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { LockOutlined, UserOutlined, DownloadOutlined } from "@ant-design/icons";
import SharpIcon from "src/static/circle-logo.svg";
import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
import { saveAs } from 'file-saver';
import {
  Checkbox,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Modal,
  Card,
} from "antd";
import { ButtonRadius } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
import lock from "src/static/icons/input-icons/lock.svg";
import user from "src/static/icons/input-icons/user.svg";
import eye from "src/static/icons/password-show.svg";

const FormItem = Form.Item;
const Inputs = styled(Input)`
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.13);
  height: 3.7em;
  padding-left: 2em;
  border-radius: 3em;
  border: none;
  //.ant-input:focus {
  //  border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  //}
`;
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
class PassFormClass extends React.Component {
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Password</span>}>
          {this.props.form.getFieldDecorator("password", {
            rules: [
              { required: true, message: "Password required" },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </FormItem>
        <FormItem label={<span>Confirm Password</span>}>
          {this.props.form.getFieldDecorator("confirmpassword", {
            rules: [
              { required: true, message: "Dealer name required" },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password />)}
        </FormItem>
      </Form>
    );
  }
}
const PassForm = Form.create()(PassFormClass);
class SignIn extends React.Component {
  static defaultProps = {
    error: null,
    firstLogin: false,
  };
  componentWillMount = () => {
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };
  exit = false;
  handleBack = () => {
    Notify("info", "Press back again to close the app", "", 2);
    if (this.exit) {
      navigator.app.exitApp();
    }
    this.exit = true;
    setTimeout(() => {
      this.exit = false;
    }, 2000);
  };
  componentWillUnmount = () => {
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };
  handleSubmit = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.type = "Technician";
        this.signIn(values);
      }
    });
  };

  signIn = values => {
    this.props
      .signIn(values)
      .then(() => {
        this.setState({ loading: false });
        if (this.props.user.firstLogin) {
          this.setState({ firstLogin: true });
        } else if (this.props.user.type === "admin") {
          this.props.push("/admin/dashboard");
        } else if (this.props.user.type === "manager") {
          this.props.push("/manager/surveys");
        } else if (this.props.user.type === "user") {
          this.props.push("/user/dashboard");
        } else if (this.props.user.type === "buyer") {
          this.props.push("/buyer/dashboard");
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", "Error", e);
      });
  };

  toggleShowPass = () => {
    const { state } = this;
    this.state.passShow === "password"
      ? this.setState({ passShow: "text" })
      : this.setState({ passShow: "password" });
  };

  constructor(props) {
    super(props);
    this.state = {
      mainView: true,
      loading: false,
      passShow: "password",
    };
  }

  passForm;
  handleChangePassword = e => {
    e.preventDefault();
    this.passForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            Notify("success", "Successfully set password");
            this.setState({ loading: false, firstLogin: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleDownloadFile = () => {
    // Assuming you have the file URL or blob available
    const fileUrl = { AppLink };

    // Use the saveAs function from file-saver to trigger the file download
    saveAs(fileUrl, 'Asambo-360-1.10.apk');
  };
  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div
        style={{
          backgroundColor: "#0C3446",
          height: "100vh",
        }}
      >
        <Row>
          <Col
            xs={24}
            md={24}
            style={{
              minHeight: "40em",
              backgroundColor: "#0C3446",
              width: "100vw",
            }}
          >
            <Spin spinning={this.state.loading}>
              <img
                src={BackImg}
                style={{
                  position: "absolute",
                  width: "100vw",
                  height: "100vh",
                  top: 0,
                  left: 0,
                }}
              />
              {this.state.mainView === true ? (
                <Row style={{ maxWidth: "40em", margin: "auto" }}>
                  <Col
                    span={24}
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      marginTop: "30vh",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "27px",
                        fontWeight: 700,
                        color: "white",
                        marginBottom: "-0em",
                      }}
                    >
                      Welcome to Asambo 360°
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "white",
                        marginBottom: "1.5em",
                      }}
                    >
                      Choose Your Destination
                    </p>
                  </Col>
                  <Col span={12} style={{ margin: "auto" }}>
                    <Card
                      onClick={e => this.setState({ mainView: false })}
                      style={{
                        boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                        borderRadius: "0.7em",
                        border: "none",
                        padding: "0em",
                        whiteSpace: "nowrap",
                        fontFamily: "Poppins",
                        padding: "0.6em",
                        minWidth: "11em",
                        width: "90%",
                        margin: "auto",
                        overflow: "hidden",
                        backgroundColor: "#0B2734",
                      }}
                    >
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "white",
                              //marginLeft: -10
                            }}
                          >
                            {" "}
                            <img src={Logo} />
                            <p
                              style={{
                                marginTop: 10,
                              }}
                            >
                              Sign In
                            </p>
                          </span>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} style={{ margin: "auto" }}>
                    <Card
                      // onClick={e => this.setState({ mainView: false })}
                      style={{
                        boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                        borderRadius: "0.7em",
                        border: "none",
                        padding: "0em",
                        whiteSpace: "nowrap",
                        fontFamily: "Poppins",
                        padding: "0em",
                        minWidth: "11em",
                        width: "90%",
                        margin: "auto",
                        overflow: "hidden",
                        backgroundColor: "#0B2734",
                      }}
                    >
                      <Row>

                        <Col span={24} style={{ textAlign: "center" }}>
                          <a href={AppLink} download>
                            <span
                              style={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "white",
                                //marginLeft: -10
                              }}
                            >
                              {" "}
                              <DownloadOutlined
                                style={{
                                  fontSize: "70px",
                                  color: "white",
                                  // marginTop: props.collapsed ? "0.5em" : 0,
                                }}
                              />
                              <p
                                style={{
                                  marginTop: 10,
                                }}
                              >
                                360° Application
                              </p>
                            </span>{" "}
                          </a>
                        </Col>

                      </Row>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Form onSubmit={this.handleSubmit}>
                  <Card
                    style={{
                      margin: "auto",
                      height: "70vh",
                      textAlign: "center",
                      //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
                      border: "none",
                      width: "100vw",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src={Logo}
                      style={{
                        zIndex: 99,
                        marginTop: "6vw",
                        width: "12em",
                        fontSize: "22px",
                      }}
                    />
                    <h1
                      style={{
                        lineHeight: "5px",
                        fontSize: "50px",
                        color: "white",
                        textAlign: "center",
                        marginTop: "0.8em",
                      }}
                    >
                      {" "}
                      <p
                        style={{
                          fontSize: "27px",
                          fontWeight: 700,
                          color: "white",
                          marginBottom: "-0.5em",
                        }}
                      >
                        Sign In to Asambo 360°
                      </p>
                      <p
                        style={{
                          fontFamily: "Open Sans",
                          marginTop: "50px",
                          fontWeight: 400,
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Sign into your account
                      </p>
                    </h1>
                    <div
                      style={{
                        maxWidth: "35em",
                        // minWidth: "22em",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                    >
                      <FormItem>
                        {getFieldDecorator("username", {
                          rules: [
                            {
                              required: true,
                              message: "Username required",
                            },
                          ],
                        })(
                          <Inputs
                            placeholder="Username"
                            prefix={
                              <UserOutlined
                                style={{
                                  paddingRight: "0.5em",
                                  color: "lightgrey",
                                }}
                              />
                            }
                            style={{
                              width: "100%",
                              border: 0,
                              borderRadius: 50,
                            }}
                          />
                        )}
                      </FormItem>
                      <FormItem style={{ marginTop: "0em" }}>
                        {getFieldDecorator("password", {
                          rules: [
                            { required: true, message: "Password required" },
                          ],
                        })(
                          <Inputs.Password
                            placeholder="Password"
                            prefix={
                              <LockOutlined
                                style={{
                                  paddingRight: "0.5em",
                                  color: "lightgrey",
                                }}
                              />
                            }
                            type="password"
                            style={{
                              boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                              height: "3.7em",
                              //paddingLeft: '2em',
                              borderRadius: "3em",
                              border: "none",
                            }}
                          />
                        )}
                      </FormItem>
                      {/* <FormItem

                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Password
                  //  </span>
                  //}
                  >
                    {getFieldDecorator('password', {
                      rules: [{ message: 'Please read & accept Terms' }]
                    })(
                      <Checkbox
                        onChange={onChange}
                        style={{ fontFamily: 'Open Sans' }}
                      >
                        I agree the
                        <span style={{ fontWeight: 900 }}>
                          {' '}
                          Terms and Conditions
                        </span>
                      </Checkbox>
                    )}
                  </FormItem> */}
                      <FormItem>
                        <ButtonRadius
                          style={{
                            marginTop: "1em",
                            height: 55,
                            width: "100%",
                            fontSize: 14,
                            border: "none",
                            fontFamily: "Open Sans",
                            textShadow: "none",
                            backgroundColor: "#0B2734",
                            boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                          }}
                          type="primary"
                          htmlType="submit"
                          size="large"
                          block
                        >
                          SIGN IN
                        </ButtonRadius>
                      </FormItem>
                    </div>
                  </Card>
                </Form>
              )}
            </Spin>
          </Col>
          <span
            style={{
              position: "absolute",
              bottom: "0.5em",
              right: "1em",
              color: 'lightgrey',
              fontSize: 12,
              textDecoration: "underline"
            }}

          >
            {"v" + process.env.REACT_APP_VERSION_CNTRL}
          </span>
        </Row>
        <Modal
          title="Set Password"
          visible={this.state.firstLogin}
          onOk={this.handleChangePassword}
          onCancel={e => this.setState({ firstLogin: false })}
          okText="Save"
          cancelText="Cancel"
        >
          <PassForm wrappedComponentRef={form => (this.passForm = form)} />
        </Modal>
      </div>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;

//for application build

// import React from "react";
// import styled from "styled-components";
// import SVG from "react-inlinesvg";
// import Logo from "src/static/logo2.svg";
// import BackImg from "src/static/home-background.png";
// // import AppLink from "src/static/360-app/Asambo-360-1.10.apk";
// import LogoTransp from "src/static/logo-transparent.png";
// import Background from "src/static/sign-in-background.png";
// import { Form } from "@ant-design/compatible";
// import "@ant-design/compatible/assets/index.css";
// import { LockOutlined, UserOutlined, DownloadOutlined } from "@ant-design/icons";
// import SharpIcon from "src/static/circle-logo.svg";
// import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
// import { saveAs } from 'file-saver';
// import {
//   Checkbox,
//   Select,
//   Row,
//   Col,
//   Spin,
//   Input,
//   Button,
//   Modal,
//   Card,
// } from "antd";
// import { ButtonRadius } from "src/components/elements/Button";
// import Notify from "src/components/meta/Notification";
// import lock from "src/static/icons/input-icons/lock.svg";
// import user from "src/static/icons/input-icons/user.svg";
// import eye from "src/static/icons/password-show.svg";

// const FormItem = Form.Item;
// const Inputs = styled(Input)`
//   box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.13);
//   height: 3.7em;
//   padding-left: 2em;
//   border-radius: 3em;
//   border: none;
//   //.ant-input:focus {
//   //  border-bottom: 2px solid rgba(0, 136, 142, 0.2);
//   //}
// `;
// function onChange(e) {
//   console.log(`checked = ${e.target.checked}`);
// }
// class PassFormClass extends React.Component {
//   compareToFirstPassword = (rule, value, callback) => {
//     const { form } = this.props;
//     if (value && value !== form.getFieldValue("password")) {
//       callback("The passwords do not match!");
//     } else {
//       callback();
//     }
//   };

//   validateToNextPassword = (rule, value, callback) => {
//     const { form } = this.props;
//     if (value) {
//       form.validateFields(["confirm"], { force: true });
//     }
//     callback();
//   };
//   render() {
//     return (
//       <Form layout="vertical">
//         <FormItem label={<span>Password</span>}>
//           {this.props.form.getFieldDecorator("password", {
//             rules: [
//               { required: true, message: "Password required" },
//               {
//                 validator: this.validateToNextPassword,
//               },
//             ],
//           })(<Input.Password />)}
//         </FormItem>
//         <FormItem label={<span>Confirm Password</span>}>
//           {this.props.form.getFieldDecorator("confirmpassword", {
//             rules: [
//               { required: true, message: "Dealer name required" },
//               {
//                 validator: this.compareToFirstPassword,
//               },
//             ],
//           })(<Input.Password />)}
//         </FormItem>
//       </Form>
//     );
//   }
// }
// const PassForm = Form.create()(PassFormClass);
// class SignIn extends React.Component {
//   static defaultProps = {
//     error: null,
//     firstLogin: false,
//   };
//   componentWillMount = () => {
//     if (window.cordova) {
//       document.addEventListener("backbutton", this.handleBack);
//     }
//   };
//   exit = false;
//   handleBack = () => {
//     Notify("info", "Press back again to close the app", "", 2);
//     if (this.exit) {
//       navigator.app.exitApp();
//     }
//     this.exit = true;
//     setTimeout(() => {
//       this.exit = false;
//     }, 2000);
//   };
//   componentWillUnmount = () => {
//     if (window.cordova) {
//       document.removeEventListener("backbutton", this.handleBack);
//     }
//   };
//   handleSubmit = event => {
//     event.preventDefault();

//     this.props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         values.type = "Technician";
//         this.signIn(values);
//       }
//     });
//   };

//   signIn = values => {
//     this.props
//       .signIn(values)
//       .then(() => {
//         this.setState({ loading: false });
//         if (this.props.user.firstLogin) {
//           this.setState({ firstLogin: true });
//         } else if (this.props.user.type === "admin") {
//           this.props.push("/admin/dashboard");
//         } else if (this.props.user.type === "manager") {
//           this.props.push("/manager/surveys");
//         } else if (this.props.user.type === "user") {
//           this.props.push("/user/dashboard");
//         } else if (this.props.user.type === "buyer") {
//           this.props.push("/buyer/dashboard");
//         }
//       })
//       .catch(e => {
//         this.setState({ loading: false });
//         Notify("error", "Error", e);
//       });
//   };

//   toggleShowPass = () => {
//     const { state } = this;
//     this.state.passShow === "password"
//       ? this.setState({ passShow: "text" })
//       : this.setState({ passShow: "password" });
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       mainView: true,
//       loading: false,
//       passShow: "password",
//     };
//   }

//   passForm;
//   handleChangePassword = e => {
//     e.preventDefault();
//     this.passForm.props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         this.setState({ loading: true });
//         this.props
//           .changePassword(values)
//           .then(() => {
//             Notify("success", "Successfully set password");
//             this.setState({ loading: false, firstLogin: false });
//           })
//           .catch(e => {
//             this.setState({ loading: false });
//             Notify("error", e);
//           });
//       }
//     });
//   };
//   // handleDownloadFile = () => {
//   //   // Assuming you have the file URL or blob available
//   //   const fileUrl = { AppLink };

//   //   // Use the saveAs function from file-saver to trigger the file download
//   //   saveAs(fileUrl, 'Asambo-360-1.10.apk');
//   // };
//   render() {
//     const { loading } = this.props;
//     const { getFieldDecorator } = this.props.form;

//     return (
//       <div
//         style={{
//           backgroundColor: "#0C3446",
//           height: "100vh",
//         }}
//       >
//         <Row>
//           <Col
//             xs={24}
//             md={24}
//             style={{
//               minHeight: "40em",
//               backgroundColor: "#0C3446",
//               width: "100vw",
//             }}
//           >
//             <Spin spinning={this.state.loading}>
//               <img
//                 src={BackImg}
//                 style={{
//                   position: "absolute",
//                   width: "100vw",
//                   height: "100vh",
//                   top: 0,
//                   left: 0,
//                 }}
//               />
//               {this.state.mainView === true ? (
//                 <Row style={{ maxWidth: "40em", margin: "auto" }}>
//                   <Col
//                     span={24}
//                     style={{
//                       margin: "auto",
//                       textAlign: "center",
//                       marginTop: "30vh",
//                     }}
//                   >
//                     <p
//                       style={{
//                         fontSize: "27px",
//                         fontWeight: 700,
//                         color: "white",
//                         marginBottom: "-0em",
//                       }}
//                     >
//                       Welcome to Asambo 360°
//                     </p>
//                     <p
//                       style={{
//                         fontSize: "16px",
//                         fontWeight: 400,
//                         color: "white",
//                         marginBottom: "1.5em",
//                       }}
//                     >
//                       Choose Your Destination
//                     </p>
//                   </Col>
//                   <Col span={24} style={{ margin: "auto" }}>
//                     <Card
//                       onClick={e => this.setState({ mainView: false })}
//                       style={{
//                         boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                         borderRadius: "0.7em",
//                         border: "none",
//                         padding: "0em",
//                         whiteSpace: "nowrap",
//                         fontFamily: "Poppins",
//                         padding: "0.6em",
//                         minWidth: "11em",
//                         width: "90%",
//                         margin: "auto",
//                         overflow: "hidden",
//                         backgroundColor: "#0B2734",
//                       }}
//                     >
//                       <Row>
//                         <Col span={24} style={{ textAlign: "center" }}>
//                           <span
//                             style={{
//                               fontWeight: 500,
//                               fontSize: 14,
//                               color: "white",
//                               //marginLeft: -10
//                             }}
//                           >
//                             {" "}
//                             <img src={Logo} />
//                             <p
//                               style={{
//                                 marginTop: 10,
//                               }}
//                             >
//                               Sign In
//                             </p>
//                           </span>{" "}
//                         </Col>
//                       </Row>
//                     </Card>
//                   </Col>
//                   {/* <Col span={12} style={{ margin: "auto" }}>
//                     <Card
//                       // onClick={e => this.setState({ mainView: false })}
//                       style={{
//                         boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                         borderRadius: "0.7em",
//                         border: "none",
//                         padding: "0em",
//                         whiteSpace: "nowrap",
//                         fontFamily: "Poppins",
//                         padding: "0em",
//                         minWidth: "11em",
//                         width: "90%",
//                         margin: "auto",
//                         overflow: "hidden",
//                         backgroundColor: "#0B2734",
//                       }}
//                     >
//                       <Row>

//                         <Col span={24} style={{ textAlign: "center" }}>
//                           <a href={AppLink} download>
//                             <span
//                               style={{
//                                 fontWeight: 500,
//                                 fontSize: 14,
//                                 color: "white",
//                                 //marginLeft: -10
//                               }}
//                             >
//                               {" "}
//                               <DownloadOutlined
//                                 style={{
//                                   fontSize: "70px",
//                                   color: "white",
//                                   // marginTop: props.collapsed ? "0.5em" : 0,
//                                 }}
//                               />
//                               <p
//                                 style={{
//                                   marginTop: 10,
//                                 }}
//                               >
//                                 360° Application
//                               </p>
//                             </span>{" "}
//                           </a>
//                         </Col>

//                       </Row>
//                     </Card>
//                   </Col> */}
//                 </Row>
//               ) : (
//                 <Form onSubmit={this.handleSubmit}>
//                   <Card
//                     style={{
//                       margin: "auto",
//                       height: "70vh",
//                       textAlign: "center",
//                       //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
//                       border: "none",
//                       width: "100vw",
//                       backgroundColor: "transparent",
//                     }}
//                   >
//                     <img
//                       src={Logo}
//                       style={{
//                         zIndex: 99,
//                         marginTop: "6vw",
//                         width: "12em",
//                         fontSize: "22px",
//                       }}
//                     />
//                     <h1
//                       style={{
//                         lineHeight: "5px",
//                         fontSize: "50px",
//                         color: "white",
//                         textAlign: "center",
//                         marginTop: "0.8em",
//                       }}
//                     >
//                       {" "}
//                       <p
//                         style={{
//                           fontSize: "27px",
//                           fontWeight: 700,
//                           color: "white",
//                           marginBottom: "-0.5em",
//                         }}
//                       >
//                         Sign In to Asambo 360°
//                       </p>
//                       <p
//                         style={{
//                           fontFamily: "Open Sans",
//                           marginTop: "50px",
//                           fontWeight: 400,
//                           color: "white",
//                           fontSize: "16px",
//                           fontWeight: "500",
//                         }}
//                       >
//                         Sign into your account
//                       </p>
//                     </h1>
//                     <div
//                       style={{
//                         maxWidth: "35em",
//                         // minWidth: "22em",
//                         margin: "auto",
//                         marginTop: "30px",
//                       }}
//                     >
//                       <FormItem>
//                         {getFieldDecorator("username", {
//                           rules: [
//                             {
//                               required: true,
//                               message: "Username required",
//                             },
//                           ],
//                         })(
//                           <Inputs
//                             placeholder="Username"
//                             prefix={
//                               <UserOutlined
//                                 style={{
//                                   paddingRight: "0.5em",
//                                   color: "lightgrey",
//                                 }}
//                               />
//                             }
//                             style={{
//                               width: "100%",
//                               border: 0,
//                               borderRadius: 50,
//                             }}
//                           />
//                         )}
//                       </FormItem>
//                       <FormItem style={{ marginTop: "0em" }}>
//                         {getFieldDecorator("password", {
//                           rules: [
//                             { required: true, message: "Password required" },
//                           ],
//                         })(
//                           <Inputs.Password
//                             placeholder="Password"
//                             prefix={
//                               <LockOutlined
//                                 style={{
//                                   paddingRight: "0.5em",
//                                   color: "lightgrey",
//                                 }}
//                               />
//                             }
//                             type="password"
//                             style={{
//                               boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
//                               height: "3.7em",
//                               //paddingLeft: '2em',
//                               borderRadius: "3em",
//                               border: "none",
//                             }}
//                           />
//                         )}
//                       </FormItem>
//                       {/* <FormItem

//                   //label={
//                   //  <span style={{ color: "grey", fontSize: 15 }}>
//                   //    Password
//                   //  </span>
//                   //}
//                   >
//                     {getFieldDecorator('password', {
//                       rules: [{ message: 'Please read & accept Terms' }]
//                     })(
//                       <Checkbox
//                         onChange={onChange}
//                         style={{ fontFamily: 'Open Sans' }}
//                       >
//                         I agree the
//                         <span style={{ fontWeight: 900 }}>
//                           {' '}
//                           Terms and Conditions
//                         </span>
//                       </Checkbox>
//                     )}
//                   </FormItem> */}
//                       <FormItem>
//                         <ButtonRadius
//                           style={{
//                             marginTop: "1em",
//                             height: 55,
//                             width: "100%",
//                             fontSize: 14,
//                             border: "none",
//                             fontFamily: "Open Sans",
//                             textShadow: "none",
//                             backgroundColor: "#0B2734",
//                             boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                           }}
//                           type="primary"
//                           htmlType="submit"
//                           size="large"
//                           block
//                         >
//                           SIGN IN
//                         </ButtonRadius>
//                       </FormItem>
//                     </div>
//                   </Card>
//                 </Form>
//               )}
//             </Spin>
//           </Col>
//           <span
//             style={{
//               position: "absolute",
//               bottom: "0.5em",
//               right: "1em",
//               color: 'lightgrey',
//               fontSize: 12,
//               textDecoration: "underline"
//             }}

//           >
//             {"v" + process.env.REACT_APP_VERSION_CNTRL}
//           </span>
//         </Row>
//         <Modal
//           title="Set Password"
//           visible={this.state.firstLogin}
//           onOk={this.handleChangePassword}
//           onCancel={e => this.setState({ firstLogin: false })}
//           okText="Save"
//           cancelText="Cancel"
//         >
//           <PassForm wrappedComponentRef={form => (this.passForm = form)} />
//         </Modal>
//       </div>
//     );
//   }
// }

// const WrappedSignIn = Form.create()(SignIn);

// export default WrappedSignIn;




