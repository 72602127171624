export const initialState = {
  myPexunit: [],
  allPexunit: [],
  basketPexunitIds: [],
  auctionPexunit: [],
  auctionPexunitIds: [],
  dealDonePexunit: [],
  wonPexunit: [],
  lostPexunit: [],
  editPexunit: undefined,
  pexunitDetails: undefined,
  pexunitDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function pexunitReducer(state = initialState, action) {
  switch (action.type) {
    case 'PEXUNIT_FIND_ALLPEXUNIT': {
      if (action.data) {
        const allPexunit = action.data.allPexUnit;

        return {
          ...state,
          allPexunit: allPexunit
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
