import React, { PureComponent, useState } from "react";
import { Layout, Row, Col, Popover, Button, Modal } from "antd";
import { ButtonRadius } from "src/components/elements/Button";
import { withRouter, Link } from "react-router-dom";
import {
  DashboardOutlined,
  LineChartOutlined,
  UserOutlined,
  ToolOutlined,
  SettingOutlined,
  AntDesignOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { getUser } from "src/redux/selectors";
import { connect } from "react-redux";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import { userInfo } from "os";
import SignatureCanvas from "react-signature-canvas";
import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaUserFriends } from "@react-icons/all-files/fa/FaUserFriends";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaCopy } from "@react-icons/all-files/fa/FaCopy";
const StyledLink = styled(Link)`
  color: #fff !important;
  font-size: 15px;
  :hover {
    text-decoration: none !important;
  }
  .anticon svg {
    display: inline-block;
    font-size: 20px !important;
    color: white !important;
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
`;

const getInitials = function (sName) {
  var names = sName.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
const signOut = s => {
  if (window.cordova) {
    window.FirebasePlugin.getToken(
      token => {
        window.pubnub.push.deleteDevice(
          {
            device: token,
            pushGateway: "gcm", // apns, gcm, mpns
          },
          function (status) {
            if (status.error) {
              console.log("operation failed w/ error:", status);
            }
          }
        );
      },
      error => {
        console.error(error);
      }
    );
  }

  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("basic");
  localStorage.clear();
  window.location.reload();
};
export class ResFooter extends PureComponent {
  state = {
    dash: true,
    shift: false,
    profile: false,
    tools: false,
  };

  onDashActive = dash => {
    this.setState({ dash });
    this.setState({ shift: false });
    this.setState({ profile: false });
    this.setState({ tools: false });
  };
  onToolsActive = tools => {
    this.setState({ tools });
    this.setState({ shift: false });
    this.setState({ dash: false });
    this.setState({ profile: false });
  };
  onShiftActive = shift => {
    this.setState({ shift });
    this.setState({ tools: false });
    this.setState({ dash: false });
    this.setState({ profile: false });
  };
  onProfileActive = profile => {
    this.setState({ profile });
    this.setState({ tools: false });
    this.setState({ dash: false });
    this.setState({ shift: false });
  };
  render() {
    if (window.innerWidth <= 1100) {
      window.isPhone = true;
    } else {
      window.isPhone = false;
    }
    return (
      <Layout.Footer
        style={{
          zIndex: 5,
          width: "100%",
          position: "fixed",
          bottom: 0,
          background: "#001529",
          lineHeight: "40px",
          padding: 0,
          height: "3.5em",
          boxShadow: "0px 11px 20px 0 rgba(0, 0, 0, 10)",
          display: window.isPhone ? "" : "none",
        }}
      >
        <Row type="flex" justify="space-around" style={{}}>
          <Col span={8} style={{ display: "flex" }}>
            <Link to={this.props.user.type === 'buyer' ? "/buyer/stock" : "/user/stock"} style={{ margin: "0.5em" }}>
              <div
                onClick={this.onShiftActive}
                style={{
                  color: "white",
                  margin: "auto",
                  marginBottom: "1.2em",
                  display: "grid",
                  cursor: "pointer",
                  borderRadius: "0.3em",
                  backgroundColor: this.state.shift ? "#009BDF" : "",
                  display: this.props.user.type === 'buyer' ? "none" : "flex"
                }}
              >
                <FaUserFriends
                  style={{
                    margin: "auto",
                    padding: "0.2em",
                    fontSize: "20px",
                    width: "1.8em",
                    height: "1.8em",
                  }}
                />

                {/*<span
                style={{
                  marginTop: -11,
                  fontSize: 12,
                }}
              >
                Search
              </span>*/}
              </div>{" "}
            </Link>
          </Col>
          <Col span={4} style={{ display: "flex" }}>
            <Link to={this.props.user.type === 'buyer' ? "/buyer/dashboard" : "/user/dashboard"} style={{ marginTop: "0.5em" }}>
              <div
                onClick={this.onDashActive}
                style={{
                  margin: "auto",
                  background: "#009BDF",
                  borderRadius: "50%",
                  width: 60,
                  height: 60,
                  bottom: 20,
                  position: "relative",
                  display: "flex",
                  border: "4px solid white",
                }}
              >
                <FaDashcube
                  style={{
                    margin: "auto",
                    color: "white",
                    padding: "0.2em",
                    fontSize: "20px",
                    width: "1.6em",
                    height: "1.6em",
                  }}
                />
              </div>
            </Link>
          </Col>

          <Col span={8} style={{ display: "flex" }}>
            <div
              onClick={signOut}
              style={{
                color: "white",
                margin: "auto",
                marginBottom: "1.7em",
                marginRight: 0,
                display: "grid",
              }}
            >
              <FaSignOutAlt
                style={{
                  margin: "auto",

                  padding: "0.2em",
                  fontSize: "20px",
                  width: "1.8em",
                  height: "1.8em",
                }}
              />
              {/*<span
                style={{
                  marginTop: -11,
                  fontSize: 12,
                }}
              >
                Profile
              </span>*/}
            </div>
          </Col>
        </Row>
      </Layout.Footer>
    );
  }
}

const mapStateToProps = state => ({
  user: getUser(state) || {},
});

export default withRouter(connect(mapStateToProps)(ResFooter));
